var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-page"},[_c('AdminMenu',{staticClass:"admin-menu"}),_c('div',{staticClass:"mobile-screen mobile-main-menu"},[_c('font-awesome-icon',{staticClass:"bars-icon",attrs:{"icon":"bars"},on:{"click":function($event){return _vm.toggleMobileMenu()}}}),_c('div',{staticClass:"mobile-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/tylogo.png")}})])],1),_c('font-awesome-icon',{staticClass:"shop-icon",class:_vm.shopMenuState,staticStyle:{"position":"absolute","right":"20px"},attrs:{"icon":"th-large"},on:{"click":function($event){return _vm.triggerShopSidebar()}}})],1),_c('div',{staticClass:"reveal-menu",class:_vm.menuState},[_c('AdminMenu',{staticClass:"mobile-menu"})],1),_c('div',{staticClass:"dashboard-body"},[_vm._m(0),_c('div',{staticClass:"clearfix",staticStyle:{"height":"25px"}}),_c('div',{staticClass:"account-dashboard dashboard-item full"},[_c('div',{staticClass:"item-container table-list"},[_c('div',{staticClass:"dashboard-title"},[_vm._v("All Posts")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
            enabled: true,
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 30,
          },"styleClass":"vgt-table"},on:{"on-cell-click":_vm.selectCell}})],1)])]),_c('div',{staticClass:"clearfix"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-page-title"},[_c('div',{staticClass:"page-title"},[_vm._v("POSTS")]),_c('div',{staticClass:"page-subtitle"},[_vm._v("Manage Posts from all Users")])])
}]

export { render, staticRenderFns }