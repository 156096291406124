<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">DASHBOARD</div>
        <div class="page-subtitle">Welcome to Dashboard</div>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container">
          <div style="margin-left: 30px; margin-top: 25px" class="upper-text">
            ยินดีต้อนรับกลับ! <br />
            Tycoon Admin
          </div>
          <div class="upper-image welcome-image"><img src="../assets/Work_from_home.jpg" /></div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">ร้านของฉัน</span> <br />
            <router-link v-if="shopInfo == ''" to="/vendor/shop">
              <div class="dashboardbtt">เปิดร้านใหม่</div>
            </router-link>
            <span v-else class="upper-text-subtitle">{{ shopInfo }}</span>
          </div>
          <div class="upper-icon">
            <img src="../assets/shopping-bag.svg">
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">ที่ตั้งร้าน</span> <br />
            <router-link v-if="shopInfo == ''" to="/vendor/shop">
              <div class="dashboardbtt">กำหนดที่ตั้งร้าน</div>
            </router-link>
            <span v-else class="upper-text-subtitle">{{ shopLocation }}</span>
          </div>
          <div class="upper-icon">
            <img src="../assets/map-pin.svg">
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">สถานะสมาชิก</span> <br />
            <!--<form @submit.prevent v-if="membership == ''">
              <div id="checkout-button" @click="omiseHandler" class="dashboardbtt">สมัครสมาชิก</div>
            </form>
            <span v-else class="upper-text-subtitle">{{ membership }}</span>-->
            <router-link v-if="membership == ''" to="/vendor/pricing">
              <div class="dashboardbtt">สมัครสมาชิก</div>
            </router-link>
            <span v-else class="upper-text-subtitle">{{ membership }}</span>
          </div>
          <div class="upper-icon">
            <img src="../assets/userd.svg">
          </div>
        </div>
      </div>
      <div class="dashboard-item full-dashboard">
        <div class="item-container report">
          <div class="page-title">ประกาศจาก Live Tycoon</div>
          <div class="annoucements">
            <ul>
              <li v-for="annoucement in annoucements" :key="annoucement.id">{{annoucement.title}} <span class="post-date">{{annoucement.post_date}}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
//import axios from "axios";
//import { backendURL } from "../config";
export default {
  name: "VendorDashboard",
  components: {
    VendorMenu,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    omiseHandler(e){
      e.preventDefault();
      this.paymentConfigure();
      this.omisePaymentHandler();
    },
    paymentConfigure(){
      // eslint-disable-next-line
      OmiseCard.configure({
        publicKey: 'pkey_test_5o4pr90kq25hukm0b55',
        defaultPaymentMethod: 'internet_banking',
        otherPaymentMethods: 'credit_card,truemoney,rabbit_linepay,alipay',
        frameLabel: 'Live Tycoon',
        locale:'th',
        submitLabel: 'Pay',
      });
      // eslint-disable-next-line
      OmiseCard.configureButton('#checkout-button');
      // eslint-disable-next-line
      OmiseCard.attach();
    },
    omisePaymentHandler(){
      // eslint-disable-next-line
      OmiseCard.open({
        amount: 30000,
        onCreateTokenSuccess: (nonce) => {
          /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
          // eslint-disable-next-line
          console.log(nonce)
        },
        onFormClosed: () => {
          /* Handler on form closure. */
        },
      })
    }
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
  },
  data() {
    return {
      shopInfo: '',
      shopLocation: '',
      membership: '',
      annoucements:[
        {id:0,title: 'ลิมูซีนซังเต ดอกเตอร์ซูฮกวินรีดไถล้มเหลว สปาย ช็อปแฟล็ตเอ็นเตอร์เทนก๊วนจูเนียร์', post_date: '21/06/2021'},
        {id:1,title: ' แผดเผา เห็นด้วยแกรนด์แฟรนไชส์ชาร์จตัวตน ซื่อบื้อศิลปวัฒนธรรม เป่ายิงฉุบ', post_date: '21/06/2021'},
      ]
    };
  },
};
</script>

<style scoped>
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
}
.full-dashboard{
  width: 98.2%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
  height:auto;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #f1f6f8;
  border-radius: 50%;
  padding: 12px;
  width:60px;
  height: 60px;
  color: #196a9f;
}
.upper-icon img{
  width:100%;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.annoucements{
  font-weight: normal;
  margin-top: 10px;
}
.annoucements ul{
  padding-left: 20px;
}
.annoucements li{
  padding-top:15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.annoucements .post-date{
  float:right;
  color: #196a9f;
}
.report a{
  text-decoration: none;
}
.dashboardbtt{
  padding: 5px 5px;
  background-color: #d4edfc;
  font-size: 14px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-top:5px;
  text-align: center;
  cursor: pointer;
}
.dashboardbtt:hover{
  background-color:white;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>