<template>
  <div class="dashboard-page">
    <AdminMenu class="admin-menu"></AdminMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <AdminMenu class="mobile-menu"></AdminMenu>
    </div>
    <div class="dashboard-body">
      <div class="admin-page-title">
        <div class="page-title">SORT</div>
        <div class="page-subtitle">Manage Sort for Posts</div>
      </div>
      <div style="height: 25px" class="clearfix"></div>
      <div class="account-dashboard dashboard-item full">
        <div class="item-container table-list">
          <div class="dashboard-title">Sort By Day of week</div>
          <vue-good-table
            @on-cell-click="selectCell"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 30,
            }"
            styleClass="vgt-table"
          />
        </div>
      </div>
      <div class="account-dashboard dashboard-item full">
        <div class="item-container table-list">
          <div class="dashboard-title">Sort Type</div>
          <vue-good-table
            :columns="columns2"
            :rows="rows2"
            styleClass="vgt-table"
          />
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import AdminMenu from "@/components/AdminMenu";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { backendURL } from "../config";

export default {
  name: "Users",
  components: {
    AdminMenu,
    VueGoodTable,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    selectCell(params) {
      if (params.column.field == "view") {
        // alert(params.row.SortType);
        // alert(params.row.Sortcat);
        // if(this.rows[params.row.originalIndex].Status.indexOf('Open') != -1){
        // 	//alert("Open - 0");
        // 	this.toggleStatus("0",params.row.id,params.row.originalIndex,"<div class='user-status closed'>Closed</div>");
        //
        // }else{
        // 	//alert("Close - 1");
        // 	this.toggleStatus("1",params.row.id,params.row.originalIndex,"<div class='user-status open'>Open</div>");
        // }
        //this.$router.push({ name: 'Visit', params: { id: params.row.id }})
      }
    },
    async toggleStatus(status, id, index, text) {
      let newstatus = "0";
      let self = this;
      if (status === "0") {
        newstatus = 1;
      } else {
        newstatus = "0";
      }
      let changes = {
        id: id,
        Status: newstatus,
      };
      await axios
        .post(`${backendURL}/updateshoppost/`, changes)
        .then(function (response) {
          self.rows[index].Status = text;
          self.output = response.status;
        })
        .catch(function (error) {
          self.output = error;
        });
    },
    async getShopPost() {
      const response = await axios.get(`${backendURL}/getsortday/`);
      const response2 = await axios.get(`${backendURL}/getsorttype/`);
      this.columns = [
        {
          label: "id",
          field: "id",
        },
        {
          label: "Day",
          field: "Day",
          type: "String",
        },
        {
          label: "SortTypeID",
          field: "SortType",
          type: "String",
        },
        {
          label: "SortType",
          field: "Sortcat",
          html: "true",
        },
        {
          label: "Update",
          field: "view",
          type: "String",
          html: "true",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
      this.columns2 = [
        {
          label: "id",
          field: "id",
        },
        {
          label: "Sort Type",
          field: "Name",
          type: "String",
        },
      ];
      this.rows2 = response2.data;
      this.rows = [];
      response.data.forEach((element) => {
        switch (element.SortType) {
          case 1:
            element.Sortcat = this.rows2[0].Name;
            break;
          case 2:
            element.Sortcat = this.rows2[1].Name;
            break;
          case 3:
            element.Sortcat = this.rows2[2].Name;
            break;
          case 4:
            element.Sortcat = this.rows2[3].Name;
            break;
          case 5:
            element.Sortcat = this.rows2[4].Name;
            break;
          case 6:
            element.Sortcat = this.rows2[5].Name;
            break;
          default:
            element.Sortcat = "Default";
        }
        element.view = "<a class='showbtt' target='_blank'>Update</a>";
        this.rows.push(element);
      });

      //this.rows = response.data;
      //this.onlinecount = response.data[0];
    },
    toggleMobileMenu: function () {
      if (this.menuState == "menu-open") {
        this.setMenuClass("menu-close");
      } else {
        this.setMenuClass("menu-open");
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    triggerShopSidebar: function () {
      if (this.shopSideBarState == "hide-shopside-menu") {
        this.setSideBarMenuClass("show-shopside-menu");
        this.setMenuClass("menu-close");
      } else {
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    this.getShopPost();
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Age",
          field: "age",
          type: "number",
        },
        {
          label: "Created On",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM Do yy",
        },
        {
          label: "Percent",
          field: "score",
          type: "percentage",
        },
      ],
      rows: [{ id: 1, name: "John", age: 20, createdAt: "", score: 0.03343 }],
      columns2: [],
      rows2: [],
      mon: 1,
      tue: 1,
      wed: 1,
      thu: 1,
      fri: 1,
      sat: 1,
      sun: 1,
      montype: "Dafault",
      tuetype: "Dafault",
      wedtype: "Dafault",
      thutype: "Dafault",
      fritype: "Dafault",
      sattype: "Dafault",
      suntype: "Dafault",
    };
  },
};
</script>

<style scoped>
.dashboard-page{
  background-color: #196a9f;
}
.dashboard-body {
  margin-left: 280px;
}
.admin-page-title {
  color: white;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.admin-page-title .page-title {
  font-weight: bold;
  float: left;
}
.admin-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  background-color: #0e4575;
  border-radius: 0.25rem;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #0f4370;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: white;
  margin-bottom: 1.5%;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #33be8f;
  border-radius: 50%;
  padding: 12px;
  color: white;
}
.upper-icon svg {
  color: #fff;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  cursor: pointer;
}
/deep/.showbtt {
  color: #f3155b;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
/deep/.vgt-global-search {
  background: #0e4575;
  border: 0px;
  margin-top: 20px;
}
@media screen and (max-width: 1100px) {
  .admin-menu {
    display: none;
  }
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}
</style>