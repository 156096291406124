import Vue from "vue";
import router from "./router";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import store from "./store";
import VueGtag from "vue-gtag";
import firebase from 'firebase/app'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import wysiwyg from "vue-wysiwyg";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VAnimateCss from "v-animate-css";
import VueMasonry from "vue-masonry-css";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
import VueMeta from "vue-meta";
import VTooltip from "v-tooltip";
import Snotify, { SnotifyPosition } from "vue-snotify";
import "vue-snotify/styles/material.css"; // or dark.css or simple.css
import { backendURL } from './config'
var VueScrollTo = require("vue-scrollto");

const socket = io(backendURL);
// const socket = io('localhost:3000');

const HelloJs = require("hellojs/dist/hello.all.min.js");
const VueHello = require("vue-hellojs");

HelloJs.init(
  {
    google:
      "668726088766-cufpropmpk1cmgu6q7rl89vhggdhrc40.apps.googleusercontent.com",
    facebook: "290319285330215",
  },
  {
    redirect_uri: "/authcallback",
  }
);

var firebaseConfig = {
  apiKey: "AIzaSyAGWEe52xV78hzrlhkLTh6hMxdNyBPWfxA",
  authDomain: "tortycoon-4913a.firebaseapp.com",
  databaseURL: "https://tortycoon-4913a.firebaseio.com",
  projectId: "tortycoon-4913a",
  storageBucket: "tortycoon-4913a.appspot.com",
  messagingSenderId: "456896027251",
  appId: "1:456896027251:web:753d721fcc37550c9b2806",
  measurementId: "G-1BRZ4SEMFL"
};
firebase.initializeApp(firebaseConfig)

Vue.use(VueHello, HelloJs);

Vue.use(VueSocketIOExt, socket);
Vue.use(VueMeta);
Vue.use(VueScrollTo);

Vue.use(
  VueGtag,
  {
    config: { id: "UA-180772526-1" },
  },
  router
);

library.add(faSearch);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faStar);
library.add(faEye);
library.add(faClock);
library.add(faHeart);
library.add(faBars);
library.add(faThLarge);
library.add(faComments);
library.add(faCog);
library.add(faTimes);
library.add(faSignOutAlt);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VAnimateCss);
Vue.use(VueMasonry);
const moment = require("moment");
require("moment/locale/th");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(wysiwyg, { hideModules: { image: true } });
Vue.use(VTooltip);
const options = {
  toast: {
    position: SnotifyPosition.leftBottom,
  },
};
Vue.use(Snotify, options);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
