<template>
  <div class="dashboard-page">
    <AdminMenu class="admin-menu"></AdminMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <AdminMenu class="mobile-menu"></AdminMenu>
    </div>
    <div class="dashboard-body">
      <div class="admin-page-title">
        <div class="page-title">Staffs</div>
        <div class="page-subtitle">All Tycoon Staffs</div>
      </div>
      <div style="height: 25px" class="clearfix"></div>
      <div class="account-dashboard dashboard-item full">
        <div class="item-container table-list">
          <div class="content-menu">
            <div v-on:click="showAddNew()" class="menu-button main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Add
            </div>
          </div>
          <div class="dashboard-title">All Staffs</div>
          <vue-good-table
            @on-cell-click="selectCell"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 30,
            }"
            styleClass="vgt-table"
          />
        </div>
      </div>
    </div>
    <sweet-modal
      :title="newsModalTitle"
      blocking="false"
      ref="userModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input">
        <div class="form-label">User ID</div>
        <input v-model="newstaffID" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">Name</div>
        <input v-model="newstaffname" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">Password</div>
        <!--<textarea v-model="StreamTitle" class="form-control"></textarea>-->
        <input v-model="newstaffpass" class="form-control" type="password" />
      </div>
      <sweet-button
        v-if="operation == 'New'"
        v-on:click="addStaff()"
        slot="button"
        ><div class="newConfirmBtt">Confirm</div></sweet-button
      >
      <sweet-button
        v-if="operation == 'Edit'"
        v-on:click="editNews()"
        slot="button"
        ><div class="newConfirmBtt">Confirm</div></sweet-button
      >
    </sweet-modal>
    <sweet-modal
      ref="successNews"
      modal-theme="light"
      overlay-theme="dark"
      icon="success"
    >
      <div class="successLive">เพิ่ม Staff ใหม่แล้ว</div>
    </sweet-modal>
    <sweet-modal
      ref="deleteStaff"
      modal-theme="light"
      overlay-theme="dark"
      icon="success"
    >
      <div class="successLive">ลบ Staff แล้ว</div>
    </sweet-modal>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import AdminMenu from "@/components/AdminMenu";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { SweetModal } from "sweet-modal-vue";
import { backendURL } from "../config";
export default {
  name: "Users",
  components: {
    AdminMenu,
    VueGoodTable,
    SweetModal,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    selectCell(params) {
      /* if(params.column.field == "Status"){
				if(this.rows[params.row.originalIndex].Status.indexOf('Open') != -1){
					this.toggleStatus("0",params.row.id,params.row.originalIndex,"<div class='user-status closed'>Closed</div>");
					
				}else{
					this.toggleStatus("1",params.row.id,params.row.originalIndex,"<div class='user-status open'>Open</div>");
				}
			} */
      if (params.column.field == "Delete") {
        var r = confirm("Are you sure you want to delete?");
        if (r == true) {
          this.deleteStaff(params.row.FCusID);
        }
      }
    },
    showAddNew() {
      this.staffID = "";
      this.newstaffID = "";
      this.newstaffname = "";
      this.newstaffpass = "";
      this.operation = "New";
      this.newsModalTitle = "Add Staff";
      this.$refs.userModal.open();
    },
    async addStaff() {
      var self = this;
      let newsParam = {
        FCusID: this.newstaffID,
        FCusName: this.newstaffname,
        FCusNickName: "",
        RoleID: 4,
        Password: this.newstaffpass,
      };
      try {
        await axios.post(`${backendURL}/addstaff/`, newsParam);
        self.$refs.userModal.close();
        self.$refs.successNews.open();
        self.getStaffs();
      } catch (error) {
        // console.error(error);
      }
    },
    async toggleStatus(status, id, index, text) {
      let newstatus = "0";
      let self = this;
      if (status === "0") {
        newstatus = 1;
      } else {
        newstatus = "0";
      }
      let changes = {
        id: id,
        Status: newstatus,
      };
      await axios
        .post(`${backendURL}/updateshop/`, changes)
        .then(function (response) {
          self.rows[index].Status = text;
          self.output = response.status;
        })
        .catch(function (error) {
          self.output = error;
        });
    },
    async getStaffs() {
      const response = await axios.get(`${backendURL}/getstaffs/`);
      this.columns = [
        {
          label: "RoleID",
          field: "RoleID",
          hidden: true,
        },
        {
          label: "ID",
          field: "FCusID",
          type: "String",
        },
        {
          label: "Name",
          field: "FCusName",
          type: "String",
        },
        {
          label: "Role",
          field: "RoleName",
          type: "String",
        },
        {
          label: "Created",
          field: "created_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM do yy",
          type: "date",
        },
        {
          label: "Last Update",
          field: "updated_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM do yy",
          type: "date",
        },
        /*{
					label: 'Status',
					field: 'Status',
					type: 'String',
					html: 'true',
					thClass: 'text-center',
					tdClass: 'text-center',
				},*/
        /*{
					label: 'View',
					field: 'View',
					type: 'String',
					html: 'true',
					thClass: 'text-center',
					tdClass: 'text-center',
				},*/
        /* {
					label: '',
					field: 'Edit',
					type: 'String',
					html: 'true',
					thClass: 'text-center',
					tdClass: 'text-center',
				},*/
        {
          label: "",
          field: "Delete",
          type: "String",
          html: "true",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
      response.data.forEach((element) => {
        element.created_at = element.created_at.split("T")[0];
        element.updated_at = element.updated_at.split("T")[0];
        /* if(element.Status == 1){
					element.Status = "<div class='user-status closed'>Closed</div>"
				} else{
					element.Status = "<div class='user-status open'>Open</div>"
				}*/
        // element.View = '<svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f3155b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>'
        element.Delete =
          '<svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#CFA904" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>';
        // element.Edit = '<svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#5797CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>'
      });
      this.rows = response.data;
      //this.onlinecount = response.data[0];
    },
    toggleMobileMenu: function () {
      if (this.menuState == "menu-open") {
        this.setMenuClass("menu-close");
      } else {
        this.setMenuClass("menu-open");
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    triggerShopSidebar: function () {
      if (this.shopSideBarState == "hide-shopside-menu") {
        this.setSideBarMenuClass("show-shopside-menu");
        this.setMenuClass("menu-close");
      } else {
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    newsearch() {
      this.searchTerm = this.filterkeyword;
    },
    async deleteStaff(id) {
      var self = this;
      let delstaff = {
        FCusID: id,
        RoleID: 4,
      };
      // eslint-disable-next-line
      //   console.log(delstaff);

      try {
        await axios.post(`${backendURL}/deletestaff/`, delstaff);
        self.$refs.deleteStaff.open();
        self.getStaffs();
      } catch (error) {
        // console.error(error);
      }
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    this.getStaffs();
  },
  data() {
    return {
      searchTerm: "",
      filterkeyword: "",
      editingID: "",
      newstaffID: "",
      newstaffname: "",
      newstaffpass: "",
      operation: "New",
      newsModalTitle: "Add Staff",
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Age",
          field: "age",
          type: "number",
        },
        {
          label: "Created On",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM Do yy",
        },
        {
          label: "Percent",
          field: "score",
          type: "percentage",
        },
      ],
      rows: [],
    };
  },
};
</script>

<style scoped>
.dashboard-page{
  background-color: #196a9f;
  min-height: 100vh;
}
.dashboard-body {
  margin-left: 280px;
}
.admin-page-title {
  color: white;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.admin-page-title .page-title {
  font-weight: bold;
  float: left;
}
.admin-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  background-color: #0e4575;
  border-radius: 0.25rem;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #0f4370;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: white;
  margin-bottom: 1.5%;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #33be8f;
  border-radius: 50%;
  padding: 12px;
  color: white;
}
.upper-icon svg {
  color: #fff;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.menu-button {
  width: 100px;
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.menu-button.main {
  background-color: #f3155b;
}
.menu-button.main:hover {
  background-color: #af1445;
}
.menu-button svg {
  margin-top: -4px;
}
.content-menu {
  margin-bottom: 20px;
}
.newConfirmBtt {
  background-color: #f3155b;
  padding: 10px 0px;
  width: 120px;
  text-align: center;
  float: right;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.form-input {
  margin-bottom: 15px;
  text-align: left;
}
.form-input input {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  font-family: "Kanit";
}
/deep/.sweet-modal .sweet-title > h2 {
  margin-top: 19px;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
/deep/.vgt-global-search {
  background: #0e4575;
  border: 0px;
  margin-top: 20px;
}
@media screen and (max-width: 1100px) {
  .admin-menu {
    display: none;
  }
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}
</style>