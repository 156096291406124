<template>
    <div class="admin menu-container">
       <div class="large-screen">
        <vuescroll :ops="ops">
            <div class="menu-wrapper">
                <div class="admin-logo"><router-link to="/"><div class="logo"><img src="../assets/tylogow.png"/></div></router-link></div>
                <div class="main-menus">
                    <div v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            <div class="admin-menu-txt">Dashboard</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/admin/users">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            <div class="admin-menu-txt">Users</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/admin/posts">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-feather"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>
                            <div class="admin-menu-txt">Posts</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div  v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/sort">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                            <div class="admin-menu-txt">Sort</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/admin/live">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>
                            <div class="admin-menu-txt">Tycoon LIVE</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div  v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/news">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic"><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>
                            <div class="admin-menu-txt">News</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div  v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/staffs">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                            <div class="admin-menu-txt">Staffs</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/app">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>
                            <div class="admin-menu-txt">App</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div v-if="user.role === 3" class="admin-menu-item">
                        <router-link to="/admin/uploads">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                            <div class="admin-menu-txt">Uploads</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div v-on:click="logOut()" class="admin-menu-item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        <div class="admin-menu-txt">Log Out</div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </vuescroll>
       </div>
       <div class="clearfix"></div>
    </div>
</template>

<script>
import vuescroll from 'vuescroll';
import { mapGetters, mapActions } from "vuex"
export default {
    name: "AdminMainMenu",
    data: function () {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            search:'',
            menuStatus:'unloaded',
            categoryClass: 'closed',
            ops: {
                vuescroll: {},
                scrollPanel: {},
                rail: {},
                bar: {
                    background: '#196A9F',
                    opacity: '0'
                }
            }
        }
    },
    components: {
      vuescroll,
    },
    computed: mapGetters(["menuState","shopMenuState","shopSideBarState","backendState"]),
    created() {
    },
    methods:{
        toggleCategories: function(){
            if (this.categoryClass == 'open') {
                this.categoryClass = 'closed';
            } else {
                this.categoryClass = 'open';
            }
        },
        logOut: function(){
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            this.$router.replace('/admin/login')
        },
        ...mapActions(["setMenuClass","setSideBarMenuClass"])
    }
}
</script>

<style lang="scss">
    .admin.menu-container{
        padding:0px;
        background-color:#196a9f;
        box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        border:1px solid #196a9f;
        background-image: none;
        z-index: 2222;
    }
    .admin-logo{
        height:70px;
        text-align: center;
        padding:18px 24px 0px 24px;
        background-color:#196a9f
    }
    .admin-logo .logo img{
        height:35px;
        width:auto;
    }
    .admin .main-menus{
        margin-top:10px;
        background-color:#196a9f;
    }
    .admin-menu-item{
        text-align: center;
        padding-left:24px;
        padding-top:12px;
        padding-bottom:12px;
        cursor:pointer;
        transition: all 0.2s ease;
        color:#a6b0cf;
    }
    .admin-menu-item a{
        color:#a6b0cf;
    }
    .admin-menu-item a:hover{
        color:#fff;
    }
    .admin-menu-item svg{
        float:left;
    }
    .admin-menu-txt{
        float:left;
        margin-left:10px;
    }
    .mobile-screen{
        display:none;
    }
    @media only screen and (max-width: 1100px) {
        .admin.menu-container{
            background-color:#032a4b;
        }
        .admin-logo{
            display:none;
        }
        .admin .main-menus{
            margin-top:75px;
        }
    }
</style>