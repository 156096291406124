<template>
  <div class="shop-container">
    <div class="shop-wrapper">
      <div class="main-content">
        <div id="stream-video" class="stream-video-wrapper">
          <iframe
            width="100%"
            height="100%"
            :src="streamURL"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="shop-live-mobile">
          <!--<iframe width="100%" height="200" src="https://www.youtube.com/embed/IIzHiKZ8c6g?autoplay=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          <div
            style="margin-top: 20px; margin-bottom: 15px"
            class="shop-section"
          >
            <div class="section-label">
              Shop
              <div class="shop-location">{{ shopInfo.Location }}</div>
            </div>
            <div class="section-detail">{{ shopInfo.Name }}</div>
          </div>
        </div>
        <audio style="display: none" id="notiSound">
          <source src="../assets/noti.mp3" type="audio/mpeg" />
        </audio>
        <div class="filter-group">
          <!-- <div class="filter-item filter-button">Most Likes</div>
            <div class="filter-item filter-button">Most Viewed</div>
            <div class="filter-item filter-button">Breakfast</div>
            <div class="filter-item filter-button">Lunch</div>
            <div class="filter-item filter-button">Dinner</div>
            <div class="filter-item filter-button">Dessert</div>
            <div class="filter-item filter-button">Hottest Deal</div> -->
          <div
            v-for="(category, index) in allShopCategories"
            :key="category.id"
            v-on:click="setShopCategory(index, category.CategoryID)"
            class="filter-item filter-button"
            :class="category.status"
          >
            <span v-if="category.CategoryID == 0">ทั้งหมด</span>
            <span v-if="category.CategoryID == 2">แฟชั่นผู้ชาย</span>
            <span v-if="category.CategoryID == 1">แฟชั่นผู้หญิง</span>
            <span v-if="category.CategoryID == 3">เด็ก</span>
            <span v-if="category.CategoryID == 4">เครื่องประดับ</span>
            <span v-if="category.CategoryID == 5">อุปกรณ์ อิเล็กทรอนิกส์</span>
            <span v-if="category.CategoryID == 6">อุปกรณ์ ประดับรถยนต์</span>
            <span v-if="category.CategoryID == 7">สุขภาพและความงาม</span>
            <span v-if="category.CategoryID == 8">อาหาร</span>
            <span v-if="category.CategoryID == 9">ขนมหวาน</span>
            <span v-if="category.CategoryID == 10">เครื่องดื่ม</span>
            <span v-if="category.CategoryID == 11">สัตว์เลี้ยง</span>
          </div>
        </div>
        <vue-easy-lightbox
          moveDisabled
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
        <masonry
          class="post-container"
          v-animate-css="'zoomIn'"
          :cols="{ default: 3, 1600: 2, 1100: 2, 800: 2, 600: 1 }"
          :gutter="{ default: '20px', 1200: '15px' }"
        >
          <div v-for="post in allShopPosts" :key="post.id">
            <div class="post-wrapper">
              <div class="post-top-section">
                <div
                  v-if="post.Banner.split('/')[4] == 'images'"
                  class="post-image"
                >
                  <img v-bind:src="post.Banner" />
                  <div
                    v-on:click="showSingle(post.Banner, post.Description)"
                    class="post-view"
                  >
                    <font-awesome-icon class="view-icon" icon="eye" />
                  </div>
                </div>
                <div v-else class="post-video">
                  <!-- <video style="width:100%;" controls :src="post.Banner" class="view--video__video"></video> -->
                  <VideoPlayer :videoURL="post.Banner"></VideoPlayer>
                </div>
                <div class="post-title">{{ post.Title }}</div>
                <div class="post-desc">{{ post.Description }}</div>
              </div>
              <div class="post-bottom-section">
                <!-- <div class="read-more">Read More</div> -->
                <div class="post-footer">
                  <div
                    v-on:click="setShopCategorybyID(0)"
                    v-if="post.CategoryID == 0"
                    class="footer-item footer-category"
                  >
                    ทั้งหมด
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(2)"
                    v-if="post.CategoryID == 2"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้ชาย
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(1)"
                    v-if="post.CategoryID == 1"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้หญิง
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(3)"
                    v-if="post.CategoryID == 3"
                    class="footer-item footer-category"
                  >
                    เด็ก
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(4)"
                    v-if="post.CategoryID == 4"
                    class="footer-item footer-category"
                  >
                    เครื่องประดับ
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(5)"
                    v-if="post.CategoryID == 5"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ อิเล็กทรอนิกส์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(6)"
                    v-if="post.CategoryID == 6"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ ประดับรถยนต์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(7)"
                    v-if="post.CategoryID == 7"
                    class="footer-item footer-category"
                  >
                    สุขภาพและความงาม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(8)"
                    v-if="post.CategoryID == 8"
                    class="footer-item footer-category"
                  >
                    อาหาร
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(9)"
                    v-if="post.CategoryID == 9"
                    class="footer-item footer-category"
                  >
                    ขนมหวาน
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(10)"
                    v-if="post.CategoryID == 10"
                    class="footer-item footer-category"
                  >
                    เครื่องดื่ม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(11)"
                    v-if="post.CategoryID == 11"
                    class="footer-item footer-category"
                  >
                    สัตว์เลี้ยง
                  </div>
                  <!--<div class="footer-item footer-date post-date">
                    <font-awesome-icon
                      class="post-icon"
                      :icon="['far', 'clock']"
                    />
                    <span>{{ post.updated_at | moment("MMMM DD") }}</span>
                  </div>-->
                  <div class="post-shop-location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    กรุงเทพฯ
                  </div>
                  <div class="view-wrapper">
                    <div style="float: right" class="footer-item footer-date">
                      <svg
                        style="margin-right: 10px; margin-top: -3px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ec7465"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-bag"
                      >
                        <path
                          d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                        ></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      <span>{{ post.PriceFrom }} - {{ post.PriceTill }} บาท</span>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </masonry>
      </div>
      <div class="sidebar-right">
        <!-- <div class="shop-section shop-video">
          <div class="shop-intro"><img v-bind:src="shopInfo.Logo"/></div>
        </div> -->
        <!--<div class="shop-section">
          <div class="section-label">LIVE</div>
          <iframe width="240" height="200" src="https://www.youtube.com/embed/IIzHiKZ8c6g?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>-->
        <!--
        <div class="shop-section">
          <div class="section-label">Shop</div>
          <div class="section-detail">{{shopInfo.Name}}</div>
        </div>
        <div class="shop-section">
          <div class="section-label">Location</div>
          <div class="section-detail">{{shopInfo.Location}}</div>
        </div>
        <div class="shop-section">
          <div class="section-label">About</div>
          <div class="section-detail">{{shopInfo.Description}}</div>
        </div>-->
        <!--<div class="shop-section">
          <div class="section-label">Social Media</div>
          <div class="section-detail">
            <div class="social-media-wrapper">
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.facebook.com/"+shopInfo.Facebook'><img src="../assets/Facebook.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.instagram.com/"+shopInfo.Instagram'><img src="../assets/Instagram.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://line.me/R/ti/p/"+shopInfo.Line'><img src="../assets/LINE.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://twitter.com/"+shopInfo.Twitter'><img src="../assets/Twitter.png"/></a></div>
            </div>
          </div>
        </div>-->
        <div class="shop-section top-section">
          <div class="section-label">เกี่ยวกับร้านค้า</div>
          {{ shopInfo.Name }} - {{ shopInfo.Location }}<br />
          {{ shopInfo.Description }}
        </div>
        <div class="chat-header">GROUP CHAT</div>
        <div id="stream-chatbox" class="chat-body">
          <div class="chat-welcome">Welcome to the chat</div>
          <!-- <div v-mod v-for="msg in chatmsgs" v-bind:key="msg.id" class="chat-msg">
            <div class="chat-user-msg"><span :style="msg.color">{{msg.user}}: </span>{{msg.text}}</div>
          </div> -->
          <div
            v-bind:class="msg.sender"
            v-mod
            v-for="msg in chatmsgs"
            v-bind:key="msg.id"
            class="chat-msg"
          >
            <div
              v-if="msg.sender == 'Customer'"
              :class="msg.color"
              class="bubble-speech bubble-left"
            >
              <div
                style="font-family: 'Kanit'"
                :style="msg.color"
                class="message"
              >
                <span style="color: #333; font-weight: bold"
                  >{{ msg.user }}:</span
                >
                {{ msg.text }}
              </div>
            </div>
            <div v-else class="bubble-speech bubble-right">
              <div class="message">
                {{ msg.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="chat-input">
          <input
            @focus="checkUserStatus()"
            v-model="usermsg"
            @keyup.enter="sendChat()"
            placeholder="Send a Message"
          />
          <div v-if="userInfo.id" class="send-chat">
            <div v-on:click="sendChat()" class="chatbtt">Chat</div>
            <div v-bind:class="chatSettingClass" class="chat-setting">
              <div class="setting-header">ตั้งค่าการแชท</div>
              <font-awesome-icon
                v-on:click="toggleChatSetting()"
                class="close-icon"
                icon="times"
              />
              <div class="chat-setting-header">ชื่อที่แสดงของคุณ</div>
              <input v-model="chatname" />
              <div class="chat-setting-header section">สีของชื่อ</div>
              <div class="chat-name-colors">
                <div
                  v-for="color in settingColors"
                  v-bind:style="color.style"
                  v-bind:key="color.code"
                  v-bind:class="color.colorClass"
                  v-on:click="changeColor(color)"
                  class="color-choice"
                ></div>
              </div>
              <!--<div v-on:click="signOut()" class="signout">ออกจากระบบ</div>-->
              <div v-on:click="toggleChatSetting()" class="saveSetting">
                ยืนยัน
              </div>
            </div>
            <font-awesome-icon
              v-on:click="toggleChatSetting()"
              class="chat-setting-icon"
              icon="cog"
            />
            <font-awesome-icon
              v-on:click="signOut()"
              class="signout-setting-icon"
              icon="sign-out-alt"
            />
          </div>
          <div v-else>
            <div v-on:click="goLogin()" class="signinbtt">เข้าสู่ระบบ</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-on:click="closeAllMenu()"
      v-bind:class="shopSideBarState"
      class="menu-overlay"
    >
      Tycoon
    </div>
    <div v-bind:class="shopSideBarState" class="sidebar-mobile-right">
      <!-- <div class="shop-section shop-video">
        <div class="shop-intro"><img v-bind:src="shopInfo.Logo"/></div>
      </div>
      <div class="shop-section">
        <div class="section-label">About</div>
        <div class="section-detail">{{shopInfo.Description}}</div>
      </div>
      <div class="shop-section">
        <div class="section-label">Social Media</div>
        <div class="section-detail">
          <div class="social-media-wrapper">
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.facebook.com/"+shopInfo.Facebook'><img src="../assets/Facebook.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.instagram.com/"+shopInfo.Instagram'><img src="../assets/Instagram.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://line.me/R/ti/p/"+shopInfo.Line'><img src="../assets/LINE.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://twitter.com/"+shopInfo.Twitter'><img src="../assets/Twitter.png"/></a></div>
          </div>
        </div>
      </div> -->
      <div class="shop-section top-section">
        <div class="section-label">เกี่ยวกับร้านค้า</div>
        {{ shopInfo.Name }} - {{ shopInfo.Location }}<br />
        {{ shopInfo.Description }}
      </div>
      <div class="chat-header">GROUP CHAT</div>
      <div id="stream-chatbox" class="chat-body">
        <div class="chat-welcome">Welcome to the chat</div>
        <!-- <div v-mod v-for="msg in chatmsgs" v-bind:key="msg.id" class="chat-msg">
            <div class="chat-user-msg"><span :style="msg.color">{{msg.user}}: </span>{{msg.text}}</div>
          </div>-->
        <div
          v-bind:class="msg.sender"
          v-mod
          v-for="msg in chatmsgs"
          v-bind:key="msg.id"
          class="chat-msg"
        >
          <div
            v-if="msg.sender == 'Customer'"
            :class="msg.color"
            class="bubble-speech bubble-left"
          >
            <div
              style="font-family: 'Kanit'"
              :style="msg.color"
              class="message"
            >
              <span style="color: #333; font-weight: bold"
                >{{ msg.user }}:</span
              >
              {{ msg.text }}
            </div>
          </div>
          <div v-else class="bubble-speech bubble-right">
            <div class="message">
              {{ msg.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="chat-input">
        <input
          @focus="checkUserStatus()"
          v-model="usermsg"
          @keyup.enter="sendChat()"
          placeholder="Send a Message"
        />
        <div v-if="userInfo.id" class="send-chat">
          <div v-on:click="sendChat()" class="chatbtt">Chat</div>
          <div v-bind:class="chatSettingClass" class="chat-setting">
            <div class="setting-header">ตั้งค่าการแชท</div>
            <font-awesome-icon
              v-on:click="toggleChatSetting()"
              class="close-icon"
              icon="times"
            />
            <div class="chat-setting-header">ชื่อที่แสดงของคุณ</div>
            <input v-model="chatname" />
            <div class="chat-setting-header section">สีของชื่อ</div>
            <div class="chat-name-colors">
              <div
                v-for="color in settingColors"
                v-bind:style="color.style"
                v-bind:key="color.code"
                v-bind:class="color.colorClass"
                v-on:click="changeColor(color)"
                class="color-choice"
              ></div>
            </div>
            <!--<div v-on:click="signOut()" class="signout">ออกจากระบบ</div>-->
            <div v-on:click="toggleChatSetting()" class="saveSetting">
              ยืนยัน
            </div>
          </div>
          <font-awesome-icon
            v-on:click="toggleChatSetting()"
            class="chat-setting-icon"
            icon="cog"
          />
          <font-awesome-icon
            v-on:click="signOut()"
            class="signout-setting-icon"
            icon="sign-out-alt"
          />
        </div>
        <div v-else>
          <div v-on:click="goLogin()" class="signinbtt">เข้าสู่ระบบ</div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <sweet-modal ref="register" modal-theme="dark" overlay-theme="dark">
      <CustomerLogin @close="closeModal()" ref="cutomerLogin"></CustomerLogin>
    </sweet-modal>
  </div>
</template>

<script>
import axios from "axios";
import VideoPlayer from "@/components/VideoPlayer";
import CustomerLogin from "@/components/CustomerLogin";
import VueEasyLightbox from "vue-easy-lightbox";
import { SweetModal } from "sweet-modal-vue";
import { mapGetters, mapActions } from "vuex";
import { backendURL } from "../config";

export default {
  name: "ShopPost",
  components: {
    VideoPlayer,
    VueEasyLightbox,
    SweetModal,
    CustomerLogin,
  },
  sockets: {
    connect: function () {
      // eslint-disable-next-line
      console.log("socket connected");
    },
  },
  mounted() {},
  created() {
    this.setShopMenuClass("show-menu");
    this.getAllShopPosts(this.$route.params.id);
    this.getShopInfo(this.$route.params.id);
    this.getAllShopCategory(this.$route.params.id);
    var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
    // eslint-disable-next-line
    console.log(chatSetting);
    if (localStorage.getItem("userSetting")) {
      this.chatname = chatSetting.name;
      var userInfo = { id: chatSetting.id };
      this.setUserLogin(userInfo);
      for (var i = 0; i < this.settingColors.length; i++) {
        if (this.settingColors[i].code == chatSetting.color) {
          this.settingColors[i].colorClass = "active";
          this.chatcolor = this.settingColors[i].code;
        }
      }
    } else {
      this.chatname = Math.floor(Math.random() * 9000 + 1);
      this.chatcolor = ((Math.random() * 0xffffff) << 0).toString(16);
    }
    // eslint-disable-next-line
    this.$socket.$subscribe("live" + this.$route.params.id, (payload) => {
      // eslint-disable-next-line
      console.log(payload);
      this.chatmsgs.push(payload);
      setTimeout(function () {
        var objDiv = document.getElementById("stream-chatbox");
        objDiv.scrollTop = 10000000000;
      }, 100);
    });
    var self = this;
    setTimeout(function () {
      // self.getChatHistory()
      self.getLiveStream();
    }, 500);
  },
  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0,
      usermsg: "",
      chatname: "",
      chatmsgs: [],
      chatcolor: "",
      chatSettingClass: "",
      chatroomid: "",
      streamURL: "",
      FBready: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src:
              "https://www.facebook.com/InterGalacticSpaceNinja/videos/1156507764720617/",
            type: "video/mp4",
          },
        ],
      },
      settingColors: [
        { code: "red", style: "background-color:#ff0000", colorClass: "" },
        { code: "blue", style: "background-color:#0000ff", colorClass: "" },
        {
          code: "darkgreen",
          style: "background-color:#008000",
          colorClass: "",
        },
        { code: "darkred", style: "background-color:#b22222", colorClass: "" },
        {
          code: "lightorange",
          style: "background-color:#ff7f50",
          colorClass: "",
        },
        {
          code: "lightgreen",
          style: "background-color:#9acd32",
          colorClass: "",
        },
        { code: "orange", style: "background-color:#ff4500", colorClass: "" },
        { code: "green", style: "background-color:#2e8b57", colorClass: "" },
        { code: "yellow", style: "background-color:#daa520", colorClass: "" },
        { code: "clay", style: "background-color:#d2691e", colorClass: "" },
        {
          code: "greygreen",
          style: "background-color:#5f9ea0",
          colorClass: "",
        },
        { code: "skyblue", style: "background-color:#1e90ff", colorClass: "" },
        { code: "pink", style: "background-color:#ff69b4", colorClass: "" },
        { code: "purple", style: "background-color:#8a2be2", colorClass: "" },
      ],
    };
  },
  methods: {
    async getLiveStream() {
      const response = await axios.get(
        `${backendURL}/getlivestreambyshopid/${this.$route.params.id}`
      );
      this.streamURL = response.data[0].StreamURL + "?autoplay=1";
      //this.streamURL = 'https://www.youtube.com/embed/goAGPFBRnd8?&controls=0&autoplay=1'
      //this.FBready = true
    },
    showSingle(url, desc) {
      this.imgs = [{ title: desc, src: url }];
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    goLogin() {
      this.$router.push({
        name: "AuthBack",
        query: { shop: this.$route.params.id },
      });
    },
    setShopCategory(index, categoryID) {
      this.setPostActiveCategory(index);
      var params = {
        id: this.$route.params.id,
        CategoryID: categoryID,
      };
      if (categoryID == "0") {
        this.getAllShopPosts(this.$route.params.id);
      } else {
        this.getAllShopPostsByCategory(params);
      }
    },
    setShopCategorybyID(categoryID) {
      for (var i = 0; i < this.allShopCategories.length; i++) {
        if (this.allShopCategories[i].CategoryID == categoryID) {
          this.setPostActiveCategory(i);
        }
      }
      var params = {
        id: this.$route.params.id,
        CategoryID: categoryID,
      };
      if (categoryID == "0") {
        this.getAllShopPosts(this.$route.params.id);
      } else {
        this.getAllShopPostsByCategory(params);
      }
    },
    async getChatHistory() {
      if (localStorage.getItem("userSetting")) {
        var params = {
          SellerID: this.shopInfo.FCusID,
          CustomerID: this.userInfo.id,
          Reader: "Customer",
        };
        const response = await axios.post(`${backendURL}/getchats/`, params);
        this.chatroomid = response.data.chatroom[0].id;
        var sellerid = response.data.SellerID;
        var chat = {};
        this.chatmsgs = [];
        for (var i = 0; i < response.data.chats.length; i++) {
          chat = {};
          chat.id = response.data.chats[i].id;
          chat.text = response.data.chats[i].ChatText;
          if (response.data.chats[i].ChatFrom === sellerid) {
            chat.sender = "Seller";
          } else {
            chat.sender = "Customer";
          }
          this.chatmsgs.push(chat);
        }
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox");
          objDiv.scrollTop = 10000000000;
        }, 100);
      }
    },
    sendChat() {
      if (this.userInfo.id) {
        /*var self = this
            // eslint-disable-next-line
            console.log('shop'+self.$route.params.id+self.userInfo.id)
            axios({
              url: 'https://api.livetycoon.com/addchat/',
              method: 'POST',
              data: {'ChatRoomID': this.chatroomid, 'ChatFrom': this.userInfo.id, 'ChatText': this.usermsg, 'Status': '0'}
            }).then(function () {
              var d = new Date()
              var msg = {
                id: d.getTime(),
                user: self.chatname,
                color: 'color:#f3155b',
                text: self.usermsg,
                room: 'shop'+self.$route.params.id+self.userInfo.id,
                usernoti: 'shop'+self.shopInfo.FCusID,
                userid: '',
                shopid: localStorage.getItem('shopID')
              }
              self.$socket.client.emit('shopChat', msg)
              self.$socket.client.emit('customerChat', msg)
              self.sendNotification()
            })
              .catch(function (error) {
                // eslint-disable-next-line
                console.log(error)
            })*/
        var d = new Date();
        var msg = {
          id: d.getTime(),
          user: this.chatname,
          // color: 'color:#f3155b',
          color: this.chatcolor,
          text: this.usermsg,
          room: "live" + this.$route.params.id,
          sender: "Customer",
          // usernoti: 'shop'+self.shopInfo.FCusID,
          //userid: '',
          //shopid: localStorage.getItem('shopID')
        };
        this.$socket.client.emit("liveChat", msg);
        this.usermsg = "";
        //this.$socket.client.emit('customerChat', msg)
      } else {
        this.$refs.cutomerLogin.reset();
        this.$refs.register.open();
      }
    },
    checkUserStatus() {
      if (!this.userInfo.id) {
        // this.$refs.cutomerLogin.reset()
        // this.$refs.register.open();
        this.$router.push({
          name: "AuthBack",
          query: { shop: this.$route.params.id },
        });
      }
    },
    sendNotification: function () {
      var appid = "f2889da0-ef8f-486e-b152-9b66de49e91e";
      var userpushid = [this.shopInfo.NotificationID];
      var self = this;
      axios({
        url: "https://onesignal.com/api/v1/notifications",
        method: "POST",
        data: {
          app_id: appid,
          include_player_ids: userpushid,
          headings: { en: this.chatname },
          contents: { en: this.usermsg },
        },
      })
        .then(function () {
          self.usermsg = "";
        })
        .catch(function (error) {
          // eslint-disable-next-line
          console.log(error.response);
        });
    },
    closeModal() {
      this.$refs.register.close();
      var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
      if (localStorage.getItem("userSetting")) {
        this.chatname = chatSetting.name;
        for (var i = 0; i < this.settingColors.length; i++) {
          if (this.settingColors[i].code == chatSetting.color) {
            this.settingColors[i].colorClass = "active";
            this.chatcolor = this.settingColors[i].code;
          }
        }
      }
    },
    toggleChatSetting() {
      var i;
      if (this.chatSettingClass == "") {
        this.chatSettingClass = "active";
      } else {
        this.chatSettingClass = "";
        var selectedcolor = "";
        for (i = 0; i < this.settingColors.length; i++) {
          if (this.settingColors[i].colorClass == "active") {
            selectedcolor = this.settingColors[i].code;
          }
        }
        var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
        var usersetting = {
          id: chatSetting.id,
          color: selectedcolor,
          name: this.chatname,
        };
        localStorage.setItem("userSetting", JSON.stringify(usersetting));
      }
    },
    signOut() {
      var r = confirm("คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ");
      if (r == true) {
        this.chatmsgs = [];
        this.logOutUser();
      }
    },
    changeColor(color) {
      for (var i = 0; i < this.settingColors.length; i++) {
        this.settingColors[i].colorClass = "";
        if (this.settingColors[i].code == color.code) {
          this.settingColors[i].colorClass = "active";
          this.chatcolor = this.settingColors[i].code;
        }
      }
    },
    closeAllMenu: function () {
      this.setMenuClass("menu-close");
      this.setSideBarMenuClass("hide-shopside-menu");
    },
    ...mapActions([
      "fetchShops",
      "getAllShopPosts",
      "getAllShopCategory",
      "setShopMenuClass",
      "getShopInfo",
      "setPostActiveCategory",
      "getAllShopPostsByCategory",
      "setUserLogin",
      "logOutUser",
      "setMenuClass",
      "setSideBarMenuClass",
    ]),
  },
  computed: mapGetters([
    "allShopPosts",
    "shopSideBarState",
    "allShopCategories",
    "shopInfo",
    "userInfo",
  ]),
};
</script>

<style scoped>
.shop-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: top;
  min-height: 100vh;
  padding-left: 20px;
}
.main-content {
  margin-right: 300px;
  position: relative;
  vertical-align: top;
}
.sidebar-right {
  width: 300px;
  display: table-cell;
  height: 100%;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  /* border-radius: 0px 0px 0px 50px; */
  position: relative;
  position: fixed;
  top: 0px;
  right: 0px;
}
.sidebar-mobile-right {
  width: 300px;
  height: 100%;
  display: none;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 30px 30px 50px;
  position: fixed;
  top: 50px;
  right: 0px;
  z-index: 999;
  transition: all 0.3s ease;
}
.shop-section {
  margin-bottom: 30px;
  font-family: "Kanit";
}
.section-label {
  color: #314052;
  font-size: 17px;
  position: relative;
  margin-bottom: 40px;
  font-weight: bold;
}
.section-label:after {
  content: "";
  position: absolute;
  display: block;
  height: 7px;
  width: 16px;
  border-radius: 2px;
  background-color: #537eff;
  bottom: -18px;
}
.shop-intro img {
  width: 100%;
}
.filter-group {
  margin-top: 10px;
}
.shop-live-mobile {
  border: 1px solid transparent;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 15px;
  border-radius: 12px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 75px;
}
.shop-location {
  display: block;
  padding: 2px 5px;
  background-color: #2863ec;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
  border: 1px solid #2863ec;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: auto;
  float: right;
}
.shop-live-mobile {
  display: none;
}
.menu-overlay.hide-shopside-menu {
  display: none;
}
.post-view {
  cursor: pointer;
}
.filter-item.active {
  background-color: rgba(243, 21, 91, 1);
  color: white;
}
.shop-section.top-section {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 0px;
}
.chat-header {
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}
.chat-body {
  padding: 20px;
  overflow: auto;
  position: absolute;
  height: 66%;
  width: 100%;
}
.chat-input {
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.chat-input input {
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
  border: 0px;
}
.send-chat {
  /*padding-right: 1rem;*/
  padding-bottom: 70px;
}
.chatbtt,
.signout,
.saveSetting {
  background-color: #2863ec;
  padding: 5px;
  color: white;
  width: 60px;
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
  border-radius: 3px;
  float: right;
  cursor: pointer;
}
.saveSetting {
  padding: 5px;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}
.signout {
  background-color: #f3155b;
  padding: 5px;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}
.chatbtt:hover,
.saveSetting:hover {
  background-color: #588aff;
}
.signout:hover {
  background-color: rgb(216, 12, 77);
}
.chat-user-msg {
  padding-top: 3px;
  padding-bottom: 3px;
}
.chat-user-msg span {
  font-weight: bold;
}
.chat-setting-icon {
  float: right;
  margin-right: 20px;
  margin-top: 19px;
  font-size: 18px;
  cursor: pointer;
}
.signout-setting-icon {
  float: left;
  margin-top: 19px;
  font-size: 18px;
  cursor: pointer;
  color: #9c9c9c;
  margin-left: 5px;
}
.signout-setting-icon:hover {
  color: #f3155b;
}
.chat-setting {
  -webkit-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  position: absolute;
  width: 93%;
  left: 3.5%;
  margin-top: -406px;
  background-color: white;
  padding: 10px;
  display: none;
  font-family: "Kanit";
  cursor: pointer;
  z-index: 9999;
}
.setting-header {
  text-align: center;
  font-weight: 600;
}
.chat-setting .close-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
.chat-setting.active {
  display: block;
}
.chat-setting-header {
  margin-top: 20px;
}
.chat-setting-header.section {
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
  margin-bottom: 15px;
}
.chat-setting input {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border: 0px;
}
.color-choice {
  width: 30px;
  float: left;
  height: 30px;
  margin-right: 13px;
  margin-bottom: 10px;
  border: 2px solid #c3c3c3;
}
.color-choice:hover {
  border: 2px solid #333;
}
.color-choice.active {
  border: 2px solid #333;
}
.signinbtt {
  font-family: "Kanit";
  background-color: #2863ec;
  padding: 9px 5px;
  color: white;
  text-align: center;
  margin-top: 10px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.signinbtt:hover {
  background-color: #588aff;
}
.chat-msg {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: flex;
}
.chat-msg.Seller {
  justify-content: flex-end;
}
.chat-user-msg {
  background-color: white;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
}
.bubble-speech {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 22px;
  letter-spacing: 1px;
  margin-top: 16px;
  background-color: #efefef;
  padding: 9px 16px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.12);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.12);
}
.bubble-speech.bubble-left {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 90%;
}
.bubble-speech.bubble-right {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #99d8ec;
  max-width: 90%;
}
.bubble-speech.bubble-left:after {
  z-index: 9002;
  display: block;
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  border-top: 0 #efefef solid;
  border-bottom: 12px transparent solid !important;
  border-left: 20px #efefef solid;
}
.bubble-speech.bubble-right:after {
  z-index: 9002;
  display: block;
  content: "";
  position: absolute;
  bottom: -11px;
  right: 0;
  border-top: 0 #99d8ec solid;
  border-bottom: 12px transparent solid;
  border-right: 20px #99d8ec solid;
}
.stream-video-wrapper {
  margin-top: 20px;
  width: 98%;
  height: 720px;
}
.bubble-speech.bubble-left.red {
  background-color: #ff0000;
  color: white;
}
.bubble-speech.bubble-left.red:after {
  border-color: #ff0000;
}
.bubble-speech.bubble-left.blue {
  background-color: #0000ff;
  color: white;
}
.bubble-speech.bubble-left.blue:after {
  border-color: #0000ff;
}
.bubble-speech.bubble-left.darkgreen {
  background-color: #008000;
  color: white;
}
.bubble-speech.bubble-left.darkgreen:after {
  border-color: #008000;
}
.bubble-speech.bubble-left.darkred {
  background-color: #b22222;
  color: white;
}
.bubble-speech.bubble-left.darkred:after {
  border-color: #b22222;
}
.bubble-speech.bubble-left.lightorange {
  background-color: #ff7f50;
  color: white;
}
.bubble-speech.bubble-left.lightorange:after {
  border-color: #ff7f50;
}
.bubble-speech.bubble-left.lightgreen {
  background-color: #9acd32;
  color: white;
}
.bubble-speech.bubble-left.lightgreen:after {
  border-color: #9acd32;
}
.bubble-speech.bubble-left.orange {
  background-color: #ff4500;
  color: white;
}
.bubble-speech.bubble-left.orange:after {
  border-color: #ff4500;
}
.bubble-speech.bubble-left.green {
  background-color: #2e8b57;
  color: white;
}
.bubble-speech.bubble-left.green:after {
  border-color: #2e8b57;
}
.bubble-speech.bubble-left.yellow {
  background-color: #daa520;
  color: white;
}
.bubble-speech.bubble-left.yellow:after {
  border-color: #daa520;
}
.bubble-speech.bubble-left.clay {
  background-color: #d2691e;
  color: white;
}
.bubble-speech.bubble-left.clay:after {
  border-color: #d2691e;
}
.bubble-speech.bubble-left.greygreen {
  background-color: #5f9ea0;
  color: white;
}
.bubble-speech.bubble-left.greygreen:after {
  border-color: #5f9ea0;
}
.bubble-speech.bubble-left.skyblue {
  background-color: #1e90ff;
  color: white;
}
.bubble-speech.bubble-left.skyblue:after {
  border-color: #1e90ff;
}
.bubble-speech.bubble-left.pink {
  background-color: #ff69b4;
  color: white;
}
.bubble-speech.bubble-left.pink:after {
  border-color: #ff69b4;
}
.bubble-speech.bubble-left.purple {
  background-color: #8a2be2;
  color: white;
}
.bubble-speech.bubble-left.purple:after {
  border-color: #8a2be2;
}
@media only screen and (max-width: 1100px) {
  .sidebar-right {
    display: none;
  }
  .main-content {
    margin-right: 0px;
  }
  .filter-group {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .filter-item {
    margin-top: 15px;
  }
  .sidebar-mobile-right {
    display: block;
  }
  .sidebar-mobile-right.hide-shopside-menu {
    right: -300px;
  }
  .sidebar-mobile-right.show-shopside-menu {
    right: 0px;
    padding: 0px;
  }
  .chat-body {
    height: 64vh;
  }
  .menu-overlay.show-shopside-menu {
    display: block;
  }
  .shop-live-mobile {
    display: none;
  }
  .sidebar-mobile-right {
    overflow: auto;
  }
  .chat-input {
    bottom: 35px;
  }
  .shop-wrapper {
    padding-left: 0px;
  }
  .post-container {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 550px) {
  .chat-body {
    height: 55vh;
  }
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .chat-body {
    height: 53vh;
  }
  .sidebar-mobile-right {
    overflow: auto;
  }
}
@media only screen and (max-width: 350px) {
  .post-date {
    display: none;
  }
}
@media only screen and (min-device-width: 330px) and (max-device-width: 1024px) and (orientation: portrait) {
  .stream-video-wrapper {
    margin-top: 55px;
    width: 100%;
    height: 300px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .stream-video-wrapper {
    margin-top: 55px;
    width: 100%;
    height: 720px;
  }
}
</style>