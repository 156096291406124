<template>
  <div class="markets category-page">
    <div v-if="allPosts.length > 0" v-animate-css="'fadeInDown'" class="market-post-header">
        <div class="search-header">
            {{this.$route.params.keyword}}
        </div>
    </div>
    <masonry v-if="allPosts.length > 0" class="post-container" v-animate-css="'zoomIn'"
        :cols="{default: 4, 1600: 3, 900:2, 700: 1, 400: 1}"
        :gutter="{default: '15px', 700: '0px'}"
        >
        <div v-for="post in allPosts" :key="post.id">
            <div class="post-wrapper">
                <div class="post-top-section">
                    <div class="shop-logo" v-bind:style="{ 'background-image': 'url(' + post.Logo + ')' }"></div>
                    <div v-if="post.Name" class="shop-name">{{post.Name}}</div>
                    <div v-else class="shop-name">-</div>
                    <div class="post-date">
                        <font-awesome-icon class="post-icon" :icon="['far', 'clock']" />
                        <span>{{ post.updated_at | moment("subtract", "3 hours", "MMMM Do") }}</span>
                    </div>
                    <div class="clearfix"></div>
                    <router-link :to="{ name: 'Shop', params: { id: post.ShopID }}">
                        <div v-if="post.Banner.split('/')[4] == 'images'" class="post-image">
                            <img v-bind:src="post.Preview"/>
                            <div class="post-view">
                                <font-awesome-icon class="view-icon" icon="eye" />
                            </div>
                        </div>
                        <div v-if="post.Banner.split('/')[4] == 'videos'" class="post-video">
                            <!-- <video oncontextmenu="return false;" style="width:100%;" controls class="view--video__video">
                                <source :src="post.Banner" type="video/mp4">
                            </video> -->
                            <VideoPlayer :videoURL='post.Banner'></VideoPlayer>
                        </div>
                        <div v-if="post.Banner.split('/')[4] == 'gifs'" class="post-video">
                            <video oncontextmenu="return false;" style="width:100%;" loop muted autoplay :src="post.Banner" class="view--video__video">
                                <source :src="post.Banner" type="video/mp4">
                            </video>
                            <!-- <GIFPlayer :videoURL='post.Banner'></GIFPlayer>-->
                        </div>
                    </router-link>
                </div>
                <div class="post-bottom-section">
                    <div class="post-sku">{{post.SKU}}</div>
                    <div class="post-title">{{post.Title}}</div>
                    <div class="post-desc">{{post.Description}}</div>
                    <router-link :to="{ name: 'Shop', params: { id: post.ShopID }}">
                        <div class="read-more">ชมร้านค้า</div>
                    </router-link>
                    <div class="post-footer">
                        <div v-on:click="showCategory(2)" v-if="post.CategoryID == 2" class="footer-item footer-category">แฟชั่นผู้ชาย</div>
                        <div v-on:click="showCategory(1)" v-if="post.CategoryID == 1" class="footer-item footer-category">แฟชั่นผู้หญิง</div>
                        <div v-on:click="showCategory(3)" v-if="post.CategoryID == 3" class="footer-item footer-category">เด็ก</div>
                        <div v-on:click="showCategory(4)" v-if="post.CategoryID == 4" class="footer-item footer-category">เครื่องประดับ</div>
                        <div v-on:click="showCategory(5)" v-if="post.CategoryID == 5" class="footer-item footer-category">อุปกรณ์ อิเล็กทรอนิกส์</div>
                        <div v-on:click="showCategory(6)" v-if="post.CategoryID == 6" class="footer-item footer-category">อุปกรณ์ ประดับรถยนต์</div>
                        <div v-on:click="showCategory(7)" v-if="post.CategoryID == 7" class="footer-item footer-category">สุขภาพและความงาม</div>
                        <div v-on:click="showCategory(8)" v-if="post.CategoryID == 8" class="footer-item footer-category">อาหาร</div>
                        <div v-on:click="showCategory(9)" v-if="post.CategoryID == 9" class="footer-item footer-category">ขนมหวาน</div>
                        <div v-on:click="showCategory(10)" v-if="post.CategoryID == 10" class="footer-item footer-category">เครื่องดื่ม</div>
                        <div v-on:click="showCategory(11)" v-if="post.CategoryID == 11" class="footer-item footer-category">สัตว์เลี้ยง</div>
                        <div v-on:click="showCategory(11)" v-if="post.CategoryID == 12" class="footer-item footer-category">อื่น ๆ</div>
                        <div class="footer-item footer-date">
                            <font-awesome-icon class="post-icon" :icon="['far', 'clock']" />
                            <span>{{ post.updated_at | moment("MMMM DD") }}</span>
                        </div>
                        <div v-if="Number(post.PriceTill).toLocaleString() !== '0'" style="float:right;margin-top:1px;" class="footer-item footer-date">
                            <svg style="margin-right:10px;margin-top:-3px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ec7465" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            <span>{{Number(post.PriceFrom).toLocaleString()}} - {{Number(post.PriceTill).toLocaleString()}} บาท</span>
                        </div>
                        <div v-else style="float:right;margin-top:1px;" class="footer-item footer-date">
                            <svg style="margin-right:10px;margin-top:-3px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ec7465" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            <span>{{Number(post.PriceFrom).toLocaleString()}} บาท</span>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <infinite-loading :style="loaderStyle" ref="infiniteloader" @infinite="addMoreSearch"></infinite-loading>
    </masonry>
    <div v-else class="no-results">
        <div class="no-results-body">
            <h1 class="results-title">No results found</h1>
            <div class="result-msg">เราไม่พบผลลัพธ์ใด ๆ สำหรับ {{this.$route.params.keyword}} โปรดลองคำค้นหาอื่นเพื่อดูผลลัพธ์เพิ่มเติม</div>
            <div class="form-group has-search-result">
                <b-form-input  v-on:keyup.enter="searchPost()" class="searchbox" type="text" v-model="search" placeholder="Search"></b-form-input>
                <font-awesome-icon class="search-icon" icon="search" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import VideoPlayer from '@/components/VideoPlayer'
// import GIFPlayer from '@/components/GifPlayer'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: "Market",
    components:{
        InfiniteLoading,
        VideoPlayer
        // GIFPlayer
    },
    methods:{
        showCategory: function(catid){
            this.setPostFilter(catid)
            this.$router.push({ path: `/category/${catid}` })
        },
        searchPost: function(){
            this.searchPostAllShop(this.search)
            this.$router.push({ path: `/search/${this.search}` })
        },
        ...mapActions(["fetchShops","getAllPosts","setShopMenuClass","addMoreSearch","setPostFilter","searchPostAllShop"])
    },
    watch: {
        allPosts() {
            var remainder = this.allPosts.length % 30;
            if (remainder != 0){
                this.$refs.infiniteloader.stateChanger.complete();
                this.loaderStyle = "display:none"
            } else {
                this.$refs.infiniteloader.stateChanger.loaded();
                this.loaderStyle = "display:block"
            }
        },
    },
    computed: {
        selected: {
            // getter
            get: function () {
                return this.postCategory
            },
            // setter
            set: function (newValue) { 
                this.selectedCategory = newValue;
            }
        },
        ...mapGetters(["allShops","allPosts","allMorePosts","shopMenuState","postCategory","allMenus"]),
    },
    created() {
        /*this.fetchShops();
        this.getAllPosts();*/
        this.setShopMenuClass("hide-menu");
        //this.setPostFilter(this.$route.params.id)
        this.searchPostAllShop(this.$route.params.keyword)
    },
    data() {
      return {
        selectedCategory: 0,
        loaderStyle: '',
        search: '',
        options: [
          { value: '0', text: 'ทั้งหมด (All Categories)' },
          { value: '2', text: 'แฟชั่นผู้ชาย (Men)'},
          { value: '1', text: 'แฟชั่นผู้หญิง (Women)' },
          { value: '3', text: 'เด็ก (Kids)'},
          { value: '4', text: 'เครื่องประดับ (Accessories)'},
          { value: '5', text: 'อุปกรณ์ อิเล็กทรอนิกส์ (Electronics)'},
          { value: '6', text: 'อุปกรณ์ ตกแต่งรถยนต์ (Auto Accessories)'},
          { value: '7', text: 'สุขภาพและความงาม (Beauty & Health)'},
          { value: '8', text: 'อาหาร (Food)'},
          { value: '9', text: 'ขนมหวาน (Dessert)'},
          { value: '10', text: 'เครื่องดื่ม (Beverage)'},
          { value: '11', text: 'สัตว์เลี้ยง (Pets)'},
        ]
      }
    }
}
</script>

<style scoped>
    .category-page{
        padding-left:20px;
    }
    .filter-item{
        display: inline-block;
        color:#111;
        margin-right: 10px;
    }
    .filter-button,.category-select{
        /*border: 1px solid transparent;
        background-color: #fff;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(5,50,87,0.62);
        -moz-box-shadow: 0px 3px 5px 0px rgba(5,50,87,0.62);
        box-shadow: 0px 3px 5px 0px rgba(5,50,87,0.62);
        transition: all 0.3s ease;
        border-radius:6px;
        padding: 4px 10px;
        color:black;
        font-weight: 500 !important;
        font-size:15px !important;
        font-family: 'Kanit';*/
    }
    .filter-button:hover{
        background-color: rgba(243,21,91,1);
        color:#fff;
        cursor:pointer;
    }
    .category-select{
        padding-top:3px !important;
        padding-bottom: 3px !important;
        height:33px !important;
    }
    .market-post-filter .filter-group{
        display: inline-block;
        padding: 15px 20px 15px 20px;
        padding-top:0px;
        vertical-align: middle;
        white-space: nowrap;
    }
    .filter-label{
        font-size:13px;
        font-weight: 500;
    }
    .post-wrapper{
        border: 1px solid #d1d7dc;
        -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
        box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
        background-color: #fff;
        border-radius: 6px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        font-family: "Kanit";
        font-family: 'Kanit';
    }
    .post-wrapper .post-image:hover img{
        transform: scale(1.1);
    }
    .post-title{
        font-weight: 500;
        color: #2c2d2f;
        font-size: 20px;
        line-height: 32px;
        border-bottom: 1px solid transparent;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        margin-bottom: 10px;
    }
    .post-desc{
        font-size: 14px;
        color: #5c5c5c;
        font-weight: 400;
        line-height: 1.428571429;
    }
    .post-view{
         position:absolute;
         width:100%;
         height:100%;
         background-image: linear-gradient(transparent,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.7));
         top:0px;
         left:0px;
         opacity:0;
         transition: all 0.3s ease;
    }
    .post-view svg{
        left:44%;
        top:45%;
        position: relative;
        width:45px;
        height: 45px;
        font-size:30px;
        color:white;
        transition: all 0.3s ease;
    }
    .post-wrapper .post-image:hover .post-view{
        opacity:1;
    }
    .post-wrapper .post-image:hover svg{
        transform: translateY(-20px);
    }
    /*.post-container.read-more{
        margin-top:15px;
        font-weight: 700;
        display: inline-block;
        color: #111;
        background-color: #fff;
        border-bottom: 2px solid #111;
        padding: 4px 0px;
        font-size: 12px;
        line-height: 14px;
    }*/
    /*.post-footer{
        margin-top:30px;
    }*/
    .footer-item{
        float:left;
    }
    .footer-category{
        display: block;
        padding: 2px 5px;
        background-color: #d4edfc;
        font-size: 11px;
        line-height: 14px;
        color: #3893c1;
        border: 1px solid #75b9dd;
        border-radius: 4px;
    }
    .footer-category:hover{
        cursor: pointer;
        background-color: white;
        border: 1px solid #5987f0;
    }
    .post-icon{
        margin-right: 2px;
    }
    .heart-icon{
        margin-right:2px;
        color:#ec7465;
    }
    .search-header{
        font-family: 'Kanit';
        color: white;
        font-size:28px;
        border-bottom: 1px solid #d7dbe3;
        padding: 40px;
        padding-left: 0px;
        width:40%;
        margin-bottom: 30px;
    }
    .no-results{
        text-align: left;
        border: #fff;
        -webkit-box-shadow: 0px 9px 24px 0px rgba(151,160,204,0.12);
        box-shadow: 0px 9px 24px 0px rgba(151,160,204,0.12);
        background-color: #fff;
        border-radius: 8px;
        max-width: 900px;
        margin: 20px auto 20px auto;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    .no-results-body{
        padding: 50px;
        position: relative;
    }
    .no-results-body h1{
        padding-bottom: 20px;
        margin-bottom: 30px;
        position:relative
    }
    .no-results-body h1:after {
        content: "";
        display: block;
        height: 4px;
        width: 80px;
        background-color: #f3155b;
        position: absolute;
        bottom: 0px;
    }
    .result-msg{
        font-family: 'Kanit';
    }
    .has-search-result{
        margin-bottom: 0px;
        margin-top:30px;
    }
    .has-search-result .searchbox {
        padding-right: 2.375rem;
    }
    .has-search-result .search-icon {
        position: absolute;
        z-index: 2;
        display: block;
        width: 1.075rem;
        height: 1.075rem;
        line-height: 1.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
        margin-top:-28px;
        right:65px;
    }
    .post-sku{
        font-size:12px;
        color:#f3155b;
        margin-bottom: 5px;
    }
    @media only screen and (max-width: 1100px) {
        .post-container {
            width:100%;
            padding-top:0px;
        }
        .search-header{
            font-family: 'Kanit';
            color: white;
            font-size:24px;
            border-bottom: 1px solid #d7dbe3;
            padding: 15px;
            padding-left: 0px;
            width:100%;
            text-align: center;
            margin-top:60px;
        }
        /*.market-post-filter{
            display:none;
        }*/
        .market-post-header{
            margin-bottom: 20px;
        }
        .no-results{
            margin-top:90px;
        }
        .no-results{
            max-width: auto;
            width:95%;
        }
    }
</style>