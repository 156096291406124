<template>
  <div class="orders-page">
    <div class="row">
      <div class="col-xl-12 col-md-12 col-sm-12">
        <div class="page-header">
          <div class="page-header-left">My Orders</div>
          <div class="page-header-right">การสั่งซื้อ</div>
        </div>
        <div class="clearfix"></div>
        <div class="order-header-wrapper">
          <div class="order-header">
            <div class="header-products">สินค้าทั้งหมด</div>
            <div class="header-total">ยอดคำสั่งซื้อทั้งหมด</div>
            <div class="header-delivery">ที่อยู่การจัดส่ง</div>
            <div class="header-status">สถานะ</div>
          </div>
        </div>
        <div class="order-table" v-if="!noResult">
          <div v-for="orderitem in orderList" v-bind:key="orderitem.invono" class="cart-wrapper cart-row">
            <div class="cart-header">
              <div class="cart-header-left">
                <a :href="'/shop/'+orderitem.shopid">
                  <div class="cart-icon shop">
                    <!--<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>-->
                    <div v-bind:style="{ backgroundImage: 'url(' + orderitem.shopLogo + ')' }" class="shop-logo"></div>
                  </div>
                  <span style="float:left;margin-top:9px;color:#333;">{{orderitem.shopName}}</span>
                </a>
              </div>
              <div class="invoice-no">หมายเลขคำสั่งซื้อ <span style="color:#3893c1">{{orderitem.invono}}</span></div>
              <!--<div class="cart-header-right"><a @click="$router.go(-1)"><div class="backbtt">ย้อนกลับ</div></a></div>-->
              <div class="clearfix"></div>
            </div>
            <div class="cart-detail">
              <div class="cart-item-wrapper">
                <div class="order-items">
                  <div v-for="item in orderitem.products" v-bind:key="item.pid" class="cart-item">
                    <div v-on:click="showShop(item.shopid)" class="item-image" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                    <div class="item-detail">
                      <div class="item-title">{{item.title}}</div>
                      <div class="item-desc">{{item.descripton}}</div>
                      <div class="item-category">{{item.category}}</div>
                      <div class="clearfix"></div>
                      <div class="item-location">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                        กรุงเทพฯ-สายใต้เซ็นเตอร์
                      </div>
                    </div>
                    <div class="item-amount">
                      x{{item.amount}}
                    </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                  <div class="item-total">
                    ฿{{orderitem.total}}
                  </div>
                  <div class="item-delivery">
                    {{orderitem.delivery.name}}<br>
                    {{orderitem.delivery.address}}<br>
                    {{orderitem.delivery.phone}}
                  </div>
                  <div class="item-status">
                    <div v-if="orderitem.status == 'Pending'" class="pending"><a class="pending" :href="'/payment/'+orderitem.invono"><img style="margin-right:5px;" src="../assets/payment.png" />รอการชำระเงิน</a></div>
                    <div style="margin-bottom:10px;" v-if="orderitem.status == 'Awaiting Approval'" class="awaiting"><img style="margin-right:5px;" src="../assets/approving.png" />รอการยืนยัน</div>
                    <a style="margin-top:20px;color:#3893c1;text-decoration:underline" :href="orderitem.slip" target="_blank" v-if="orderitem.status == 'Awaiting Approval'" class="awaiting">ดูสลิปการชำระเงิน</a>
                    <div v-if="orderitem.status == 'Rejected'" class="rejected"><img style="margin-right:5px;" src="../assets/rejected.png" />คำสั่งซื้อถูกยกเลิก</div>
                    <div v-if="orderitem.status == 'Rejected'" class="rejected-reason">เหตุผล: {{orderitem.reason}}</div>
                    <div v-if="orderitem.status == 'Approved'" class="approved"><img style="margin-right:5px;" src="../assets/approved.png" />ชำระเงินสำเร็จ</div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="order-no-result">
            <img src="../assets/noorder.png" />
            <div class="no-result-txt">ไม่พบคำสั่งซื้อ</div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import { backendURL } from "../config";

export default {
  components: {},
  data() {
    return {
      chatname: "",
      noResult: false,
      orderList: []
    };
  },
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (!user) {
        self.$router.replace("/");
      }else{
        self.getUserOrders(user.uid);
      }
    });
  },
  methods: {
    ...mapActions([]),
    async getUserOrders(uid){
      var self = this;
      firebase.firestore().collection("checkout").where('uid', '==', uid).orderBy('created', 'desc').get()
      .then((result) => {
          for (var i = 0; i< result.docs.length; i++){
            self.getShopInfo(result.docs[i].data());
            //var order = result.docs[i].data();
            //order.push()
            //self.orderList.push(order);
          }
          if (result.docs.length < 1) {
            self.noResult = true;
          }
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('orders', error)
          self.noResult = true;
        });
    },
    async getShopInfo(order){
      const response = await axios.get(`${backendURL}/getshop/${order.shopid}`);
      //eslint-disable-next-line
      console.log('shop', response)
      order.shopName = response.data.Name;
      order.shopLogo = response.data.Logo;
      this.orderList.push(order)
    }
  },
  computed: mapGetters(["userInfo"]),
};
</script>

<style scoped>
  .orders-page{
    padding: 15px 30px;
    font-family: 'Kanit';
  }
  .invoice-no{
    float:right;
    margin-top: 9px;
  }
  .order-header-wrapper{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top:0px;
  }
  .order-header{
    width: 100%;
    background-color: #d2ecf6;
    color: #3893c1;
    padding:20px 15px;
    margin-top:25px;
    border-radius: 5px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
  }
  .header-products{
    width:45%;
    display: inline-block;
  }
  .header-total{
    width:10%;
    display: inline-block;
    text-align:right;
  }
  .item-total{
    width:10%;
    float:left;
    text-align: right;
    padding-top:10px;
  }
  .header-delivery{
    width:25%;
    display: inline-block;
    text-align: right;
  }
  .item-delivery{
    float:left;
    width: 25%;
    text-align: right;
    padding-top: 10px;
  }
  .header-status{
    width:20%;
    display: inline-block;
    text-align: right;
  }
  .item-amount{
    float: right;
  }
  .orders-page .order-items{
    float: left;
    width:45%;
    padding-right: 8%;
    border-bottom: 0px;
  }
  .orders-page .cart-item{
    width:100%;
  }
  .orders-page .item-status{
    float:right;
    padding-top: 10px;
    width:20%;
    text-align: right;
  }
  .item-status .pending{
    color:#f29339;
  }
  .item-status .awaiting{
    color:#00b0ff;
  }
  .cart-icon.shop{
    padding-top: 0px;
    margin-top: 2px;
    float: left;
  }
  .cart-row{
    border-top:1px solid #3893c1;
  }
  .order-no-result{
    text-align: center;
    margin-top: 40px;
  }
  .no-result-txt{
    margin-top: 15px;
    font-size: 32px;
  }
  .rejected{
  color:#f81b1b;
}
.rejected-reason{
  color:#f81b1b;
  text-decoration: underline;
}
.approved{
  color:#45c9a5;
}
</style>
