<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">PRICING</div>
        <div class="page-subtitle">Become a Member</div>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div class="pricing-wrapper">
        <div class="pricing-header">
          <h1>สมัครสมาชิก</h1>
          <div class="header-subtitle">สมัครสมาชิกวันนี้และเริ่มขายให้กับลูกค้าในพื้นที่</div>
        </div>
        <div v-if="readytoPay == false" class="account-dashboard pricing-item">
          <div v-if="membership == ''">
            <div class="pricing-banner">
              <img src="../assets/shopbg.png" />
            </div>
            <h1 class="package-name">1 เดือน</h1>
            <div class="package-detail">สมัครสมาชิกและเริ่มโพสต์สินค้าของคุณบนเว็บไซต์ของเรา</div>
            <div class="package-price">฿ 300</div>
            <div v-if="membership == ''" class="pay-now" @submit.prevent>
              <!--<div id="checkout-button" @click="omiseHandler">ซื้อเลย</div>-->
              <div @click="selectPaymentSource()">ชำระค่าสมาชิก</div>
            </div>
          </div>
          <div v-if="membership == 'valid'">
            <div class="w4rAnimated_checkmark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>
            </div>
            <div class="verify-title">การเป็นสมาชิกของคุณมีอายุอีก {{daysLeft}} วัน</div>
          </div>
        </div>
        <div v-if="readytoPay == true" class="account-dashboard payment-options">
          <div v-if="verifyPayment == false">
            <div class="option-label">ชำระค่าสมาชิกโดยใช้</div>
            <div v-for="method in paymentMethods" :key="method.id" :class="method.selection" class="pay-option-item" v-on:click="selectSource(method)">
              <img :src="method.payment_icon"/>
              {{method.title}}
            </div>
            <div class="clearfix"></div>
            <div class="pay-now" @submit.prevent>
              <div class="cancel-checkout-button" @click="cancelCheckout()">ยกเลิก</div>
              <div class="checkout-button" id="checkout-button" @click="omiseHandler">ตกลง</div>
            </div>
          </div>
          <div class="verifying" v-if="verifyPayment == true && paymentComplete == ''">
            <img class="spinner" src='../assets/loadspin.svg'/>
            <div class="verify-title">กำลังตรวจสอบการชำระค่าสมาชิก กรุณารอสักครู่</div>
          </div>
          <div class="verifyComplete" v-if="verifyPayment == true && paymentComplete == 'success'">
            <div class="w4rAnimated_checkmark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>
            </div>
            <div class="verify-title">การยืนยันการเป็นสมาชิกเสร็จสมบูรณ์</div>
            <div style="margin-top:0px" class="pay-now" @submit.prevent>
              <div class="checkout-button-full" @click="refreshPage">ยืนยัน</div>
            </div>
          </div>
          <div class="verifyComplete" v-if="verifyPayment == true && paymentComplete == 'failed'">
            <div class="w4rAnimated_checkmark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
              </svg>
            </div>
            <div class="verify-title">การจ่ายเงินไม่สำเร็จ</div>
            <div style="margin-top:0px" class="pay-now" @submit.prevent>
              <div class="checkout-button-full" @click="refreshPage">ตกลง</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import axios from "axios";
import { backendURL } from "../config";
export default {
  name: "VendorPricing",
  components: {
    VendorMenu,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    omiseHandler(e){
      e.preventDefault();
      if (this.paymentSource == 'credit_card'){
        this.omisePaymentCreditHandler();
      } else {
        this.omisePaymentInternetBankingHandler();
      }
    },
    selectPaymentSource(){
      this.readytoPay = true;
      var self = this;
      setTimeout(function(){ self.paymentConfigure(); }, 1000);
      //setTimeout(function(){ self.paymentComplete = true;}, 1000);
    },
    cancelCheckout() {
      this.readytoPay = false;
    },
    selectSource(method) {
      for (var i = 0; i < this.paymentMethods.length; i++) {
        this.paymentMethods[i].selection = '';
      }
      method.selection = 'active';
      this.paymentSource = method.type;
      this.paymentConfigure();
    },
    paymentConfigure(){
      // eslint-disable-next-line
      OmiseCard.configure({
        publicKey: 'pkey_test_5o4pr90kq25hukm0b55',
        amount: this.paymentAmount,
        defaultPaymentMethod: this.paymentSource,
        //otherPaymentMethods: 'credit_card,truemoney,rabbit_linepay,alipay',
        frameLabel: 'Live Tycoon',
        locale:'th',
        submitLabel: 'Pay',
      });
      // eslint-disable-next-line
      OmiseCard.configureButton('#checkout-button');
      // eslint-disable-next-line
      OmiseCard.attach();
    },
    omisePaymentCreditHandler(){
      var self = this;
      // eslint-disable-next-line
      OmiseCard.open({
        amount: self.paymentAmount,
        onCreateTokenSuccess: (token) => {
          // eslint-disable-next-line
          console.log(token)
          /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
          self.createCreditCardCharge(self.customeremail, self.customername, self.paymentAmount, token)
        },
        onFormClosed: () => {
          /* Handler on form closure. */
        },
      })
    },
    omisePaymentInternetBankingHandler(){
      var self = this;
      //eslint-disable-next-line
      OmiseCard.open({
        amount: this.paymentAmount,
        onCreateTokenSuccess: (token) => {
          // eslint-disable-next-line
          console.log(token)
          /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
          self.createInternetBankingCharge(self.customeremail, self.customername, self.paymentAmount, token)
        },
        onFormClosed: () => {
          /* Handler on form closure. */
        },
      })
    },
    async createCreditCardCharge(email, name, amount, token) {
      this.verifyPayment = true;
      const response = await axios({
        method: 'post',
        url: `${backendURL}/checkout-credit-card`,
        //url: 'http://localhost:3000/checkout-credit-card',
        data: {
          email,
          name,
          amount,
          token,
          uid: this.customerid
        }
      })
      if (response.data == 'successful') {
        this.paymentComplete = 'success';
      } else {
        //failed
        //eslint-disable-next-line
        console.log(response.data)
        this.paymentComplete = 'failed';
      }
    },
    async createInternetBankingCharge(email, name, amount, token) {
      this.verifyPayment = true;
      const response = await axios({
        method: 'post',
        url: `${backendURL}/checkout-internet-banking`,
        //url: 'http://localhost:3000/checkout-internet-banking',
        data: {
          email,
          name,
          amount,
          token,
          uid: this.customerid
        }
      })
      if (response.data) {
        window.location.href = response.data;
      } else {
        //failed
        //eslint-disable-next-line
        console.log(response.data)
      }
    },
    saveUserProfile: function (data) {
      this.customername = data.name;
      this.customeremail = data.email;
    },
    checkMembershipStatus: function () {
      var self = this;
      firebase.firestore().collection("transactions").where('customer_id', '==', this.customerid).orderBy('updated', 'desc').limit(1).get()
      .then((result) => {
          if (result.exists == false){
            // self.newuser = true;
            // self.contact = '0' + user.phoneNumber.slice(3);
            // self.phone = '0' + user.phoneNumber.slice(3);
            self.membership = '';
          } else {
            //eslint-disable-next-line
            //console.log('mstatus',result.docs[0].data())
            let membership = result.docs[0].data();
            if(membership.status == 'successful') {
              //self.membership = 'valid'
              self.membership = ''
              const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
              const membershipDate = membership.updated.toDate();
              membershipDate.setDate(membershipDate.getDate()+30);
              const todayDate = new Date();
              const diffDays = Math.round(Math.abs((membershipDate - todayDate) / oneDay));
              self.daysLeft = diffDays;
            } else {
              self.membership = '';
            }
          }
      })
      .catch(function (e) {
        //eslint-disable-next-line
        console.log('Membership status error',e)
        self.membership = ''
      });
    },
    refreshPage: function () {
      location.reload();
    }
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      self.customerid = user.uid;
      self.checkMembershipStatus();
      firebase.firestore().collection("users").doc(user.uid).get()
      .then((result) => {
          if (result.exists == false){
            // self.newuser = true;
            // self.contact = '0' + user.phoneNumber.slice(3);
            // self.phone = '0' + user.phoneNumber.slice(3);
          } else {
            // self.newuser = false;
            self.saveUserProfile(result.data())
          }
      })
      .catch(function () {
      });
    });
  },
  data() {
    return {
      shopInfo: '',
      shopLocation: '',
      membership: '-',
      daysLeft: '',
      readytoPay: false,
      paymentComplete: '',
      verifyPayment: false,
      paymentSource: 'credit_card',
      paymentAmount: 30000,
      customerid: '',
      customeremail: '',
      customername: '',
      annoucements:[
        {id:0,title: 'ลิมูซีนซังเต ดอกเตอร์ซูฮกวินรีดไถล้มเหลว สปาย ช็อปแฟล็ตเอ็นเตอร์เทนก๊วนจูเนียร์', post_date: '21/06/2021'},
        {id:1,title: ' แผดเผา เห็นด้วยแกรนด์แฟรนไชส์ชาร์จตัวตน ซื่อบื้อศิลปวัฒนธรรม เป่ายิงฉุบ', post_date: '21/06/2021'},
      ],
      paymentMethods: [
        {id:0,title: 'บัตรเครดิต / บัตรเดบิต', type:'credit_card', payment_icon: '/img/credit.png', selection: 'active'},
        {id:1,title: 'Internet Banking', type:'internet_banking', payment_icon: '/img/bank.png', selection: ''},
      ]
    };
  },
};
</script>

<style scoped>
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.pricing-wrapper{
  text-align: center;
  align-items: center;
}
.pricing-header{
  margin-bottom: 30px;
  margin-top:30px;
}
.header-subtitle{
  color:#a6b0cf;
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.pricing-item {
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.account-dashboard {
  width: 25%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
}
.full-dashboard{
  width: 98.2%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
  height:auto;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #f1f6f8;
  border-radius: 50%;
  padding: 12px;
  width:60px;
  height: 60px;
  color: #196a9f;
}
.upper-icon img{
  width:100%;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.annoucements{
  font-weight: normal;
  margin-top: 10px;
}
.annoucements ul{
  padding-left: 20px;
}
.annoucements li{
  padding-top:15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.annoucements .post-date{
  float:right;
  color: #196a9f;
}
.dashboardbtt{
  padding: 5px 5px;
  background-color: #d4edfc;
  font-size: 14px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-top:5px;
  text-align: center;
  cursor: pointer;
}
.dashboardbtt:hover{
  background-color:white;
}
.pricing-banner{
  width:55%;
  margin-top:20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
.pricing-banner img{
  width:100%;
}
.package-name{
  margin-top:10px;
  margin-bottom: 10px;
}
.package-detail{
  font-weight: normal;
  width:70%;
  margin-right: auto;
  margin-left: auto;
}
.package-price{
  margin-top: 5px;
  font-size: 55px;
  color: #0e5668;
}
.pay-now{
  width:90%;
  margin-left: auto;
  margin-right: auto;
  background-color:#196a9f;
  border-radius: 50px;
  padding:10px 0px;
  color:white;
  font-size: 22px;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 30px;
  cursor: pointer;
}
.pay-now:hover{
  background-color: #75b9dd;
}
.payment-options{
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  width:400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  align-items:flex-start;
  margin-left: auto;
  margin-right: auto;
  padding:1% 1%;
  text-align: left;
  cursor: pointer;
}
.pay-option-item.active{
  border: 1px solid #f3155b;
}
.payment-options .pay-now{
  text-align: center;
  width: 100%;
  margin-top:30px;
  background-color: transparent;
}
.payment-options .pay-now .cancel-checkout-button{
  width:48%;
  border:1px solid #196a9f;
  color:#196a9f;
  float:left;
  border-radius: 6px;
  font-size: 22px;
  padding: 10px 0px;
}
.payment-options .pay-now .checkout-button{
  width:48%;
  margin-left: 4%;
  background-color:#196a9f;
  float:left;
  border-radius: 6px;
  font-size: 22px;
  padding: 10px 0px;
}
.payment-options .pay-now .checkout-button-full{
  width:100%;
  margin-top:0px;
  background-color:#196a9f;
  border-radius: 6px;
  font-size: 22px;
  padding: 10px 0px;
}
.pay-option-item{
  width:48%;
  border: 1px solid #d1d7dc;
  float: left;
  font-weight: normal;
  text-align: center;
  border-radius: 6px;
  margin-top:25px;
  padding:10px;
}
.pay-option-item:nth-child(odd){
  margin-left: 4%;
}
.pay-option-item img{
  width:80%;
  margin-top: 10px;
}
.verifying{
  text-align: center;
}
.verify-title{
  color:#333;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.spinner{
  width:140px;
  padding-bottom:10px;
}
.w4rAnimated_checkmark svg {
  width: 150px;
  display: block;
  margin: 10px auto 0;
}
.w4rAnimated_checkmark .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.w4rAnimated_checkmark .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.w4rAnimated_checkmark .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.w4rAnimated_checkmark .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}


/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>