<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">POST NEW</div>
        <div class="page-subtitle">New Post for Shop</div>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div class="pricing-wrapper">
        <div v-if="postStatus == ''" class="post-item account-dashboard">
          <div v-on:click="triggerImageUpload()" v-if="photoSrc == ''" class="image-placehoder">
            <div class="placeholder-tip">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              คลิกที่นี่เพื่ออัพโหลดภาพ
            </div>
          </div>
          <div v-on:click="triggerImageUpload()" v-else class="image-product">
            <img :src="photoSrc"/>
          </div>
          <!-- banner for image-->
          <input v-on:change="handleFileUpload()" style="display:none" ref="imageUpload" type="file"/>
          <div class="item-form">
            <div class="item-label">ชื่อสินค้า</div>
            <div class="item-input"><input v-model="productName" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">หมวดหมู่สินค้า</div>
            <div class="item-input">
              <b-form-select
                class="category-select"
                v-model="productCategory"
                :options="options"
              ></b-form-select>
            </div>
          </div>
          <div class="item-form">
            <div class="item-label">SKU</div>
            <div class="item-input"><input v-model="SKU" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">ตั้งเป็นสินค้าหลัก</div>
            <div class="item-input">
             <b-form-select
                class="feature-select"
                v-model="productFeature"
                :options="optionsFeature"
              ></b-form-select>
            </div>
          </div>
          <div class="item-form">
            <div class="item-label">ช่วงราคา(เริ่มต้น)</div>
            <div class="item-input"><input v-model="productPrice" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">ช่วงราคา(สูงสุด)</div>
            <div class="item-input"><input v-model="productPrice2" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">คำอธิบาย</div>
            <div class="item-input"><textarea v-model="productDesc" type="text"></textarea></div>
          </div>
          <!-- featured toggle -->
          <div class="add-now">
            <div @click="savePost()">บันทึก</div>
          </div>
        </div>
        <div v-if="postStatus == 'complete'" class="post-item account-dashboard">
          <div class="w4rAnimated_checkmark">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
            </svg>
          </div>
          <div class="message-title">เพิ่มโพสต์ใหม่เรียบร้อยแล้ว</div>
          <div style="margin-top:15px;width:100%;" class="pay-now">
            <div class="checkout-button-full" @click="$router.go(-1)">ตกลง</div>
          </div>
        </div>
        <div v-if="postStatus == 'fail'" class="post-item account-dashboard">
          <div class="w4rAnimated_checkmark">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
              <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
            </svg>
          </div>
          <div class="message-title">ไม่สามารถเพิ่มโพสต์ได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง</div>
          <div style="margin-top:15px;width:100%;" class="pay-now">
            <div class="checkout-button-full" @click="$router.go(-1)">ตกลง</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import axios from "axios";
// import { backendURL } from "../config";
export default {
  name: "VendorPostNew",
  components: {
    VendorMenu,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    triggerImageUpload: function () {
      this.$refs.imageUpload.click();  
    },
    handleFileUpload() {
      this.productImage = this.$refs.imageUpload.files[0];
      let formData = new FormData();
      formData.append('photo', this.productImage);
      var headers = {
        'Content-Type': 'multipart/form-data'
      }
      var self = this
      axios({
        method: 'POST',
        url: 'https://api.livetycoon.com/upload',
        headers: headers,
        data: formData
      })
      .then(function (response) {
        self.photoSrc = 'https://api.livetycoon.com/static/images/' + response.data.filename
      })
      .catch(function (error) {
        // eslint-disable-next-line
        console.log(error)
        alert('ไม่สามารถอัปโหลดรูปภาพได้ โปรดลองอีกครั้งในภายหลัง')
      })
    },
    savePost(){
      var newpost ={
        FCusID:this.FCusID ?? '',
        ShopID:this.shopID ?? '',
        CategoryID:this.productCategory ?? '',
        Banner:this.photoSrc ?? '',
        Title:this.productName ?? '',
        SKU:this.SKU ?? '',
        Description:this.productDesc ?? '',
        Status:0,
        PriceFrom:this.productPrice ?? '',
        PriceTill:this.productPrice2 ?? '',
        Featured:this.productFeature ?? ''
      };
      // eslint-disable-next-line
      //console.log(newpost);
      var self = this
      axios
        .post('https://api.livetycoon.com/shoppost', newpost)
        .then(response => {
          if (self.productFeature === '0') {
            self.updateShopFeatured(response.data.id)
          } else {
            self.postStatus = 'complete'
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error)
          self.postStatus = 'fail'
        })
    },
    updateShopFeatured (id) {
      var self = this;
      var setfpost = {}
      setfpost.id = id
      setfpost.FCusID = self.FCusID;
      axios
        .post('https://api.livetycoon.com/featureshoppost', setfpost)
        .then(response => {
          // eslint-disable-next-line
          console.log(response)
          self.postStatus = 'complete'
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error)
          self.postStatus = 'fail'
        })
    }
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      self.FCusID = user.uid;
      firebase.firestore().collection("users").doc(user.uid).get()
      .then((result) => {
         self.shopID = result.data().shopid;
      })
      .catch(function () {
      });
    });
  },
  data() {
    return {
      FCusID: '',
      shopID: '',
      productName: '',
      productImage: '',
      photoSrc: '',
      productCategory: '2',
      productFeature: '0',
      productPrice: '',
      productPrice2: '',
      productDesc: '',
      banner:'',
      SKU:'',
      status:'',
      featured: '',
      options: [
        { value: "2", text: "แฟชั่นผู้ชาย (Men)" },
        { value: "1", text: "แฟชั่นผู้หญิง (Women)" },
        { value: "3", text: "เด็ก (Kids)" },
        { value: "4", text: "เครื่องประดับ (Accessories)" },
        { value: "5", text: "อุปกรณ์ อิเล็กทรอนิกส์ (Electronics)" },
        { value: "6", text: "อุปกรณ์ ตกแต่งรถยนต์ (Auto Accessories)" },
        { value: "7", text: "สุขภาพและความงาม (Beauty & Health)" },
        { value: "8", text: "อาหาร (Food)" },
        { value: "9", text: "ขนมหวาน (Dessert)" },
        { value: "10", text: "เครื่องดื่ม (Beverage)" },
        { value: "11", text: "สัตว์เลี้ยง (Pets)" },
        { value: "12", text: "อื่น ๆ (Others)" },
      ],
      optionsFeature: [
        { value: "0", text: "ตกลง" },
        { value: "1", text: "ไม่" },
      ],
      postStatus: ''
    };
  },
};
</script>

<style scoped>
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.pricing-wrapper{
  text-align: center;
  align-items: center;
}
.pricing-header{
  margin-bottom: 30px;
  margin-top:30px;
}
.header-subtitle{
  color:#a6b0cf;
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.post-item {
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding:20px
}
.account-dashboard {
  width: 500px;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
}
.full-dashboard{
  width: 98.2%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
  height:auto;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #f1f6f8;
  border-radius: 50%;
  padding: 12px;
  width:60px;
  height: 60px;
  color: #196a9f;
}
.upper-icon img{
  width:100%;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.annoucements{
  font-weight: normal;
  margin-top: 10px;
}
.annoucements ul{
  padding-left: 20px;
}
.annoucements li{
  padding-top:15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.annoucements .post-date{
  float:right;
  color: #196a9f;
}
.image-placehoder{
  width:100%;
  height:300px;
  border:1px dashed #196A9F;
  text-align: center;
  margin-bottom: 10px;
}
.image-placehoder .placeholder-tip{
  margin-top:110px;
}
.image-placehoder .placeholder-tip svg{
  width: 100%;
  margin-bottom: 20px;
}
.image-product{
  margin-bottom: 15px;
}
.image-product img{
  width:100%;
  border-radius: 12px;
}
.item-form{
  text-align: left;
  width:100%;
  margin-top:5px;
}
.item-form input, .item-form textarea, .item-form select{
  margin-top: .5rem;
  width:100%;
  padding: .3rem .75rem;
  border-radius: .375rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
  background-color: #fff;
  border-color: #dbdbdb;
  -webkit-box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  border-style:solid;
}
.add-now{
  width:100%;
  margin-left: auto;
  margin-right: auto;
  background-color:#196a9f;
  border-radius: 50px;
  padding:10px 0px;
  color:white;
  font-size: 22px;
  font-weight: normal;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.add-now:hover{
  background-color: #0b425f;
}
.category-select{
  background-color: white !important;
}
.w4rAnimated_checkmark svg {
  width: 150px;
  display: block;
  margin: 10px auto 0;
}
.w4rAnimated_checkmark .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.w4rAnimated_checkmark .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.w4rAnimated_checkmark .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.w4rAnimated_checkmark .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.message-title{
  font-size: 18px;
  margin-top: 20px;
}
.checkout-button-full{
  width:100%;
  margin-top:0px;
  background-color:#196a9f;
  border-radius: 6px;
  font-size: 22px;
  padding: 10px 0px;
  color:white;
  cursor: pointer;
}
.checkout-button-full:hover{
  background-color: #0f4569;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>