<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">SHOP</div>
        <div class="page-subtitle">Your Shop</div>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div v-if="newuser == false && (membership == '-')" class="membership-notice">
        <svg style="float:left;" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
        <div style="margin-top:10px;float:left;" class="notice-msg">{{membershipmsg}}</div>
        <router-link to="/vendor/pricing">
          <button class="savebtt">{{membershipbttmsg}}</button>
        </router-link>
        <div class="clearfix"></div>
      </div>
      <div v-if="newuser == true" class="newuser-notice">
        <svg style="float:left;" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
        <div style="margin-top:10px;float:left;" class="notice-msg">เพื่อดำเนินการต่อ โปรดป้อนข้อมูลร้านค้าของคุณ คุณจะไม่สามารถโพสต์หรือต่ออายุสมาชิกได้จนกว่าคุณจะสร้างร้านค้าของคุณ</div>
        <div class="clearfix"></div>
      </div>
      <div class="dashboard-item full-dashboard">
        <div class="item-container report">
          <div class="profile-wrapper">
            <div v-if="profileimg == ''" class="profile-image">
              <svg data-v-1af38fae="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="rounded-full feather feather-user profile-default"><path data-v-1af38fae="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle data-v-1af38fae="" cx="12" cy="7" r="4"></circle></svg>
            </div>
            <div v-else class="profile-image">
              <img :src="profileimg"/>
            </div>
            <div class="profile-name-wrapper">
              <div class="profile-name">{{profilename}}</div>
              <div class="profile-role">ชื่อเจ้าของร้าน</div>
            </div>
            <!-- <div class="proflie-contact">
              <div class="profile-contact">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                {{contact}}
              </div>
              <div class="profile-contact">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                {{profilelocation}}
              </div>
            </div> -->
            <div class="profile-posts">
              <div class="post-count">{{postcount}}</div>
              จำนวนโพสต์
            </div>
            <div class="profile-membership">
              <router-link to="/vendor/pricing">
                <div class="post-count">{{daysLeft}}</div>
                เหลือวันเป็นสมาชิก
              </router-link>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="dashboard-item half-dashboard">
        <div class="item-container report">
          <div class="item-title">
            ข้อมูลของฉัน
            <button @click="validateProfile()" class="savebtt">ยืนยัน</button>
          </div>
          <div class="item-form">
            <div class="item-label">ชื่อเต็ม<span class="required"> *</span></div>
            <div class="item-input"><input v-bind:class="{ 'profile-required' : profilevalid === false}" v-model="profilename"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">อีเมล์<span class="required"> *</span></div>
            <div class="item-input"><input v-bind:class="{ 'profile-required' : profilevalid === false}" v-model="email"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">โทรศัพท์<span class="required"> *</span></div>
            <div class="item-input"><input v-bind:class="{ 'profile-required' : profilevalid === false}" v-model="phone" type="tel"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">LINE</div>
            <div class="item-input"><input v-model="line" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">Facebook</div>
            <div class="item-input"><input v-model="facebook" type="text"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">Instagram</div>
            <div class="item-input"><input v-model="instagram" type="text"/></div>
          </div>
        </div>
      </div>
      <div class="dashboard-item half-dashboard">
        <div class="item-container report">
          <div class="item-title">
            รายละเอียดร้าน
            <button @click="saveShopDetail()" class="savebtt">ยืนยัน</button>
          </div>
          <div class="item-form">
            <div class="item-label">ที่อยู่ร้าน</div>
            <div class="item-input"><input v-model="location"/></div>
          </div>
          <div class="item-form">
            <div class="item-label">จังหวัด</div>
            <div class="item-input">
              <select v-model="province">
                <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                <option value="กระบี่">กระบี่ </option>
                <option value="กาญจนบุรี">กาญจนบุรี </option>
                <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
                <option value="กำแพงเพชร">กำแพงเพชร </option>
                <option value="ขอนแก่น">ขอนแก่น</option>
                <option value="จันทบุรี">จันทบุรี</option>
                <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
                <option value="ชัยนาท">ชัยนาท </option>
                <option value="ชัยภูมิ">ชัยภูมิ </option>
                <option value="ชุมพร">ชุมพร </option>
                <option value="ชลบุรี">ชลบุรี </option>
                <option value="เชียงใหม่">เชียงใหม่ </option>
                <option value="เชียงราย">เชียงราย </option>
                <option value="ตรัง">ตรัง </option>
                <option value="ตราด">ตราด </option>
                <option value="ตาก">ตาก </option>
                <option value="นครนายก">นครนายก </option>
                <option value="นครปฐม">นครปฐม </option>
                <option value="นครพนม">นครพนม </option>
                <option value="นครราชสีมา">นครราชสีมา </option>
                <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
                <option value="นครสวรรค์">นครสวรรค์ </option>
                <option value="นราธิวาส">นราธิวาส </option>
                <option value="น่าน">น่าน </option>
                <option value="นนทบุรี">นนทบุรี </option>
                <option value="บึงกาฬ">บึงกาฬ</option>
                <option value="บุรีรัมย์">บุรีรัมย์</option>
                <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
                <option value="ปทุมธานี">ปทุมธานี </option>
                <option value="ปราจีนบุรี">ปราจีนบุรี </option>
                <option value="ปัตตานี">ปัตตานี </option>
                <option value="พะเยา">พะเยา </option>
                <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
                <option value="พังงา">พังงา </option>
                <option value="พิจิตร">พิจิตร </option>
                <option value="พิษณุโลก">พิษณุโลก </option>
                <option value="เพชรบุรี">เพชรบุรี </option>
                <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
                <option value="แพร่">แพร่ </option>
                <option value="พัทลุง">พัทลุง </option>
                <option value="ภูเก็ต">ภูเก็ต </option>
                <option value="มหาสารคาม">มหาสารคาม </option>
                <option value="มุกดาหาร">มุกดาหาร </option>
                <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
                <option value="ยโสธร">ยโสธร </option>
                <option value="ยะลา">ยะลา </option>
                <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
                <option value="ระนอง">ระนอง </option>
                <option value="ระยอง">ระยอง </option>
                <option value="ราชบุรี">ราชบุรี</option>
                <option value="ลพบุรี">ลพบุรี </option>
                <option value="ลำปาง">ลำปาง </option>
                <option value="ลำพูน">ลำพูน </option>
                <option value="เลย">เลย </option>
                <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                <option value="สกลนคร">สกลนคร</option>
                <option value="สงขลา">สงขลา </option>
                <option value="สมุทรสาคร">สมุทรสาคร </option>
                <option value="สมุทรปราการ">สมุทรปราการ </option>
                <option value="สมุทรสงคราม">สมุทรสงคราม </option>
                <option value="สระแก้ว">สระแก้ว </option>
                <option value="สระบุรี">สระบุรี </option>
                <option value="สิงห์บุรี">สิงห์บุรี </option>
                <option value="สุโขทัย">สุโขทัย </option>
                <option value="สุพรรณบุรี">สุพรรณบุรี </option>
                <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
                <option value="สุรินทร์">สุรินทร์ </option>
                <option value="สตูล">สตูล </option>
                <option value="หนองคาย">หนองคาย </option>
                <option value="หนองบัวลำภู">หนองบัวลำภู </option>
                <option value="อำนาจเจริญ">อำนาจเจริญ </option>
                <option value="อุดรธานี">อุดรธานี </option>
                <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
                <option value="อุทัยธานี">อุทัยธานี </option>
                <option value="อุบลราชธานี">อุบลราชธานี</option>
                <option value="อ่างทอง">อ่างทอง </option>
              </select>
            </div>
          </div>
          <div class="item-form">
            <div class="item-label">ใส่คำอธิบายสั้นๆ</div>
            <div class="item-input"><textarea v-model="shopdesc"/></div>
          </div>
        </div>
      </div>
      <div v-if="newuser == false" class="dashboard-item half-dashboard">
        <div class="item-container report">
          <div class="item-title">
            การตั้งค่า
          </div>
          <div style="margin-top:20px" class="item-form">
            <div class="form-left-border">
              <div class="label">
                <div class="font-medium">เปิดร้าน</div>
                <div class="text-gray">ลูกค้าจะเห็นร้านคุณ</div>
              </div>
              <label v-if="membership != '-' && membership != ''" class="switch right">
                <input type="checkbox" checked>
                <span class="slider round"></span>
              </label>
              <label v-else class="switch right">
                <input disabled type="checkbox">
                <span class="slider round"></span>
              </label>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import validator from 'validator';
//import axios from "axios";
//import { backendURL } from "../config";
export default {
  name: "VendorShop",
  components: {
    VendorMenu,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    validateProfile: function () {
      var phone = "+66" + this.phone.slice(1);
      var valid = false;
      if (validator.isEmpty(this.profilename) == true) {
        alert('ชื่อของคุณไม่สามารถเว้นว่างได้')
      } else if (validator.isMobilePhone(phone,'th-TH') == false){
        alert('โปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง')
      } else if (validator.isEmail(this.email) == false ){
        alert('กรุณาใส่อีเมล์ที่ถูกต้อง')
      } else{
        valid = true;
        this.saveShopProfile();
      }
      if (valid == false) {
        this.profilevalid = false;
      } else {
        this.profilevalid = true;
      }
    },
    displayProfile: function (data) {
      this.profilename = data.name;
      this.email = data.email;
      this.phone = data.phone;
      this.contact = data.phone;
      this.line = data.line;
      this.facebook = data.facebook;
      this.instagram = data.instagram;
      this.location = data.location;
      this.province = data.province;
      this.shopdesc = data.description;
      if(data.membership != '-') {
        this.membership = data.membership;
      } else{
        //this.membershipmsg = 'การเป็นสมาชิกของคุณหมดอายุแล้ว ลูกค้าจะไม่สามารถดูร้านค้าของคุณได้จนกว่าคุณจะต่ออายุการเป็นสมาชิก'
        this.membershipmsg = 'สมัครสมาชิกวันนี้เพื่อให้ลูกค้าเห็นร้านค้าของคุณและช่วยเพิ่มยอดขาย'
        this.membershipbttmsg = 'สมัครสมาชิก'
      }
    },
    async saveShopProfile() {
      var self = this;
      var data = {};
      data.name = this.profilename;
      data.email = this.email;
      data.phone = this.phone;
      data.line = this.line;
      data.facebook = this.facebook;
      data.instagram = this.instagram;
      if (self.newuser == true) {
        data.membership = '-';
      }
      firebase.auth().onAuthStateChanged(function(user) {
        firebase.firestore().collection('users').doc(user.uid).set(data,{ merge: true })
        .then(() => {
          self.contact = data.phone;
          self.newuser = false;
          self.membershipmsg = 'สมัครสมาชิกวันนี้เพื่อให้ลูกค้าเห็นร้านค้าของคุณและช่วยเพิ่มยอดขาย'
          self.membershipbttmsg = 'สมัครสมาชิก'
          alert('โปรไฟล์ของคุณได้รับการอัพเดทเรียบร้อยแล้ว');
        })
        .catch(function () {
        });
      })
    },
    saveShopDetail: function () {
      var data = {};
      data.location = this.location;
      data.province = this.province;
      data.description = this.shopdesc;
      firebase.auth().onAuthStateChanged(function(user) {
        firebase.firestore().collection('users').doc(user.uid).set(data,{ merge: true })
        .then(() => {
          alert('โปรไฟล์ของคุณได้รับการอัพเดทเรียบร้อยแล้ว');
        })
        .catch(function () {
        });
      })
    },
    checkMembershipStatus: function (uid) {
      var self = this;
      firebase.firestore().collection("transactions").where('customer_id', '==', uid).orderBy('updated', 'desc').limit(1).get()
      .then((result) => {
          if (result.exists == false){
            // self.newuser = true;
            // self.contact = '0' + user.phoneNumber.slice(3);
            // self.phone = '0' + user.phoneNumber.slice(3);
            self.membership = '-'
          } else {
            //eslint-disable-next-line
            //console.log('mstatus',result.docs[0].data())
            let membership = result.docs[0].data();
            if(membership.status == 'successful') {
              //self.membership = 'valid'
              const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
              const membershipDate = membership.updated.toDate();
              membershipDate.setDate(membershipDate.getDate()+30);
              const todayDate = new Date();

              const diffDays = Math.round(Math.abs((membershipDate - todayDate) / oneDay));
              if(diffDays > 0){
                self.membership = 'valid'
                self.daysLeft = diffDays;
              } else {
                self.membership = 'expired'
              }
            } else {
              self.membership = '-'
            }
          }
      })
      .catch(function (e) {
        //eslint-disable-next-line
        console.log('Membership status error',e)
        self.membership = '-';
        self.daysLeft = '0';
      });
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      firebase.firestore().collection("users").doc(user.uid).get()
      .then((result) => {
          if (result.exists == false){
            self.newuser = true;
            self.contact = '0' + user.phoneNumber.slice(3);
            self.phone = '0' + user.phoneNumber.slice(3);
            self.membership = '-';
            self.daysLeft = '0';
          } else {
            self.newuser = false;
            self.checkMembershipStatus(user.uid);
            self.displayProfile(result.data())
          }
      })
      .catch(function () {
      });
    });
  },
  data() {
    return {
      shopInfo: '',
      shopLocation: '',
      newuser: null,
      membership: '',
      daysLeft: '',
      membershipmsg: '',
      membershipbttmsg: '',
      profilename: '',
      profileimg: '',
      email:'',
      phone: '',
      line: '',
      facebook:'',
      instagram: '',
      profilelocation: '-',
      postcount: 0,
      contact: '',
      location: '',
      shopname: '-',
      shopdesc: '',
      province: 'กรุงเทพมหานคร',
      profilevalid: null,
    };
  },
};
</script>

<style scoped>
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
}
.full-dashboard,.half-dashboard{
  width: 98.2%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
  height:auto;
}
.half-dashboard{
  width:48.5%;
  height: auto;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #f1f6f8;
  border-radius: 50%;
  padding: 12px;
  width:60px;
  height: 60px;
  color: #196a9f;
}
.upper-icon img{
  width:100%;
}
.item-container.report {
  padding: 20px 25px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.annoucements{
  font-weight: normal;
  margin-top: 10px;
}
.annoucements ul{
  padding-left: 20px;
}
.annoucements li{
  padding-top:15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.annoucements .post-date{
  float:right;
  color: #196a9f;
}
.savebtt{
  padding: 5px 5px;
  background-color: #d4edfc;
  font-size: 14px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-top:5px;
  text-align: center;
  float: right;
  padding:10px;
  position: relative;
  top:-12px;
}
.savebtt:hover{
  background-color:white;
}
.profile-wrapper{
  font-weight: normal;
}
.profile-image{
  float:left;
  width:120px;
}
.profile-image svg{
  background-color: #a8a8a8;
  border-radius: 50%;
  width:100px;
  height:100px;
  padding: 10px;
  color: #fff;
}
.profile-name-wrapper{
  width:30%;
  float:left;
  padding-top:20px;
  padding-bottom: 20px;
  border-right: 1px solid #e2e8f0;
}
.proflie-contact{
  width:30%;
  float:left;
  padding-top:20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-right: 1px solid #e2e8f0;
}
.profile-name{
  font-size: 20px;
}
.profile-role{
  color: rgba(113,128,150);
}
.profile-contact{
  color: rgba(113,128,150);
}
.profile-contact:last-child{
  position: relative;
  top:10px;
}
.profile-posts{
  width:27%;
  float:left;
  text-align: center;
  border-right: 1px solid #e2e8f0;
}
.profile-membership{
  width:27%;
  float:left;
  text-align: center;
}
.profile-membership a{
  text-decoration: none;
  color: #333;
}
.post-count{
  color: #1c3faa;
  font-size: 1.5rem;
  padding-top:20px;
}
.item-form{
  margin-top:10px;
  font-weight: normal;
}
.item-form:last-child{
  margin-bottom: 20px;
}
.item-title{
  padding-top: .75rem;
  padding-bottom: 1.75rem;
  border-bottom: 1px solid rgba(237,242,247);
  width: 100%;
}
.item-form input, .item-form textarea, .item-form select{
  margin-top: .5rem;
  width:100%;
  padding: .3rem .75rem;
  border-radius: .375rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
  background-color: #fff;
  border-color: #dbdbdb;
  -webkit-box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  border-style:solid;
}
.profile-required{
  border-color: red !important;
}
.form-left-border{
  border-left: 2px solid rgba(28,63,170);
  padding-left: 1rem;
}
.form-left-border .label{
  float:left;
}
.font-medium{
  font-weight: 600;
}
.text-gray{
  color:rgba(113,128,150);
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch.right{
  float:right;
  margin-top: 6px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.membership-notice{
  margin-left: 0.7%;
  margin-right: 0.7%;
  padding: 20px 25px;
  background-color: #75b9dd;
  border-radius: 6px;
  color: white;
  margin-bottom: 30px;
}
.newuser-notice{
  margin-left: 0.7%;
  margin-right: 0.7%;
  padding: 20px 25px;
  background-color: #FEEFB3;
  border-radius: 6px;
  color: #9F6000;
  margin-bottom: 30px;
}
.membership-notice svg{
  color: #fff;
  margin-right: 10px;
}
.newuser-notice svg{
  color: #9F6000;
  margin-right: 10px;
}
.membership-notice .savebtt{
  width:10%;
  position: relative;
  top:-2px;
}
.required
{
  color: red;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>