<template>
  <div class="cart-page">
    <div class="row">
      <div class="col-xl-8 col-md-7 col-sm-12">
        <div class="page-header">
          <div class="page-header-left">My Cart ( {{cartamount}} )</div>
          <div class="page-header-right">สินค้าในตะกร้า</div>
        </div>
        <div class="clearfix"></div>
        <div class="cart-wrapper">
          <div class="cart-header">
            <div class="cart-header-left">
              <div class="cart-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
              </div>
              <b>{{cartamount}}</b> รายการในตะกร้า
            </div>
            <div class="cart-header-right"><a @click="$router.go(-1)"><div class="backbtt">ย้อนกลับ</div></a></div>
            <div class="clearfix"></div>
          </div>
          <div class="cart-detail">
            <div class="cart-item-wrapper">
              <div v-for="(item,index) in cartDetail" v-bind:key="item.pid" class="cart-item">
                <div v-on:click="showShop(item.shopid)" class="item-image" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                <div class="item-detail">
                  <div class="item-title">{{item.title}}</div>
                  <div class="item-desc">{{item.descripton}}</div>
                  <div class="item-category">{{item.category}}</div>
                  <div class="clearfix"></div>
                  <div class="item-location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    กรุงเทพฯ-สายใต้เซ็นเตอร์
                  </div>
                </div>
                <div class="item-action">
                  <div class="item-price">
                    <!--<div
                    v-if="Number(item.PriceTill).toLocaleString() !== '0' && item.PriceFrom != item.PriceTill"
                    style="float: right"
                    class="footer-item footer-date"
                  >
                    <svg
                      style="margin-right: 10px; margin-top: -3px"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ec7465"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path
                        d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                      ></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span
                      >{{ Number(item.PriceFrom).toLocaleString() }} -
                      {{ Number(item.PriceTill).toLocaleString() }} บาท</span
                    >
                  </div>
                  <div
                    v-else
                    style="float: right"
                    class="footer-item footer-date"
                  >
                    <div v-if="Number(item.PriceFrom) != 0">
                    <svg
                      style="margin-right: 10px; margin-top: -3px"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ec7465"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path
                        d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                      ></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span
                      >{{ Number(item.PriceFrom).toLocaleString() }} บาท</span
                    >
                    </div>
                  </div>-->
                  <div
                    class="footer-item footer-date cart-price"
                  >
                    <div v-if="Number(item.PriceTill) != 0">
                      <svg
                        style="margin-right: 10px; margin-top: -3px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ec7465"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-bag"
                      >
                        <path
                          d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                        ></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      <span
                        >{{ Number(item.PriceTill).toLocaleString() }} บาท</span
                      >
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="item-quantity-wrapper">
                    Qty:
                    <select  @change="quantityChange(item.pid,item.amount)" v-model="item.amount" class="item-quantity">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                    </select>
                  </div>
                  <div class="clearfix"></div>
                  <div v-on:click="removeItem(item.pid,index)" class="remove-item"><svg style="margin-top:-3px;margin-right:5px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>ลบออกจากตะกร้า</div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-5 col-sm-12">
        <div class="page-header">
          <div class="page-header-left"></div>
          <div class="page-header-right page-header-mobile-left confirm-header">ยืนยันการซื้อ</div>
        </div>
        <div class="clearfix"></div>
        <div class="cart-wrapper">
          <div class="cart-header">
            <div class="cart-header-left">
              <div class="cart-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
              </div>
              ที่อยู่สำหรับการจัดส่ง
            </div>
            <div class="cart-header-right"></div>
            <div class="clearfix"></div>
          </div>
          <div class="cart-detail">
            <div v-if="deliveries.length < 1" class="help">คลิกปุ่มเพิ่มด้านล่างเพื่อเพิ่มสถานที่จัดส่งใหม่</div>
            <div v-else class="delivery-options">
              <div v-for="(delivery,index) in currentDelivery" :key="index"  class="delivery-option row">
                <div class="col-2">
                  <img class="address-icon" src="../assets/address.png"/>
                </div>
                <div class="col-10">
                  <div class="delivery-name">{{delivery.name}} <div v-on:click="deleteAddress(delivery)" class="address-actions">Delete</div> <div style="margin-right:15px" v-on:click="showEdit(delivery)" class="address-actions">Edit</div></div>
                  <div class="delivery-address">{{delivery.address}}</div>
                  <div class="delivery-phone">{{delivery.phone}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="deliveries.length > 1" v-on:click="showOtherAddress()" class="more-delivery">{{deliveries.length-1}} ที่อยู่อื่นๆ</div>
        </div>
        <div v-on:click="showNewAddress()" class="new-address">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          เพิ่มที่อยู่ใหม่
        </div> 
        <div class="cart-wrapper">
          <div class="cart-header">
            <div class="cart-header-left">
              <div class="cart-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
              </div>
              รายละเอียดการชำระเงิน
            </div>
            <div class="cart-header-right"></div>
            <div class="clearfix"></div>
          </div>
          <div class="cart-detail">
            <div class="row cart-total">
              <div class="col-8">
                ยอดรวมรถเข็น
              </div>
              <div style="text-align:right;" class="col-4">
                {{cartTotal}} บาท
              </div>
            </div>
            <div class="row cart-total">
              <div class="col-8">
                ค่าจัดส่ง
              </div>
              <div style="text-align:right;" class="col-4">
                {{cartShipping}} บาท
              </div>
            </div>
          </div>
          <div class="cart-grand-total">
            <div class="row">
              <div class="col-8">
                รวมทั้งหมด
              </div>
              <div style="text-align:right;" class="col-4">
                {{cartGrandTotal}} บาท
              </div>
            </div>
          </div>
        </div>
        <div v-on:click="initPayment()" class="pay-btt">ชำระเงิน</div>
      </div>
    </div>
    <sweet-modal
      title="เพิ่มที่อยู่ใหม่"
      :blocking="false"
      ref="newAddressModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input">
        <div class="form-label">ชื่อผู้รับ</div>
        <input v-model="newShippingName" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">ที่อยู่จัดส่ง</div>
        <input v-model="newShippingAddress" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">เบอร์โทรติดต่อ</div>
        <input v-model="newShippingPhone" class="form-control" />
      </div>
      <sweet-button
        v-on:click="addNewAddress()"
        slot="button"
        ><div class="add-btt">ยืนยัน</div></sweet-button
      >
    </sweet-modal>
    <sweet-modal
      title="แก้ไขที่อยู่"
      :blocking="false"
      ref="editAddressModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input">
        <div class="form-label">ชื่อผู้รับ</div>
        <input v-model="editShippingName" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">ที่อยู่จัดส่ง</div>
        <input v-model="editShippingAddress" class="form-control" />
      </div>
      <div class="form-input">
        <div class="form-label">เบอร์โทรติดต่อ</div>
        <input v-model="editShippingPhone" class="form-control" />
      </div>
      <sweet-button
        v-on:click="editAddress()"
        slot="button"
        ><div class="add-btt">ยืนยัน</div></sweet-button
      >
    </sweet-modal>
    <sweet-modal
      title="ตัวเลือกที่อยู่อื่น"
      :blocking="false"
      ref="otherAddressModal"
      modal-theme="light"
      overlay-theme="dark"
    >
     <div class="delivery-options choice-option">
        <div v-for="(delivery,index) in otherdeliveries" :key="index" v-on:click="changeAddress(delivery)" class="delivery-option row">
          <div class="col-2">
            <img class="address-icon" src="../assets/address.png"/>
          </div>
          <div class="col-10">
            <div class="delivery-name">{{delivery.name}} <div class="address-actions">เลือก</div></div>
            <div class="delivery-address">{{delivery.address}}</div>
            <div class="delivery-phone">{{delivery.phone}}</div>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SweetModal } from "sweet-modal-vue";
import firebase from 'firebase';
import axios from "axios";
import { backendURL } from "../config";
import { nanoid } from 'nanoid'

export default {
  name: "Cart",
  components: {
    SweetModal
  },
  methods: {
    checkCartDetail: function () {
      for (let i=0; i<this.cart.length;i++){
        if (this.cart[i].shopid === this.$route.params.shop.toString()){
          for (let j=0; j < this.cart[i].product.length; j++) {
            this.getCartDetail(this.cart[i].product[j].pid,this.cart[i].shopid, this.cart[i].product[j].amount);
            this.cartamount = this.cartamount + this.cart[i].product[j].amount;
          }
        }
      }
    },
    updateCartAmount: function () {
      this.cartamount = 0;
      for (let i=0; i<this.cart.length;i++){
        if (this.cart[i].shopid === this.$route.params.shop.toString()){
          for (let j=0; j < this.cart[i].product.length; j++) {
            this.cartamount = this.cartamount + this.cart[i].product[j].amount;
          }
        }
      }
      this.updateCartTotal();
    },
    updateCartTotal: function () {
      this.cartTotal = 0;
      for (var i = 0; i < this.cartDetail.length; i++) {
        this.cartTotal = this.cartTotal + (parseInt(this.cartDetail[i].PriceTill) * this.cartDetail[i].amount);
      }
      this.cartGrandTotal = this.cartTotal + this.cartShipping;
    },
    showShop: function (shopid) {
      this.$router.push("/shop/"+shopid)
    },
    showNewAddress: function () {
      this.newShippingName = "";
      this.newShippingAddress = "";
      this.newShippingPhone = "";
      this.$refs.newAddressModal.open();
    },
    async getCartDetail(pid,shopid,amount) {
      const response = await axios.get(
          `${backendURL}/getshoppost/`+pid,
      );
      var category = this.getCategoryName(response.data.CategoryID)
      var item = {
        pid: response.data.id,
        shopid: shopid,
        image: response.data.Banner,
        title: response.data.Title,
        category: category,
        descripton: response.data.Description,
        PriceFrom: response.data.PriceFrom,
        PriceTill: response.data.PriceTill,
        amount: amount
      }
      this.cartDetail.push(item);
      this.cartTotal = this.cartTotal + (parseInt(response.data.PriceTill) * amount);
      this.cartGrandTotal = this.cartTotal + this.cartShipping;
    },
    quantityChange : function (id,amount) {
      var item = {
        shopid: this.$route.params.shop.toString(),
        pid: id,
        amount: amount
      }
      this.updateQuantity(item);
      this.updateCartAmount();
    },
    removeItem: function (pid,index) {
      var item = {
        shopid: this.$route.params.shop.toString(),
        pid: pid
      }
      this.removefromCart(item);
      this.cartDetail.splice(index,1);
      this.updateCartAmount();
    },
    getCategoryName(catid) {
      switch(catid) {
        case 1:
          return 'แฟชั่นผู้หญิง';
        case 2:
          return 'แฟชั่นผู้ชาย';
        case 3:
          return 'เด็ก';
        case 4:
          return 'เครื่องประดับ';
        case 5:
          return 'อุปกรณ์ อิเล็กทรอนิกส์';
        case 6:
          return 'อุปกรณ์ ประดับรถยนต์';
        case 7:
          return 'สุขภาพและความงาม';
        case 8:
          return 'อาหาร';
        case 9:
          return 'ขนมหวาน';
        case 10:
          return 'เครื่องดื่ม';
        case 11:
          return 'สัตว์เลี้ยง';
        case 12:
          return 'อื่น ๆ';
        default:
          return 'อื่น ๆ';
      }
    },
    addNewAddress: function () {
      var self = this;
      firebase.auth().onAuthStateChanged(function(user) {
        var data = {};
        data.uid = user.uid;
        data.name = self.newShippingName;
        data.address = self.newShippingAddress;
        data.phone = self.newShippingPhone;
        firebase.firestore().collection('delivery').add(data)
        .then((result) => {
          self.$refs.newAddressModal.close();
          self.defaultAddress = result.id;
          self.getDelivery();
          self.updateDefaults(result.id);
        })
        .catch(function (e) {
          //eslint-disable-next-line
          console.log(e)
        });
      })
    },
    showEdit: function(address) {
      this.editAddressID = address.id;
      this.editShippingName = address.name;
      this.editShippingAddress = address.address;
      this.editShippingPhone = address.phone;
      this.$refs.editAddressModal.open();
    },
    showOtherAddress: function () {
      this.otherdeliveries = [];
      for (var i=0; i < this.deliveries.length; i++) {
        //eslint-disable-next-line
        console.log("other",this.deliveries[i].id+","+self.defaultAddress)
        if (this.deliveries[i].id !== this.defaultAddress) {
          this.otherdeliveries.push(this.deliveries[i]);
        }
      }
      this.$refs.otherAddressModal.open();
    },
    editAddress: function () {
      var self = this;
      firebase.auth().onAuthStateChanged(function(user) {
        var data = {};
        data.uid = user.uid;
        data.name = self.editShippingName;
        data.address = self.editShippingAddress;
        data.phone = self.editShippingPhone;
        firebase.firestore().collection("delivery").doc(self.editAddressID).set(data,{ merge: true })
        .then(() => {
          self.$refs.editAddressModal.close();
          self.getDelivery()
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('delivery', error)
        });
      })
    },
    deleteAddress: function (address) {
      var self = this;
      var result = confirm("คุณแน่ใจหรือว่าต้องการลบที่อยู่นี้");
      if (result) {
        firebase.firestore().collection("delivery").doc(address.id).delete()
        .then(() => {
          if (self.otherdeliveries.length > 0) {
            self.defaultAddress = self.otherdeliveries[0].id;
            self.updateDefaults(self.otherdeliveries[0].id);
          }
          self.getDelivery();
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('delivery', error)
        });
      }
    },
    getDelivery: function () {
      var self = this;
      self.deliveries = [];
      self.currentDelivery = [];
      firebase.auth().onAuthStateChanged(function(user) {
        firebase.firestore().collection("delivery").where('uid', '==', user.uid).get()
        .then((result) => {
          for (var i = 0; i< result.docs.length; i++){
            var item = result.docs[i].data();
            delete item.uid;
            item.id = result.docs[i].id;
            self.deliveries.push(item);
            if (item.id == self.defaultAddress) {
              self.currentDelivery.push(item)
            }
          }
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('delivery', error)
        });
      })
    },
    getDefaults: function () {
      var self = this;
      self.otherdeliveries = [];
      firebase.auth().onAuthStateChanged(function(user) {
        firebase.firestore().collection("defaults").doc(user.uid).get()
        .then((result) => {
          self.defaultAddress = result.data().address;
          self.getDelivery();
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('delivery', error)
        });
      })
    },
    changeAddress: function (delivery) {
      this.defaultAddress = delivery.id;
      for (var i=0; i < this.deliveries.length; i++) {
        if (this.deliveries[i].id === delivery.id) {
          this.currentDelivery = [];
          this.currentDelivery.push(delivery)
        }
      }
      this.$refs.otherAddressModal.close();
      var self = this;
      self.updateDefaults(delivery.id);
    },
    updateDefaults: function (addressid) {
      var data = {};
      data.address = addressid;
      firebase.auth().onAuthStateChanged(function(user) {
        firebase.firestore().collection("defaults").doc(user.uid).set(data,{ merge: true })
          .then(() => {
          })
          .catch(function (error) {
            //eslint-disable-next-line
            console.log('delivery', error)
          });
        })
    },
    initPayment: function () {
      var self = this;
      // var invno = Math.floor(Math.random() * Date.now())
      var invno = nanoid();
      firebase.auth().onAuthStateChanged(function(user) {
        var data = {};
        data.uid = user.uid;
        data.invono = invno.toString();
        data.delivery = self.currentDelivery[0];
        data.products = self.cartDetail;
        data.shopid = self.$route.params.shop.toString()
        data.total = self.cartGrandTotal
        data.created = firebase.firestore.Timestamp.now();
        data.status = 'Pending'
        firebase.firestore().collection("checkout").add(data)
          .then(() => {
            self.$router.push({ name: 'Payment', params: { invoice: invno }})
          })
          .catch(function (error) {
            //eslint-disable-next-line
            console.log('delivery', error)
          });
        })
    },
    ...mapActions([
      "removefromCart",
      "updateQuantity",
      "loadCart"
    ]),
  },
  computed: {
    ...mapGetters([
      "cart"
    ]),
  },
  mounted() {
    this.loadCart(JSON.parse(localStorage.getItem('cart')));
    var self = this;
    setTimeout(function () {
      self.checkCartDetail();
    }, 100);
    this.getDefaults();
  },
  watch: {
  },
  data() {
    return {
      cartamount: 0,
      cartTotal: 0,
      cartShipping: 40,
      cartGrandTotal: 0,
      cartDetail: [],
      newShippingName: '',
      newShippingAddress: '',
      newShippingPhone: '',
      editShippingName: '',
      editShippingAddress: '',
      editShippingPhone: '',
      editAddressID: '',
      deliveries: [],
      currentDelivery: [],
      otherdeliveries: [],
      defaultAddress: ''
    };
  },
};
</script>

<style>
.cart-page{
  padding:15px 30px;
  font-family: 'Kanit';
}
.page-header{
  font-weight: bold;
  font-size: 16px;
}
.page-header-left{
  float: left;
}
.page-header-right{
  float: right;
}
.cart-wrapper{
  margin-top:20px;
  background-color:white;
  border-radius: 10px; 
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.cart-header{
  padding:10px 15px;
}
.cart-detail{
  border-top: 1px solid #f0f0f0;
  padding:15px 20px;
}
.cart-item{
  border-bottom: 1px solid #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.cart-item:first-child{
  padding-top: 10px;
}
.cart-item:last-child{
  border-bottom: 0px;
  padding-bottom: 15px;
}
.cart-header-left{
  float: left;
  margin-top: 5px;
}
.backbtt{
  border:1px solid #f3155b;
  color:#f3155b;
  float:right;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
}
.backbtt:hover{
  background-color:#f3155b;
  color:white;
}
.cart-icon{
  margin-right:10px;
  margin-top:-3px;
  padding-top:7px;
  background-color: #d2ecf6;
  width:40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}
.cart-item-wrapper{
  padding:10px 0px;
}
.item-image{
  width:120px;
  height:120px;
  border-radius: 7px;
  overflow: hidden;
  background-size: cover;
  float: left;
  cursor: pointer;
}
.item-detail{
  width:50%;
  float:left;
  margin-left: 20px;
}
.item-title{
  font-size: 18px;
  font-weight: 400;
  overflow-wrap: break-word;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-category{
  display: block;
  padding: 2px 5px;
  background-color: #d4edfc;
  font-size: 11px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-bottom: 10px;
  float:left;
  margin-top:10px;
}
.item-desc{
  font-size: 12px;
  color: #5c5c5c;
  font-weight: 400;
  line-height: 1.428571429;
  overflow-wrap: break-word;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 5px;
}
.item-location{
  color: #3c5b68;
  font-size: 12px;
  margin-top: 2px;
}
.item-action{
  float:right;
  width:22%;
  text-align:right;
}
.remove-item{
  color:red;
  font-size: 14px;
  margin-top:36px;
  cursor: pointer;
}
.cart{
  width:100%;
  padding:5px 10px;
  border:1px solid #75b9dd;
  color: #3893c1;
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  text-decoration: none;
}
.item-quantity-wrapper{
  display: inline-block;
  margin-top:8px;
  font-size: 14px;
}
.item-quantity{
  border-color: #D5D9D9;
  border-radius: 8px;
  color: #0F1111;
  background: #F0F2F2;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
  width:50px;
  text-align: center;
}
.pay-btt{
  width:100%;
  background-color:#196a9f;
  color:white;
  text-align: center;
  margin-top:25px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size:20px;
  padding-top:15px;
  padding-bottom: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.pay-btt:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.add-btt{
  background-color:#196a9f;
  color:white;
  text-align: center;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size:16px;
  padding-top:10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  width:110px;
  margin-right: 13px;
  float:right;
}
.add-btt:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.cart-total{
  padding-bottom: 15px;
}
.cart-total:last-child{
  padding-bottom: 5px;
}
.cart-grand-total{
  border-top: 1px solid #f0f0f0;
  padding:15px 20px;
  padding-bottom: 20px;
}
.new-address{
  margin-top:15px;
}
.new-address:hover{
  color: #f3155b;
  cursor: pointer;
}
.form-input{
  padding-bottom: 15px;
}
.form-label{
  margin-bottom: 5px;
}
.help{
  color: #979696;
  width:100%;
  text-align: center;
  padding-top:15px;
  padding-bottom: 15px;
}
.address-icon{
  width:100%;
  background-color:#e3f5ff;
  border-radius: 7px;
}
.delivery-address{
  color: #979696;
}
.delivery-phone{
  margin-top:5px;
}
.address-actions{
  float:right;
  font-size: 14px;
  color: #f3155b;
  cursor: pointer;
}
.delivery-option{
  margin-bottom: 25px;
}
.delivery-option:last-child{
  margin-bottom: 0px;
}
.more-delivery{
  text-align: center;
  background-color: #d2ecf6;
  color: #3893c1;
  padding-top:10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.more-delivery:hover{
  color:#196a9f;
}
.delivery-options.choice-option .delivery-option{
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 5px;
}

.delivery-options.choice-option .delivery-option:hover{
  background-color: #d7f1ff;
}
.cart-price{
  text-align: right !important;
  float:right !important;
}
</style>
<style scoped>
/deep/.sweet-modal .sweet-title > h2 {
  margin-top: 19px;
}
@media only screen and (max-width: 1100px) {
  .cart-page{
    padding-left: 10px;
    padding-right: 10px;
  }
  .cart-item-wrapper{
    padding-left: 0px;
    padding-right: 0px;
  }
  .item-action{
    float:left;
    width:100%;
    text-align: left;
    padding-left: 140px;
  }
  .cart-price{
    text-align: left !important;
    float:left !important;
  }
  .page-header-mobile-left{
    float: left;
  }
  .confirm-header{
    margin-top:20px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1100px) {
  .confirm-header{
    margin-top: 0px;
  }
  .cart-page{
    width:99.3%;
  }
  .item-action{
    float: right;
    width:23%;
    text-align: right;
    padding-left: 0px;
  }
  .cart-price{
    text-align: right !important;
    float:right !important;
  }
  .item-detail{
    width:35%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .item-action{
    float: right;
    width:23%;
    text-align: right;
    padding-left: 0px;
  }
  .cart-price{
    text-align: right !important;
    float:right !important;
  }
  .item-detail{
    width:50%;
  }
}
</style>