<template>
    <div class="menu-container user">
       <div class="menu-wrapper">
           <div v-on:click="closeSideMenu()" class="menu-item">
                <router-link to="/contact">
                    <svg style="float:left;margin-right:10px;margin-top:6px;font-size:12px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                    <div class="menu-txt">เข้าสู่ระบบ</div>
                    <div class="clearfix-left"></div>
                </router-link>
            </div>
            <div v-on:click="closeSideMenu()" class="menu-item">
                <router-link to="/contact">
                    <svg style="float:left;margin-right:10px;margin-top:6px;font-size:12px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                    <div class="menu-txt">ดูรถเข็น</div>
                    <div class="clearfix-left"></div>
                </router-link>
            </div>
            <div v-on:click="closeSideMenu()" class="menu-item">
                <router-link to="/contact">
                    <svg style="float:left;margin-right:10px;margin-top:6px;font-size:12px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                    <div class="menu-txt">ประวัติร้านค้า</div>
                    <div class="clearfix"></div>
                </router-link>
            </div>
            <div v-on:click="closeSideMenu()" class="menu-item">
                <router-link to="/contact">
                    <svg style="float:left;margin-right:10px;margin-top:6px;font-size:12px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                    <div class="menu-txt">ติดต่อเรา</div>
                    <!-- <font-awesome-icon class="list-icon" icon="chevron-down" /> -->
                    <div class="clearfix"></div>
                </router-link>
            </div>
       </div>
    </div>
</template>

<script>
export default {
    name: "UserMainMenu",
    data: function () {
        return {
            
        }
    },
    components: {
    },
    created() {
    },
    methods:{
    }
}
</script>

<style lang="scss">
    .menu-container.user{
        width:200px;
        font-family: 'Kanit';
    }
    .user-menu-icon{
        float:left;
        margin-right: 15px;
    }
    .user .menu-wrapper{
        margin-top:25px;
    }
    .user .menu-item{
        margin-bottom: 15px;
    }
    .clearfix-left{
        clear:left;
    }
</style>