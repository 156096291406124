import { render, staticRenderFns } from "./AdminPost.vue?vue&type=template&id=562169ca&scoped=true"
import script from "./AdminPost.vue?vue&type=script&lang=js"
export * from "./AdminPost.vue?vue&type=script&lang=js"
import style0 from "./AdminPost.vue?vue&type=style&index=0&id=562169ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562169ca",
  null
  
)

export default component.exports