<template>
    <div class="vendor menu-container">
       <div class="large-screen">
        <vuescroll :ops="ops">
            <div class="menu-wrapper">
                <div class="admin-logo"><router-link to="/vendor/dashboard"><div class="logo"><img src="../assets/tylogow.png"/></div></router-link></div>
                <div class="main-menus">
                    <div class="admin-menu-item">
                        <router-link to="/vendor/dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
                            <div class="admin-menu-txt">Dashboard</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/vendor/shop">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            <div class="admin-menu-txt">Shop</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/vendor/posts">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                            <div class="admin-menu-txt">Posts</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div class="admin-menu-item">
                        <router-link to="/vendor/orders">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                            <div class="admin-menu-txt">Orders</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                    <div v-on:click="logOut()" class="admin-menu-item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        <div class="admin-menu-txt">Log Out</div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </vuescroll>
       </div>
    </div>
</template>

<script>
import vuescroll from 'vuescroll';
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
export default {
    name: "VendorMainMenu",
    data: function () {
        return {
            search:'',
            menuStatus:'unloaded',
            categoryClass: 'closed',
            ops: {
                vuescroll: {},
                scrollPanel: {},
                rail: {},
                bar: {
                    background: '#196A9F',
                    opacity: '0'
                }
            }
        }
    },
    components: {
      vuescroll,
    },
    computed: mapGetters(["menuState","shopMenuState","shopSideBarState","backendState"]),
    created() {
    },
    methods:{
        toggleCategories: function(){
            if (this.categoryClass == 'open') {
                this.categoryClass = 'closed';
            } else {
                this.categoryClass = 'open';
            }
        },
        logOut: function(){
            //localStorage.removeItem('user')
            //localStorage.removeItem('token')
            var self = this;
            firebase.auth().signOut().then(() => {
            // Sign-out successful.
                self.$router.replace('/login')
            }).catch(() => {
            // An error happened.
            });
        },
        ...mapActions(["setMenuClass","setSideBarMenuClass"])
    }
}
</script>

<style lang="scss">
    .vendor.menu-container{
        padding:0px;
        background-color:#196a9f;
        box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        border:1px solid #196a9f;
        background-image: none;
        z-index: 2222;
    }
    .admin-logo{
        height:70px;
        text-align: center;
        padding:18px 24px 0px 24px;
        background-color:#196a9f
    }
    .admin-logo .logo img{
        height:35px;
        width:auto;
    }
    .admin .main-menus{
        margin-top:10px;
    }
    .admin-menu-item{
        text-align: center;
        padding-left:24px;
        padding-top:12px;
        padding-bottom:12px;
        cursor:pointer;
        transition: all 0.2s ease;
        color:white;
    }
    .admin-menu-item a{
        color:white;
    }
    .admin-menu-item a:hover{
        // color:rgb(235, 81, 107);
        color: #75b9dd;
    }
    .admin-menu-item svg{
        float:left;
    }
    .admin-menu-txt{
        float:left;
        margin-left:10px;
    }
    .mobile-screen{
        display:none;
    }
    @media only screen and (max-width: 1100px) {
        .admin.menu-container{
            background-color:#032a4b;
        }
        .admin-logo{
            display:none;
        }
        .admin .main-menus{
            margin-top:75px;
        }
    }
</style>