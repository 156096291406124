<template>
  <div class="contact-page">
		<div class="contact-body">
			<div class="contact-page-title">
				<div class="page-title">ติดต่อเรา</div>
				<div class="page-subtitle">Contact Us</div>
			</div>
			<div style="height:25px;" class="clearfix"></div>
			<div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="tel:0819365392">
                    <div class="item-container report">
                        <div class="upper-icon"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">โทรสํานักงานขาย</span> <br> <span class="upper-text-subtitle">Call our Sales Department</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="http://line.me/ti/p/~livetycoon">
                    <div class="item-container report">
                        <div class="upper-icon"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">เพิ่มเราเป็นเพื่อนบน LINE</span> <br> <span class="upper-text-subtitle">Add us as friend on LINE</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="http://line.me/ti/p/~saitaicenter">
                    <div class="item-container report">
                        <div class="upper-icon"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">ติดต่อสํานักงานขาย LINE</span> <br> <span class="upper-text-subtitle">LINE Sales Department</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="https://goo.gl/maps/6KMzyP8E47vgkjhq8">
                    <div class="item-container report">
                        <div class="upper-icon"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">มาพบเรา</span> <br> <span class="upper-text-subtitle">Meet with us</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="https://www.facebook.com/Live-Tycoon-103346034384817/">
                    <div class="item-container report">
                        <div class="upper-icon last"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">เฟซบุ๊ก</span> <br> <span class="upper-text-subtitle">Facebook</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="https://www.instagram.com/livetycoon">
                    <div class="item-container report">
                        <div class="upper-icon last"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">อินสตาแกรม</span> <br> <span class="upper-text-subtitle">Instagram</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
            <div v-animate-css="'zoomIn'" class="contact-card contact-item">
				<a href="https://www.twitter.com/livetyc00n">
                    <div class="item-container report">
                        <div class="upper-icon last"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></div>
                        <div class="bottom-text">
                            <span class="upper-text-title">ทวิตเตอร์</span> <br> <span class="upper-text-subtitle">Twitter</span>
                        </div>
                        <div style="height:15px;" class="clearfix"></div>
                    </div>
				</a>
			</div>
		</div>
        <div style="height:15px;clear:both" class="clearfix"></div>
        <div v-animate-css="'fadeIn'" class="contact-footer">
            <img src="../assets/contactbg.png"/>
        </div>  
	</div>
</template>

<script>
export default {
    name: "Contact",
    components:{
    },
    methods:{
    },
    computed: {
    },
    created() {
    },
    data() {
      return {
      }
    }
}
</script>

<style scoped>
    .contact-page{
        padding-left:10px;
        padding-right: 10px;
    }
    .contact-footer{
        width:40%;
        margin-left: auto;
        margin-right: auto;
        margin-top:7%;
        clear:left;
    }
    .contact-footer img{
        width:100%;
    }
   .contact-page-title{
        color:white;
        padding-right:20px;
        padding-left: 10px;
        padding-top:20px;
        margin-bottom: 20px;
    }
    .page-title{
        float:left;
        font-family: 'Kanit';
    }
    .page-subtitle{
        float:right;
    }
    .contact-card{
        width:23.6%;
        color:white;
        margin-bottom:1.5%;
        transition: all 0.4s ease;
    }
    .contact-card a{
        color:white;
        text-decoration: none;
    }
    .contact-card:hover{
        transform: translateY(-7px);
        -webkit-box-shadow: 0px 15px 5px 0px rgba(5,50,87,0.82);
        -moz-box-shadow: 0px 15px 5px 0px rgba(5,50,87,0.82);
        box-shadow: 0px 15px 5px 0px rgba(5,50,87,0.82);
    }
    .contact-item{
        background-color:#0E4575;
        border-radius: .25rem;
        position: relative;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        min-width: 0;
        float:left;
        margin-left: 0.7%;
        margin-right: 0.7%;
        height:auto;
        box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        border:1px solid #0f4370;
    }
    .upper-icon{
        background-color:#f3155b;
        padding:12px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        text-align: center;
        width:60px;
        height:60px;
    }
    .item-container{
        padding:20px 25px;
        padding-bottom: 0px;
    }
    .bottom-text{
        text-align: center;
        font-family: 'Kanit';
        margin-top:10px;
    }
    .upper-text-title{
        font-size:20px;
        margin-top:20px;
    }
    .upper-text-subtitle{
        margin-top:5px;
        margin-bottom: 20px;
        font-size:16px;
    }
    @media only screen and (min-width: 701px) and (max-width: 1100px) {
        .contact-body{
            margin-top:15px;
        }
        .contact-card{
            width:48.5%;
            margin-top:0px;
        }
        .contact-footer{
            width:65%;
            margin-top:25px;
            margin-bottom: 25px;
        }
    }
    @media only screen and (max-width: 700px) {
        .contact-body{
            margin-top:15px;
        }
        .contact-card{
            width:100%;
            margin-top:10px;
        }
        .contact-page-title{
            padding-left: 3px;
            padding-right: 3px;
        }
        .contact-footer{
            width:85%;
            margin-top:25px;
            margin-bottom: 25px;
        }
    }
</style>