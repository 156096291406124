<template>
  <div class="dashboard-page">
    <AdminMenu class="admin-menu"></AdminMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <AdminMenu class="mobile-menu"></AdminMenu>
    </div>
    <div class="dashboard-body">
      <div class="admin-page-title">
        <div class="page-title">APP</div>
        <div class="page-subtitle">Manage App Version</div>
      </div>
      <div style="height: 25px" class="clearfix"></div>
      <div class="account-dashboard dashboard-item full">
        <div class="item-container table-list">
          <div class="content-menu">
            <div v-on:click="showAddNew()" class="menu-button main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Add
            </div>
          </div>
          <div class="dashboard-title">All App Versions</div>
          <vue-good-table
            @on-cell-click="selectCell"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 30,
            }"
            styleClass="vgt-table"
          />
        </div>
      </div>
    </div>
    <sweet-modal
      :title="newsModalTitle"
      blocking="false"
      ref="versionModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input">
        <div class="form-label">Build Number</div>
        <input v-model="buildversion" class="form-control" />
      </div>
      <sweet-button
        v-if="operation == 'New'"
        v-on:click="addVersion()"
        slot="button"
        ><div class="newConfirmBtt">Confirm</div></sweet-button
      >
      <sweet-button
        v-if="operation == 'Edit'"
        v-on:click="editVersion()"
        slot="button"
        ><div class="newConfirmBtt">Confirm</div></sweet-button
      >
    </sweet-modal>
    <sweet-modal
      title="News"
      ref="newsViewModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input view-newsImage">
        <img :src="BannerURL" />
      </div>
      <div class="form-input view-newsTitle">
        <div>{{ newsTitle }}</div>
      </div>
      <div class="form-input view-newsDesc">
        <!--<textarea v-model="StreamTitle" class="form-control"></textarea>-->
        <div v-html="newsDesc"></div>
      </div>
      <sweet-button v-on:click="closeViewNews()" slot="button"
        ><div class="newConfirmBtt">Close</div></sweet-button
      >
    </sweet-modal>
    <sweet-modal
      ref="successVersion"
      modal-theme="light"
      overlay-theme="dark"
      icon="success"
    >
      <div class="successLive">เพิ่ม Version ใหม่แล้ว</div>
    </sweet-modal>
    <sweet-modal
      ref="editNews"
      modal-theme="light"
      overlay-theme="dark"
      icon="success"
    >
      <div class="successLive">เเก้ News แล้ว</div>
    </sweet-modal>
    <sweet-modal
      ref="deleteNews"
      modal-theme="light"
      overlay-theme="dark"
      icon="success"
    >
      <div class="successLive">ลบ News แล้ว</div>
    </sweet-modal>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import AdminMenu from "@/components/AdminMenu";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { SweetModal } from "sweet-modal-vue";
import moment from "moment";
import { backendURL } from "../config";

export default {
  name: "AdminNews",
  components: {
    AdminMenu,
    VueGoodTable,
    SweetModal,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    showAddNew() {
      this.editingID = "";
      this.newsTitle = "";
      this.newsDesc = "";
      this.BannerURL = "";
      this.operation = "New";
      this.newsModalTitle = "Add Version";
      this.$refs.versionModal.open();
    },
    async addVersion() {
      var self = this;
      let newsParam = {
        version: this.buildversion,
      };

      try {
        await axios.post(`${backendURL}/addversion/`, newsParam);
        self.$refs.versionModal.close();
        self.$refs.successVersion.open();
        self.getNews();
      } catch (error) {
        // console.error(error);
      }
    },
    async editNews() {
      var self = this;
      let editNews = {
        id: this.editingID,
        Image: this.BannerURL,
        Title: this.StreamTitle,
        Description: this.newsDesc,
        Status: "0",
      };
      // eslint-disable-next-line
      //   console.log(editNews);

      try {
        await axios.post(`${backendURL}/updatenews/`, editNews);
        self.$refs.newsModal.close();
        self.$refs.editNews.open();
        self.getNews();
      } catch (error) {
        // console.error(error);
      }
    },
    selectCell(params) {
      if (params.column.field == "Status") {
        //alert(params.row.originalIndex);
        if (this.rows[params.row.originalIndex].Status.indexOf("Open") != -1) {
          //alert("Open - 0");
          this.toggleStatus(
            "1",
            params.row.id,
            params.row.originalIndex,
            "<div class='user-status closed'>Maintainence</div>"
          );
        } else {
          //alert("Close - 1");
          this.toggleStatus(
            "0",
            params.row.id,
            params.row.originalIndex,
            "<div class='user-status open'>Open</div>"
          );
        }

        //this.$router.push({ name: 'Visit', params: { id: params.row.id }})
      }
      if (params.column.field == "Delete") {
        var r = confirm("Are you sure you want to delete?");
        if (r == true) {
          this.deleteNews(params.row.id);
        }
      }
      if (params.column.field == "Edit") {
        this.editingID = params.row.id;
        this.operation = "Edit";
        this.newsTitle = params.row.Title;
        this.newsDesc = params.row.Description;
        this.BannerURL = params.row.Image;
        this.newsModalTitle = "Edit News";
        this.$refs.newsModal.open();
      }
      if (params.column.field == "View") {
        this.newsTitle = params.row.Title;
        this.newsDesc = params.row.Description;
        this.BannerURL = params.row.Image;
        this.$refs.newsViewModal.open();
      }
    },
    async toggleStatus(status, id, index, text) {
      let newstatus = "0";
      let self = this;
      if (status === "0") {
        newstatus = "1";
      } else {
        newstatus = "0";
      }
      let changes = {
        id: id,
        status: newstatus,
        updated_at: moment()
          .subtract(7, "hours")
          .format("YYYY-MM-DD HH:mm:ss.000"),
      };

      try {
        const { status } = await axios.post(
          `${backendURL}/updateversion/`,
          changes
        );
        self.rows[index].Status = text;
        self.rows[index].updated_at = moment().format(
          "Do MMMM YYYY - HH:mm:ss"
        );
        self.output = status;
      } catch (error) {
        self.output = error;
      }
    },
    async getVersions() {
      const response = await axios.get(`${backendURL}/getallversion/`);
      this.columns = [
        {
          label: "ID",
          field: "id",
          hidden: true,
        },
        {
          label: "Version",
          field: "version",
          type: "String",
        },
        {
          label: "Created",
          field: "created_at",
          // dateInputFormat: 'yyyy-MM-ddTT',
          // dateOutputFormat: 'MMM do yy - hh:mm',
          type: "String",
        },
        {
          label: "Last Update",
          field: "updated_at",
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'MMM do yy - hh:mm',
          type: "String",
        },
        {
          label: "Status",
          field: "Status",
          type: "String",
          html: "true",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
      // eslint-disable-next-line
      //   console.log(response.data);
      response.data.forEach((element) => {
        element.created_at = moment(element.created_at).format(
          "Do MMMM YYYY - HH:mm:ss"
        );
        element.updated_at = moment(element.updated_at).format(
          "Do MMMM YYYY - HH:mm:ss"
        );
        if (element.status == "1") {
          element.Status = "<div class='user-status open'>Open</div>";
        } else {
          element.Status = "<div class='user-status closed'>Maintainence</div>";
        }
      });
      this.rows = response.data;
      //this.onlinecount = response.data[0];
    },
    async deleteNews(id) {
      var self = this;

      try {
        await axios.get(`${backendURL}/deletenews/` + id);
        self.$refs.deleteNews.open();
        self.getNews();
        this.editingID = "";
        this.newsTitle = "";
        this.BannerURL = "";
        this.newsDesc = "";
      } catch (error) {
        // console.error(error);
      }
    },
    toggleMobileMenu: function () {
      if (this.menuState == "menu-open") {
        this.setMenuClass("menu-close");
      } else {
        this.setMenuClass("menu-open");
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    triggerShopSidebar: function () {
      if (this.shopSideBarState == "hide-shopside-menu") {
        this.setSideBarMenuClass("show-shopside-menu");
        this.setMenuClass("menu-close");
      } else {
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    closeViewNews: function () {
      this.$refs.newsViewModal.close();
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    this.getVersions();
  },
  data() {
    return {
      editingID: "",
      buildversion: "",
      newsDesc: "",
      BannerURL: "",
      operation: "New",
      newsModalTitle: "Add News",
      columns: [],
      rows: [],
    };
  },
};
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.dashboard-page{
  background-color: #196a9f;
}
.dashboard-body {
  margin-left: 280px;
}
.admin-page-title {
  color: #333;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.admin-page-title .page-title {
  font-weight: bold;
  float: left;
}
.admin-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  background-color: #0e4575;
  border-radius: 0.25rem;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #0f4370;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: white;
  margin-bottom: 1.5%;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #33be8f;
  border-radius: 50%;
  padding: 12px;
  color: white;
}
.upper-icon svg {
  color: #fff;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.menu-button {
  width: 100px;
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.menu-button.main {
  background-color: #f3155b;
}
.menu-button.main:hover {
  background-color: #af1445;
}
.newConfirmBtt {
  background-color: #f3155b;
  padding: 10px 0px;
  width: 120px;
  text-align: center;
  float: right;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.menu-button svg {
  margin-top: -4px;
}
.content-menu {
  margin-bottom: 20px;
}
.form-input {
  margin-bottom: 15px;
  text-align: left;
}
.form-input input {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  font-family: "Kanit";
}
.successLive {
  font-family: "Kanit";
}
/deep/.sweet-modal .sweet-title > h2 {
  margin-top: 19px;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  cursor: pointer;
}
/deep/.showbtt {
  color: #f3155b;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
/deep/.vgt-global-search {
  background: #0e4575;
  border: 0px;
  margin-top: 20px;
}
.view-newsImage {
  width: 100%;
}
.view-newsImage img {
  width: 100%;
}
.view-newsTitle {
  font-size: 18px;
  font-weight: bold;
}
@media screen and (max-width: 1100px) {
  .admin-menu {
    display: none;
  }
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
}
</style>