<template>
  <div class="dashboard-page">
    <AdminMenu class="admin-menu"></AdminMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <AdminMenu class="mobile-menu"></AdminMenu>
    </div>
    <div class="dashboard-body">
      <div class="admin-page-title">
        <div class="page-title">DASHBOARD</div>
        <div class="page-subtitle">Welcome to Dashboard</div>
      </div>
      <div style="height: 25px" class="clearfix"></div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container">
          <div style="margin-left: 30px; margin-top: 25px" class="upper-text">
            Welcome Back! <br />
            Tycoon Admin
          </div>
          <div class="upper-image"><img src="../assets/profile-img.png" /></div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">Shops</span> <br />
            <span class="upper-text-subtitle">{{ shopcount }}</span>
          </div>
          <div class="upper-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-smile"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
              <line x1="9" y1="9" x2="9.01" y2="9"></line>
              <line x1="15" y1="9" x2="15.01" y2="9"></line>
            </svg>
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">Posts</span> <br />
            <span class="upper-text-subtitle">{{ postcount }}</span>
          </div>
          <div class="upper-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-edit"
            >
              <path
                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
              ></path>
              <path
                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item">
        <div class="item-container report">
          <div class="upper-text">
            <span class="upper-text-title">Online</span> <br />
            <span class="upper-text-subtitle">{{ onlinecount }}</span>
          </div>
          <div class="upper-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-video"
            >
              <polygon points="23 7 16 12 23 17 23 7"></polygon>
              <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
            </svg>
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item variable">
        <div class="item-container report">
          <div class="dashboard-title">Most Viewed Categories</div>
          <div class="category-view-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-eye"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
          </div>
          <div class="most-view-category">31,410</div>
          <div class="most-view-category-subtitle">สุขภาพและความงาม</div>
          <div class="category-view-row">
            <div class="category-name">แฟชั่นผู้ชาย</div>
            <div class="category-view-result">20,000</div>
            <div class="clearfix"></div>
          </div>
          <div class="category-view-row">
            <div class="category-name">สัตว์เลี้ยง</div>
            <div class="category-view-result">18,105</div>
            <div class="clearfix"></div>
          </div>
          <div class="category-view-row">
            <div class="category-name">อุปกรณ์ อิเล็กทรอนิกส์</div>
            <div class="category-view-result">12,020</div>
            <div class="clearfix"></div>
          </div>
          <div style="height: 15px" class="clearfix"></div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item large">
        <div class="item-container report">
          <div class="dashboard-title">Post by Categories</div>
          <div class="chart-wrapper">
            <div id="postchart"></div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="account-dashboard dashboard-item full">
        <div class="item-container table-list">
          <div class="dashboard-title">Latest Users</div>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            styleClass="vgt-table"
          />
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import AdminMenu from "@/components/AdminMenu";
import ApexCharts from "apexcharts";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { backendURL } from "../config";
export default {
  name: "Login",
  components: {
    AdminMenu,
    VueGoodTable,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    async getPostCount() {
      const response = await axios.get(`${backendURL}/getshoppostcount/`);
      this.postcount = response.data[0].total;
    },
    async getShopCount() {
      const response = await axios.get(`${backendURL}/getshopcount/`);
      this.shopcount = response.data[0].total;
    },
    async getOnlineCount() {
      const response = await axios.get(`${backendURL}/getshoponlinecount/`);
      this.onlinecount = response.data[0].total;
    },
    async getPostByCat() {
      const response = await axios.get(`${backendURL}/getshoppostcountbycat/`);
      var arr = [];
      var total = [];
      response.data.forEach((element) => {
        arr.push(element.CategoryName);
        total.push(element.total);
        //this.options.series[0].data[this.options.xaxis.categories.indexOf(element.CategoryName)] = element.total;
      });
      this.options.series[0].data = total;
      this.options.xaxis.categories = arr;
      var chart = new ApexCharts(
        document.querySelector("#postchart"),
        this.options
      );
      chart.render();
      //this.onlinecount = this.options.xaxis.categories;
    },
    async getUsers() {
      const response = await axios.get(`${backendURL}/getusersp/0`);
      this.columns = [
        {
          label: "FCusID",
          field: "FCusID",
          type: "String",
        },
        {
          label: "FCusName",
          field: "FCusName",
          type: "String",
        },
        {
          label: "FCusNickName",
          field: "FCusNickName",
          type: "String",
        },
        {
          label: "RoleID",
          field: "RoleID",
          hidden: true,
        },
        {
          label: "RoleName",
          field: "RoleName",
          type: "String",
        },
        {
          label: "Email",
          field: "Email",
          type: "String",
        },
        {
          label: "created_at",
          field: "created_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM Do yy",
          type: "date",
        },
        {
          label: "updated_at",
          field: "updated_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM Do yy",
          type: "date",
        },
      ];
      response.data.results.forEach((element) => {
        element.created_at = element.created_at.split("T")[0];
        element.updated_at = element.updated_at.split("T")[0];
      });
      this.rows = response.data.results;
      //this.onlinecount = response.data[0];
    },
    async getUserShop() {
      const response = await axios.get(`${backendURL}/getshopuser/`);
      this.columns = [
        {
          label: "RoleID",
          field: "RoleID",
          hidden: true,
        },
        {
          label: "ShopID",
          field: "id",
          hidden: true,
        },
        {
          label: "ID",
          field: "FCusID",
          type: "String",
        },
        {
          label: "User Name",
          field: "FCusName",
          type: "String",
        },
        {
          label: "Nick Name",
          field: "FCusNickName",
          type: "String",
        },
        {
          label: "Shop Name",
          field: "name",
          type: "String",
        },
        {
          label: "Role",
          field: "RoleName",
          type: "String",
        },
        {
          label: "Email",
          field: "Email",
          type: "String",
        },
        {
          label: "Created",
          field: "created_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM do yy",
          type: "date",
        },
        {
          label: "Last Update",
          field: "updated_at",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM do yy",
          type: "date",
        },
        {
          label: "Status",
          field: "Status",
          html: "true",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
      response.data.forEach((element) => {
        element.created_at = element.created_at.split("T")[0];
        element.updated_at = element.updated_at.split("T")[0];
        if (element.Status == 1) {
          element.Status = "<div class='user-status closed'>Closed</div>";
        } else {
          element.Status = "<div class='user-status open'>Open</div>";
        }
      });
      this.rows = response.data;
      //this.onlinecount = response.data[0];
    },
    toggleMobileMenu: function () {
      if (this.menuState == "menu-open") {
        this.setMenuClass("menu-close");
      } else {
        this.setMenuClass("menu-open");
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    triggerShopSidebar: function () {
      if (this.shopSideBarState == "hide-shopside-menu") {
        this.setSideBarMenuClass("show-shopside-menu");
        this.setMenuClass("menu-close");
      } else {
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    //this.postcount = 5;
    this.getPostCount();
    this.getShopCount();
    this.getOnlineCount();
    this.getUserShop();
    //var options = {
    /*this.options = {
			chart: {
				type: 'bar',
				height: '310px',
				foreColor: '#fff'
			},
			series: [{
				name: 'Posts',
				data: [90,140,35,50,90,60,55,120,125,70]
			}],
			xaxis: {
				categories: ['Men','Women','Kids','Accessories','Electronics','Auto','Beauty','Food','Beverage','Pets']
			},
			fill: {
				colors: ['#33be8f']
			},
			dataLabels: {
				style: {
					colors: ['#FFF']
				}
			},
			markers: {
				colors: ['#FFF']
			},
			tooltip: {
				style: {
					color: '#33be8f'
				},
			},
		}*/
    this.getPostByCat();
  },
  data() {
    return {
      options: {
        chart: {
          type: "bar",
          height: "310px",
          foreColor: "#fff",
        },
        series: [
          {
            name: "Posts",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        xaxis: {
          categories: [
            "Men",
            "Women",
            "Kids",
            "Accessories",
            "Electronic",
            "Auto",
            "Beauty",
            "Food",
            "Beverage",
            "Pets",
          ],
        },
        fill: {
          colors: ["#33be8f"],
        },
        dataLabels: {
          style: {
            colors: ["#FFF"],
          },
        },
        markers: {
          colors: ["#FFF"],
        },
        tooltip: {
          style: {
            color: "#33be8f",
          },
        },
      },
      shopcount: 0,
      postcount: 0,
      onlinecount: 0,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Age",
          field: "age",
          type: "number",
        },
        {
          label: "Created On",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM Do yy",
        },
        {
          label: "Percent",
          field: "score",
          type: "percentage",
        },
      ],
      rows: [
        { id: 1, name: "John", age: 20, createdAt: "", score: 0.03343 },
        {
          id: 2,
          name: "Jane",
          age: 24,
          createdAt: "2011-10-31",
          score: 0.03343,
        },
        {
          id: 3,
          name: "Susan",
          age: 16,
          createdAt: "2011-10-30",
          score: 0.03343,
        },
        {
          id: 4,
          name: "Chris",
          age: 55,
          createdAt: "2011-10-11",
          score: 0.03343,
        },
        {
          id: 5,
          name: "Dan",
          age: 40,
          createdAt: "2011-10-21",
          score: 0.03343,
        },
        {
          id: 6,
          name: "John",
          age: 20,
          createdAt: "2011-10-31",
          score: 0.03343,
        },
      ],
    };
  },
};
</script>

<style scoped>
.dashboard-page{
  background-color: #196a9f;
}
.dashboard-body {
  margin-left: 280px;
}
.admin-page-title {
  color: white;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.admin-page-title .page-title {
  font-weight: bold;
  float: left;
}
.admin-page-title .page-subtitle {
  float: right;
}
.dashboard-item {
  background-color: #0e4575;
  border-radius: 0.25rem;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #0f4370;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: white;
  margin-bottom: 1.5%;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #33be8f;
  border-radius: 50%;
  padding: 12px;
  color: white;
}
.upper-icon svg {
  color: #fff;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>