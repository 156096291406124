<template>
<div class="policy">
    <h1>นโยบายการใช้คุกกี้</h1>

    <ul>
        <li>เว็บไซต์ Live Tycoon จะส่งข้อมูลไฟล์ขนาดเล็ก (คุกกี้) ไปยังบราวเซอร์ ในคอมพิวเตอร์หรืออุปกรณ์อื่นที่ผู้ใช้บริการได้เชื่อมต่อ ซึ่งคุกกี้จะมีหน้าที่ในการเก็บรวบรวมข้อมูลหลังจากที่ผู้ใช้บริการได้เข้าสู่ระบบ (Login) เพื่อจดจำข้อมูลและอำนวยความสะดวกในการเข้าสู่ระบบ โดยที่ผู้ใช้บริการจะไม่ต้องเข้าสู่ระบบซ้ำอีกต่อไป</li>
        <li>ผู้ใช้บริการสามารถบริหารการจัดการคุกกี้บนเว็บไซต์จากการตั้งค่าของบราวเซอร์นั้นได้ และผู้ใช้บริการสามารถที่จะเลือกเปลี่ยนแปลงการตั้งค่าโดยยอมรับ ปฏิเสธ หรือลบคุกกี้ออกทั้งหมดได้ในจากการตั้งค่าของบราวเซอร์ แต่ผู้ใช้บริการอาจจะพบว่าระบบหรือการบริการจะไม่สามารถบริการได้เต็มประสิทธิภาพ การตั้งค่าคุกกี้ของบราวเซอร์แต่ละประเภทจะแตกต่างกัน ดังนั้นเพื่อจะบริหารจัดการคุกกี้ ท่านควรตรวจสอบการตั้งค่า Browser ของท่าน</li>
    </ul>

    <p style="font-weight:bold">นโยบายการใช้คุกกี้นี้ปรับปรุงเมื่อวันที่ 01/08/2563</p>   

</div>
</template>

<script>
export default {
    name: "Policy",
    components: {},
    methods: {},
    computed: {},
    created() {},
}
</script>

<style scoped>
.policy{
    color:#fff;
    padding:25px 60px;
    font-family: 'Kanit';
}
 @media only screen and (max-width: 1100px) {
     .policy{
        margin-top:15px;
        padding:20px;
     }
 }
</style>
