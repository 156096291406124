<template>
  <div id="app">
    <MainMenu
      v-if="this.$route.meta.isBackend == false"
      class="main-menu"
    ></MainMenu>
    <div v-if="this.$route.meta.isBackend == false" class="mobile-main-menu">
      <div class="row">
        <div style="margin-top:4px;" v-on:click="toggleMobileMenu()" class="col-4">
          <font-awesome-icon
            class="bars-icon"
            icon="bars"
            style="color:#1B6BA0;margin-right:7px;margin-left:7px;font-size:16px;"
          />
          เมนู
        </div>
        <div class="col-4">
          <div v-on:click="closeAllMenu()" class="mobile-logo">
            <router-link to="/"><img src="./assets/tylogo.png" /></router-link>
          </div>
        </div>
        <!--<div style="margin-top:2px;text-align:right;" v-on:click="toggleUserMobileMenu()" class="col-4">
          <img style="height:25px;" src="./assets/shopping-cart.svg" />
          <svg aria-hidden="true" width="11.200000000000001" height="12.8" viewBox="0 0 448 512" focusable="false" class="ml-2 fa-icon" style="font-size:0.8em;margin-right:15px"><g><path d="M207 381.5l-194.3-194.3c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9l-194.3 194.3c-9.4 9.4-24.6 9.4-33.9 0z"></path></g></svg>
        </div>-->
        <div v-if="this.$route.name == 'Shop'" style="margin-top:2px;text-align:right" class="col-4">
          <!--<router-link :to="'/cart/'+this.$route.params.id">
            <img style="height:25px;margin-right:15px" src="./assets/shopping-cart.svg" />
            <div class="cart-amount mobile">{{cartAmount}}</div>
          </router-link>-->
        </div>
      <!-- <font-awesome-icon v-on:click="triggerShopSidebar()" v-bind:class="shopMenuState" style="position:absolute;right:20px;" class="shop-icon" icon="comments" />-->
      <!--<font-awesome-icon
        v-on:click="triggerShopSidebar()"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="comments"
      />
      <div
        v-on:click="triggerShopSidebar()"
        v-if="unreadChatCount > 0"
        class="unread-chats"
      >
        {{ unreadChatCount }}
      </div>-->
      </div>
    </div>
    <div
      v-if="this.$route.meta.isBackend == false"
      v-bind:class="menuState"
      class="reveal-menu"
    >
      <MainMenu class="mobile-menu"></MainMenu>
    </div>
    <div
      v-if="this.$route.meta.isBackend == false"
      v-bind:class="menuUserState"
      class="reveal-user-menu"
    >
      <UserMobileMenu class="mobile-menu"></UserMobileMenu>
    </div>
    <!-- <Reveal ref="mobilemenu" class="burger-menu"> 
        <a v-on:click="toggleMobileMenu()" id="home" href="#">
          <span>Home</span>
        </a>
      </Reveal> -->
    <div
      v-on:click="closeAllMenu()"
      v-if="this.$route.meta.isBackend == false"
      v-bind:class="menuState"
      class="menu-overlay"
    >
      Tycoon
    </div>
    <div
      v-on:click="closeAllMenu()"
      v-if="this.$route.meta.isBackend == false"
      v-bind:class="menuUserState"
      class="menu-overlay"
    >
      Tycoon
    </div>
    <div v-if="this.$route.meta.isBackend == false" class="page-view">
      <router-view ref="routerPage" />
    </div>
    <div v-else><router-view ref="routerPage" /></div>
    <cookie-law buttonText="ยอมรับ" theme="blood-orange">
      <div slot="message">
        เว็บไซต์ Live Tycoon
        ใช้คุ้กกี้เพื่อปรับปรุงในการให้บริการและพัฒนาประสิทธิภาพของเว็บไซต์ให้ดียิ่งขึ้น
        <a href="/cookie">เรียนรู้เพิ่มเติมที่นี่</a>
      </div>
    </cookie-law>
    <vue-snotify></vue-snotify>
    <audio style="display: none" id="notiSound">
      <source src="/assets/noti.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
// import { Reveal } from 'vue-burger-menu'
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import MainMenu from "@/components/MainMenu";
import UserMobileMenu from "@/components/UserMobileMenu";
import CookieLaw from "vue-cookie-law";
import { backendURL } from "./config";

export default {
  name: "app",
  components: {
    MainMenu,
    UserMobileMenu,
    CookieLaw,
    // Reveal
  },
  sockets: {
    connect: function () {
      // eslint-disable-next-line
      console.log("socket connected");
    },
  },
  mounted: function () {
    let FacebookScript = document.createElement("script");
    FacebookScript.setAttribute(
      "src",
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
    );
    document.head.appendChild(FacebookScript);
    this.countUnread();
    var self = this;
    // eslint-disable-next-line
    this.$socket.$subscribe("test", (payload) => {
      axios({
        url: `${backendURL}/getchatroombycustomer/${this.userInfo.id}`,
        method: "GET",
      })
        .then(function (response) {
          if (self.$route.name !== "Shop") {
            var notiSound = document.getElementById("notiSound");
            notiSound.play();
          }
          self.$snotify.confirm(
            response.data.chatroom[response.data.chatroom.length - 1].chats
              .ChatText,
            response.data.chatroom[response.data.chatroom.length - 1].ShopName,
            {
              timeout: 15000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              buttons: [
                // eslint-disable-next-line
                {
                  text: "Chat",
                  action: (toast) => {
                    self.$router.push({
                      name: "Shop",
                      params: {
                        id:
                          response.data.chatroom[
                            response.data.chatroom.length - 1
                          ].ShopID,
                      },
                    });
                    self.$snotify.remove(toast.id);
                  },
                  bold: false,
                },
                // eslint-disable-next-line
                {
                  text: "Close",
                  action: (toast) => {
                    // console.log("Clicked: No");
                    self.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            }
          );
        })
        .catch(function (error) {
          // eslint-disable-next-line
          console.log(error);
        });
    });
  },
  watch: {
    $route() {
      if (this.shopSideBarState == "show-shopside-menu") {
        this.setSideBarMenuClass("hide-shopside-menu");
      }
      if (this.$route.name !== "Shop" && this.userInfo.id) {
        this.countUnread();
      }
    },
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
    "unreadChatCount",
    "userInfo",
  ]),
  methods: {
    toggleMobileMenu: function () {
      if (this.menuState == "menu-open") {
        this.setMenuClass("menu-close");
      } else {
        this.setMenuClass("menu-open");
        this.setSideBarMenuClass("hide-shopside-menu");
      }
    },
    toggleUserMobileMenu: function () {
      if (this.menuUserState == "menu-open") {
        this.menuUserState = "menu-close";
      } else {
        this.menuUserState = "menu-open";
      }
    },
    countUnread: function () {
      var self = this;
      var unread = 0;
      axios({
        url: `${backendURL}/getchatroombycustomer/${this.userInfo.id}`,
        method: "GET",
      })
        .then(function (response) {
          for (var i = 0; i < response.data.chatroom.length; i++) {
            unread = unread + parseInt(response.data.chatroom[i].unread);
          }
          self.setUnreadChat(unread);
        })
        .catch(function (error) {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    triggerShopSidebar: function () {
      var self = this;
      if (this.$route.name !== "Shop") {
        if (this.userInfo.id) {
          axios({
            url: `${backendURL}/getchatroombycustomer/${this.userInfo.id}`,
            method: "GET",
          })
            .then(function (response) {
              self.$router.push({
                name: "Shop",
                params: {
                  id:
                    response.data.chatroom[response.data.chatroom.length - 1]
                      .ShopID,
                },
              });
            })
            .catch(function (error) {
              // eslint-disable-next-line
              console.log(error);
            });
        } else {
          self.$router.push({ name: "AuthBack" });
        }
      } else {
        if (this.shopSideBarState == "hide-shopside-menu") {
          this.setSideBarMenuClass("show-shopside-menu");
          this.setMenuClass("menu-close");
        } else {
          this.setSideBarMenuClass("hide-shopside-menu");
        }
      }
    },
    closeAllMenu: function () {
      this.setMenuClass("menu-close");
      this.setSideBarMenuClass("hide-shopside-menu");
      this.menuUserState = "menu-close";
    },
    ...mapActions(["setMenuClass", "setSideBarMenuClass", "setUnreadChat"]),
  },
  data() {
    return {
      isBackend: false,
      menuUserState: 'menu-close',
      cartAmount: 0
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Kanit&display=swap");
.main-menus,
.filter-item {
  font-family: "Kanit";
}
body {
  background-color: #f5f6f8;
}
.page-view {
  margin-left: 270px;
}
.burger-menu {
  display: none;
}
.bm-overlay {
  background-color: #196A9F;
}
.bm-menu {
  background-color: #032a4b;
}
.mobile-main-menu {
  display: none;
  position: fixed;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 3px;
  padding-right: 3px;
  top: 0px;
  left: 0px;
  width: 100%;
  background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#eaeef2));
  background-image: linear-gradient(
180deg
,#fff 0,#eaeef2);
  background-repeat: repeat-y;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  z-index: 9999;
  font-family: 'Kanit';
  font-size: 18px;
  color: #1B6BA0;
}
.mobile-logo {
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
}
.mobile-logo img {
  height: 25px;
}
.bars-icon {
  float: left;
  color: #fff;
  font-size: 20px;
  margin-top: 6px;
  margin-left: 5px;
}
.shop-icon {
  position: absolute;
  color: #fff;
  font-size: 20px;
  top: 16px;
}
.unread-chats {
  right: 10px;
  top: 10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 11px;
  width: 18px;
  height: 18px;
  text-align: center;
  position: absolute;
}
.reveal-menu {
  position: fixed;
  width: 270px;
  height: 100%;
  background-color: #032a4b;
  top: 0px;
  z-index: 5555;
  transition: all 0.3s ease;
}
.reveal-user-menu{
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: #032a4b;
  top: 0px;
  z-index: 5555;
  transition: all 0.3s ease;
}
.reveal-menu.menu-close {
  left: -270px;
}
.reveal-menu.menu-open {
  left: 0px;
}
.reveal-user-menu.menu-close {
  right: -270px;
}
.reveal-user-menu.menu-open {
  right: 0px;
}
.menu-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 333;
}
.menu-overlay.menu-close {
  display: none;
}
.menu-overlay.menu-open {
  display: block;
}
.hide-menu {
  display: none;
}
.show-menu {
  display: block;
}
.Cookie__content {
  font-family: "Kanit";
}
.w4rAnimated_checkmark svg {
  width: 150px;
  display: block;
  margin: 10px auto 0;
}
.w4rAnimated_checkmark .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.w4rAnimated_checkmark .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.w4rAnimated_checkmark .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.w4rAnimated_checkmark .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@media only screen and (max-width: 1100px) {
  .burger-menu {
    display: block;
  }
  .main-menu {
    display: none;
  }
  .page-view {
    margin-left: 0px;
    margin-top: 70px;
  }
  .mobile-main-menu {
    display: block;
  }
}
</style>
