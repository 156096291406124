import Vuex from 'vuex';
import Vue from 'vue';
import market from './modules/market'

// Load Vuex
Vue.use(Vuex);

// Create Stoer
export default new Vuex.Store({
    modules: {
        market
    }
});