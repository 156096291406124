<template>
  <div class="login-body">
    <img class="wave" src="../assets/waveblue.png" />
    <div class="container">
      <div class="img">
        <img src="../assets/shopbg.png" />
      </div>
      <div class="login-content">
        <div class="login-form">
          <img src="../assets/avatar.svg" />
          <h3 style="color: #196a9f;margin-top:20px;margin-bottom:40px;" class="title">LOGIN & REGISTER</h3>
          <div v-if="waitingverified == false" :class="uinputClass" class="input-div one">
            <div class="i">
              <img src="../assets/user.svg" />
            </div>
            <div class="div">
              <h5 style="font-family:'Kanit'">หมายเลขโทรศัพท์</h5>
              <input style="color:#333"
                v-model="username"
                @blur="remcl('username')"
                @focus="addcl('username')"
                type="text"
                class="input"
              />
            </div>
          </div>
          <div v-if="waitingverified == true">
            <!--<input style="color:#333"
              v-model="otp"
              @blur="remcl('username')"
              @focus="addcl('username')"
              type="text"
              class="input"
            />-->
            <input ref="otp1" type="tel" maxlength="1" @keypress="isNumber($event,'1')" class="otp" v-model="otp1" placeholder=""><span class="otp-divider">-</span>
            <input ref="otp2" type="tel" maxlength="1" @keypress="isNumber($event,'2')" class="otp" v-model="otp2" placeholder=""><span class="otp-divider">-</span>
            <input ref="otp3" type="tel" maxlength="1" @keypress="isNumber($event,'3')" class="otp" v-model="otp3" placeholder=""><span class="otp-divider">-</span>
            <input ref="otp4" type="tel" maxlength="1" @keypress="isNumber($event,'4')" class="otp" v-model="otp4" placeholder=""><span class="otp-divider">-</span>
            <input ref="otp5" type="tel" maxlength="1" @keypress="isNumber($event,'5')" class="otp" v-model="otp5" placeholder=""><span class="otp-divider">-</span>
            <input ref="otp6" type="tel" maxlength="1" @keypress="isNumber($event,'6')" class="otp" v-model="otp6" placeholder="">
          </div>
          <!-- <div :class="pinputClass" class="input-div pass">
            <div class="i">
              <img src="../assets/lock.svg" />
            </div>
            <div class="div">
              <h5>Password</h5>
              <input
                v-model="userpass"
                @blur="remcl('password')"
                @focus="addcl('password')"
                type="password"
                class="input"
              />
            </div>
          </div> -->
          <!--<a style="font-weight:bold;margin-top:15px;margin-bottom:15px;" href="#">Forgot Password?</a>-->
          <div class="error-msg">{{errorMessage}}</div>
          <input v-if="waitingverified == false"
            style="margin-top: 30px"
            id="login-btt"
            type="submit"
            class="btn"
            value="Login"
          />
          <input v-if="waitingverified == true"
            style="margin-top: 30px; font-family: 'Kanit'"
            id="verify-btt"
            type="submit"
            class="btn"
            value="ยืนยัน OTP"
            v-on:click="verifyOTP()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { backendURL } from "../config";
import firebase from 'firebase';

export default {
  name: "Login",
  components: {},
  mounted (){
    const self = this;
    // Start Firebase invisible reCAPTCHA verifier
    self.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('login-btt', {
      size: 'invisible',
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        if (self.waitingverified == false) {
          self.firebaselogin();
        }
      },
    });
    
    self.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  },
  methods: {
    addcl: function (type) {
      if (type === "username") {
        this.uinputClass = "focus";
      }
      if (type === "password") {
        this.pinputClass = "focus";
      }
    },
    remcl: function (type) {
      if (type === "username") {
        if (this.username == "") {
          this.uinputClass = "";
        }
      }
      if (type === "password") {
        if (this.userpass == "") {
          this.pinputClass = "";
        }
      }
      if (type === "otp") {
        if (this.otp == "") {
          this.otpinputClass = "";
        }
      }
    },
    async firebaselogin(){
      var self = this;
      this.errormsg = '';
      this.errorflag = false;
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(isNaN(this.username)){
        this.errormsg = 'เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น'
        this.errorflag = true
      }else if(this.username.trim().length != 10){
        this.errormsg = 'เบอร์โทรศัพท์ต้องมี 10 หลัก'
        this.errorflag = true
      }
      if (this.errorflag === true) {
        //this.registering = false;
        self.recaptchaVerifier.reset();
      } else {
        var telephone = this.username;
        if (telephone[0] == "0") {
          telephone = "+66" + telephone.substring(1).trim();
        }
        firebase.auth().signInWithPhoneNumber(telephone, this.recaptchaVerifier)
        .then((confirmationResult) => {
          // Signed in
          self.waitingverified = true;
          self.confirmationResult = confirmationResult;
          self.recaptchaVerifier.clear();
        })
        .catch((error) => {
          // var errorCode = error.code;
          var errorMessage = error.message;
          self.errormsg = 'ไม่สามารถส่ง OTP โปรดลองอีกครั้งในภายหลัง';
          self.errorflag = true;
          alert(errorMessage)
        });
      }
    },
    async verifyOTP() {
      let self = this;
      var otpAll = this.otp1 + this.otp2 + this.otp3 + this.otp4 + this.otp5 + this.otp6;
      this.confirmationResult.confirm(otpAll).then((result) => {
        const user = result.user;
        this.userid = user.uid;
        //self.$router.push({ path: "/vendor/dashboard" });
        self.$router.push({ path: "/" });
      }).catch(() => {
        // User couldn't sign in (bad verification code?)
        // ...
        alert('OTP ไม่ถูกต้องโปรดลองอีกครั้ง');
      });
    },
    async login() {
      var params = {
        FCusID: this.username,
        Password: this.userpass,
      };
      const response = await axios.post(`${backendURL}/adminlogin`, params);
      // eslint-disable-next-line
      console.log(response.data);
      if (response.data.accessToken) {
        localStorage.setItem("token", response.data.accessToken);
        var user = {};
        user.FCusID = response.data.user.FCusID;
        user.nickname = response.data.user.FCusNickName;
        user.role = response.data.user.RoleID;
        localStorage.setItem("user", JSON.stringify(user));
        this.$router.push({ path: "/admin/dashboard" });
      } else {
        alert("Wrong username or password");
      }
    },
    isNumber: function(evt,pos) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        if (pos == '1'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp1 = evt.key;
            this.$nextTick(() => this.$refs.otp2.focus());
          }, 100);
        }
        if (pos == '2'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp2 = evt.key;
            this.$nextTick(() => this.$refs.otp3.focus());
          }, 100);
        }
        if (pos == '3'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp3 = evt.key;
            this.$nextTick(() => this.$refs.otp4.focus());
          }, 100);
        }
        if (pos == '4'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp4 = evt.key;
            this.$nextTick(() => this.$refs.otp5.focus());
          }, 100);
        }
        if (pos == '5'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp5 = evt.key;
            this.$nextTick(() => this.$refs.otp6.focus());
          }, 100);
        }
        if (pos == '6'){
          // eslint-disable-next-line
          setTimeout(x => {
            this.otp6 = evt.key;
            //this.verifyOTP();
          }, 100);
        }
        return true;
      }
    },
    ...mapActions([]),
  },
  computed: mapGetters([]),
  created() {},
  data() {
    return {
      uinputClass: "",
      pinputClass: "",
      otpinputClass: "",
      username: "",
      userpass: "",
      waitingverified: false,
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otp5: '',
      otp6: '',
      errorflag: false,
      errorMessage: ''
    };
  },
};
</script>

<style scoped>
.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
}

.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.img img {
  width: 500px;
}

.login-form {
  width: 420px;
}

.login-content img {
  height: 100px;
}

.login-content .input-div img {
  height: 25px;
}

.login-content h2 {
  margin: 15px 0px 20px 0px;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 18px;
  transition: 0.3s;
}

.input-div:before,
.input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #f3155b;
  transition: 0.4s;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50%;
}

.input-div.focus > div > h5 {
  top: -5px;
  font-size: 15px;
}

.input-div.focus > .i > i {
  color: #38d39f;
}

.input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #fff;
  font-family: "poppins", sans-serif;
}

.input-div.pass {
  margin-bottom: 4px;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: #38d39f;
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #447ca1, #29709e, #196a9f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
}
.btn:hover {
  background-position: right;
  color:#92cef6;
}
.error-msg{
  color:red;
  font-family: 'Kanit';
}
.otp{
  width:50px !important;
  height: 50px !important;
  text-align: center;
  display:inline-block !important;
  font-size: 28px !important;
  position: relative !important;
  border: 2px solid #cdcdcd !important;
  border-radius: 5px;
  caret-color: transparent;
  color: #333 !important;
  padding:2px !important;
}
.otp-divider{
  margin-left: 3px;
  margin-right: 3px;
  font-size:28px;
  position: relative;
  top:0px;
}

@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
  .login-form{
    width:100%;
  }
  .otp{
    width:35px !important;
    height:40px !important;
    font-size: 22px !important;
  }
  .otp-divider{
    font-size: 20px;
    top:-4px;
  }
}

@media screen and (max-width: 1000px) {
  form {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
  }
}
</style>