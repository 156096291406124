<template>
  <div class="cart-page">
    <div class="row">
      <div class="col-lg-4 col-md-8 col-sm-12 offset-md-2 offset-lg-4">
        <div class="page-header">
          <div class="page-header-left">Payment</div>
          <div class="page-header-right">ชำระเงิน</div>
        </div>
        <div class="clearfix"></div>
        <div v-if="slipImage == ''" class="cart-wrapper small">
          <div v-if="bank == 'KBANK'" class="bank-logo"><img src="../assets/kbank.png" /></div>
          <div v-if="bank == 'KBANK'" class="bank-name">ธนาคารกสิกรไทย</div>
          <div class="bank-account">{{bankAccount}}</div>
          <div class="bank-payee-name">{{bankPayee}}</div>
        </div>
        <div v-if="slipImage == ''" class="cart-wrapper">
          <div class="cart-header">
            <div class="cart-header-left">
              <div class="cart-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
              </div>
              รายละเอียดการชำระเงิน
            </div>
            <div class="cart-header-right"></div>
            <div class="clearfix"></div>
          </div>
          <div class="cart-grand-total">
            <div class="row">
              <div style="text-align:center;" class="col-12 payment-grand-total">
                {{cartGrandTotal}} บาท
              </div>
            </div>
          </div>
        </div>
        <div v-if="slipImage != ''" class="cart-wrapper small payment-confirm">
          <div v-if="status == 'pending'" v-on:click="uploadSlip()" class="slip"><img :src="slipImage" /></div>
          <div v-else class="w4rAnimated_checkmark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>
            </div>
          <div v-if="status != 'pending'" style="font-size:1rem" class="helptxt">เรากำลังยืนยันการชำระเงินของคุณและจะแจ้งให้คุณทราบในไม่ช้า</div>
        </div>
        <div v-if="slipImage == ''" v-on:click="uploadSlip()" class="pay-btt">อัพโหลดสลิป</div>
        <div v-if="slipImage == ''" class="helptxt">คลิกปุ่มอัปโหลดสลิปเพื่ออัปโหลดหลักฐานการชำระเงิน<br>เพื่อยืนยันการชำระเงินของคุณ</div>
        <input v-on:change="handleSlipUpload()" style="display:none" ref="slipUpload" type="file"/>
        <div v-if="slipImage != '' && status == 'pending'" v-on:click="cancelSlip()" class="pay-btt cancel">ยกเลิก</div>
        <div v-if="slipImage != '' && status == 'pending'" v-on:click="confirmPayment()" class="pay-btt confirm">ยืนยัน</div>
        <div v-if="slipImage != '' && status != 'pending'" v-on:click="finishPayment()" class="pay-btt">ตกลง</div>
        <div v-if="slipImage != '' && status == 'pending'" class="helptxt">กดยืนยันเพื่อชำระเงินหรือกดยกเลิกเพื่ออัปโหลดสลิปใหม่</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import axios from "axios";

export default {
  name: "Payment",
  components: {
  },
  methods: {
    uploadSlip: function () {
      this.$refs.slipUpload.click();  
    },
    handleSlipUpload() {
      this.slipImage = this.$refs.slipUpload.files[0];
      let formData = new FormData();
      formData.append('slip', this.slipImage);
      var headers = {
        'Content-Type': 'multipart/form-data'
      }
      var self = this
      axios({
        method: 'POST',
        url: 'https://api.livetycoon.com/uploadslip',
        headers: headers,
        data: formData
      })
      .then(function (response) {
        self.slipImage = 'https://api.livetycoon.com/static/slip/preview/' + response.data.filename
      })
      .catch(function (error) {
        // eslint-disable-next-line
        console.log(error)
        alert('ไม่สามารถอัปโหลดรูปภาพได้ โปรดลองอีกครั้งในภายหลัง')
      })
    },
    getPaymentDetail: function () {
      var self = this;
      firebase.firestore().collection("checkout").where('invono', '==', this.$route.params.invoice.toString()).get()
      .then((result) => {
          for (var i = 0; i< result.docs.length; i++){
            var item = result.docs[i].data();
            self.invoiceID = result.docs[i].id;
            self.shopid = item.shopid;
            self.cartGrandTotal = item.total;
            self.getBankingDetail();
          }
        })
        .catch(function (error) {
          alert('error')
          //eslint-disable-next-line
          console.log('payment', error)
        });
    },
    getBankingDetail: function () {
      var self = this;
      firebase.firestore().collection("payment").where('shopid', '==', '82').get()
      .then((result) => {
          for (var i = 0; i< result.docs.length; i++){
            var item = result.docs[i].data();
            self.bank = item.bank;
            self.bankAccount = item.account;
            self.bankPayee = item.accountname;
          }
        })
        .catch(function (error) {
          alert('error')
          //eslint-disable-next-line
          console.log('payment', error)
        });
    },
    confirmPayment: function () {
      var self = this;
      firebase.firestore().collection("checkout").doc(this.invoiceID).update({slip: this.slipImage, status: 'Awaiting Approval'})
      .then(() => {
          self.status = 'awaiting'
        })
        .catch(function (error) {
          alert('error')
          //eslint-disable-next-line
          console.log('payment', error)
        });
    },
    cancelSlip: function () {
      this.slipImage = '';
    },
    finishPayment: function () {
      this.$router.replace('/');
    },
    ...mapActions([
      "removefromCart",
      "updateQuantity",
      "loadCart"
    ]),
  },
  computed: {
    ...mapGetters([
      "cart"
    ]),
  },
  mounted() {
    this.getPaymentDetail();
  },
  watch: {
  },
  data() {
    return {
      bank: '',
      shopid: null,
      bankAccount: '',
      bankPayee: '',
      cartGrandTotal: null,
      slipImage: '',
      invoiceID: '',
      status: 'pending'
    };
  },
};
</script>

<style>
  .bank-logo{
    width:100px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
  }
  .bank-logo img{
    width: 100%;
  }
  .bank-account{
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10px;
  }
  .bank-payee-name{
    text-align: center;
    padding-bottom: 25px;
    font-size: 18px;
  }
  .bank-name{
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
  }
  .helptxt{
    margin-top: 15px;
    text-align: center;
    color:#5a5a5a;
  }
  .payment-grand-total{
    color: #196a9f;
    font-weight: bold;
    font-size: 26px;
  }
  .slip img{
    width:100%;
  }
  .pay-btt.cancel{
    width:48%;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #196a9f;
    color: #196a9f;
    box-shadow: inherit;
  }
  .pay-btt.confirm{
    width:48%;
    display: inline-block;
    margin-left: 4%;
  }
  .payment-confirm{
    padding:15px;
  }
  @media only screen and (max-width: 1100px) {

  }
</style>