var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-page"},[_c('AdminMenu',{staticClass:"admin-menu"}),_c('div',{staticClass:"mobile-screen mobile-main-menu"},[_c('font-awesome-icon',{staticClass:"bars-icon",attrs:{"icon":"bars"},on:{"click":function($event){return _vm.toggleMobileMenu()}}}),_c('div',{staticClass:"mobile-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/tylogo.png")}})])],1),_c('font-awesome-icon',{staticClass:"shop-icon",class:_vm.shopMenuState,staticStyle:{"position":"absolute","right":"20px"},attrs:{"icon":"th-large"},on:{"click":function($event){return _vm.triggerShopSidebar()}}})],1),_c('div',{staticClass:"reveal-menu",class:_vm.menuState},[_c('AdminMenu',{staticClass:"mobile-menu"})],1),_c('div',{staticClass:"dashboard-body"},[_vm._m(0),_c('div',{staticClass:"clearfix",staticStyle:{"height":"25px"}}),_c('div',{staticClass:"account-dashboard dashboard-item full"},[_c('div',{staticClass:"item-container table-list"},[_c('div',{staticClass:"content-menu"},[_c('div',{staticClass:"menu-button main",on:{"click":function($event){return _vm.showAddNew()}}},[_c('svg',{staticClass:"feather feather-plus",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"12","y1":"5","x2":"12","y2":"19"}}),_c('line',{attrs:{"x1":"5","y1":"12","x2":"19","y2":"12"}})]),_vm._v(" Add ")])]),_c('div',{staticClass:"dashboard-title"},[_vm._v("Uploads")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
            enabled: true,
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 30,
          },"styleClass":"vgt-table"},on:{"on-cell-click":_vm.selectCell}})],1)])]),_c('sweet-modal',{ref:"userModal",attrs:{"title":_vm.newsModalTitle,"blocking":"false","modal-theme":"light","overlay-theme":"dark"}},[_c('div',{staticClass:"form-input"},[_c('div',{staticClass:"form-label"},[_vm._v("Media")]),_c('input',{staticClass:"form-control",staticStyle:{"height":"46px"},attrs:{"type":"file"}})]),(_vm.operation == 'New')?_c('sweet-button',{attrs:{"slot":"button"},on:{"click":function($event){return _vm.addStaff()}},slot:"button"},[_c('div',{staticClass:"newConfirmBtt"},[_vm._v("Confirm")])]):_vm._e(),(_vm.operation == 'Edit')?_c('sweet-button',{attrs:{"slot":"button"},on:{"click":function($event){return _vm.editNews()}},slot:"button"},[_c('div',{staticClass:"newConfirmBtt"},[_vm._v("Confirm")])]):_vm._e()],1),_c('sweet-modal',{ref:"successNews",attrs:{"modal-theme":"light","overlay-theme":"dark","icon":"success"}},[_c('div',{staticClass:"successLive"},[_vm._v("เพิ่ม Staff ใหม่แล้ว")])]),_c('sweet-modal',{ref:"deleteStaff",attrs:{"modal-theme":"light","overlay-theme":"dark","icon":"success"}},[_c('div',{staticClass:"successLive"},[_vm._v("ลบ Staff แล้ว")])]),_c('div',{staticClass:"clearfix"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-page-title"},[_c('div',{staticClass:"page-title"},[_vm._v("Media Uploads")]),_c('div',{staticClass:"page-subtitle"},[_vm._v("Media Uploads for Live Tycoon")])])
}]

export { render, staticRenderFns }