<template>
  <div class="page-content auth-page">
    <div class="container">
      <div v-on:click="goBack()" class="brand-logo">
        <div class="logo"><img src="../assets/tylogo.png" /></div>
      </div>
      <div v-if="!userInfo.id" v-bind:class="loginClass" class="login-wrapper">
        <div v-if="mode !== 'Forgot' && mode !== 'Resetting'">
          <div class="login-title">{{ mode }}</div>
          <a v-if="mode == 'Login'" href="#" v-on:click="showRegister()"
            ><div class="register-text">ลงทะเบียน</div></a
          >
          <a v-else href="#" v-on:click="showLogin()"
            ><div class="register-text">เข้าสู่ระบบ</div></a
          >
          <div class="login-input">
            <input
              v-on:keyup.enter="doRegisLogin()"
              v-model="username"
              placeholder="อีเมลหรือหมายเลขโทรศัพท์"
            />
          </div>
          <div class="login-input">
            <input
              v-on:keyup.enter="doRegisLogin()"
              v-model="password"
              placeholder="รหัสผ่าน"
              type="password"
            />
          </div>
          <div
            v-if="mode == 'Register'"
            v-on:keyup.enter="doRegisLogin()"
            class="login-input"
          >
            <input
              v-model="confirmpassword"
              placeholder="ยืนยันรหัสผ่าน"
              type="password"
            />
          </div>
          <div
            v-bind:style="processStyle"
            v-on:click="signInCustomer()"
            v-if="mode == 'Login'"
            class="loginbtt"
          >
            LOGIN
          </div>
          <div
            v-bind:style="processStyle"
            v-on:click="registerCustomer()"
            v-if="mode == 'Register'"
            class="loginbtt"
          >
            REGISTER
          </div>
          <img
            v-bind:style="loadingStyle"
            class="loading"
            src="../assets/30.svg"
          />
          <div v-on:click="forgetPassword()" class="forgot-pass">
            Forgot Password
          </div>
          <div v-if="errormsg != ''" class="error-msg">{{ errormsg }}</div>
          <div v-if="successmg != ''" class="success-msg">{{ successmg }}</div>
          <span class="line">
            <h2><span>OR</span></h2>
          </span>
          <div
            v-on:click="socialSignIn('facebook')"
            style="margin-right: 3%"
            class="signin-block"
          >
            <img src="../assets/facebookicon.png" />
            <div style="width: 100%; text-align: center">Facebook</div>
            <div style="clear: both" class="clearfix"></div>
          </div>
          <div v-on:click="socialSignIn('google')" class="signin-block">
            <img src="../assets/googleicon.jpg" />
            <div style="width: 100%; text-align: center">Google</div>
            <div style="clear: both" class="clearfix"></div>
          </div>
        </div>
        <div v-if="mode == 'Forgot'">
          <div class="login-title">ลืมรหัสผ่าน</div>
          <a href="#" v-on:click="backtoLogin()"
            ><div class="register-text">กลับสู่การเข้าสู่ระบบ</div></a
          >
          <div v-if="otpsend == false" class="login-input">
            <input v-model="optemail" placeholder="อีเมลหรือหมายเลขโทรศัพท์" />
          </div>
          <div
            v-if="otpsend == false && otpsendwarning != ''"
            class="otpsendwarning"
          >
            {{ otpsendwarning }}
          </div>
          <div v-if="otpsend == false" class="forgot-msg">
            โปรดใส่ที่อยู่อีเมลหรือหมายเลขโทรศัพท์ของคุณเพื่อให้เราสามารถส่งรหัส
            OTP เพื่อรีเซ็ตรหัสผ่านของคุณ
          </div>
          <div
            v-if="otpsend == false"
            v-bind:style="processStyle"
            v-on:click="sendOTPcode()"
            class="loginbtt"
          >
            ยืนยัน
          </div>
          <div v-if="otpsend == true" class="otpcode-wrapper">
            <div class="login-input otpcode">
              <input
                ref="otp1"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(1)"
                v-model="optcode1"
                placeholder=""
              />
            </div>
            <div class="otpcode-sep">-</div>
            <div class="login-input otpcode">
              <input
                ref="otp2"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(2)"
                v-model="optcode2"
                placeholder=""
              />
            </div>
            <div class="otpcode-sep">-</div>
            <div class="login-input otpcode">
              <input
                ref="otp3"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(3)"
                v-model="optcode3"
                placeholder=""
              />
            </div>
            <div class="otpcode-sep">-</div>
            <div class="login-input otpcode">
              <input
                ref="otp4"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(4)"
                v-model="optcode4"
                placeholder=""
              />
            </div>
            <div class="otpcode-sep">-</div>
            <div class="login-input otpcode">
              <input
                ref="otp5"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(5)"
                v-model="optcode5"
                placeholder=""
              />
            </div>
            <div class="otpcode-sep">-</div>
            <div class="login-input otpcode">
              <input
                ref="otp6"
                pattern="\d*"
                maxlength="1"
                v-on:keyup="setNextNumber(6)"
                v-model="optcode6"
                placeholder=""
              />
            </div>
          </div>
          <div
            v-if="otpsend == true"
            style="text-align: center"
            class="forgot-msg"
          >
            รหัส OTP ถูกส่งไปยังอีเมลหรือหมายเลขโทรศัพท์ของคุณ
          </div>
          <div
            v-if="otpsend == true"
            style="text-align: center"
            class="forgot-msg"
          >
            <a href="#">ส่งรหัส OTP อีกครั้ง</a>
          </div>
          <div
            v-if="otpsend == true"
            v-bind:style="processStyle"
            v-on:click="confirmOTPcode()"
            class="loginbtt"
          >
            ยืนยัน
          </div>
          <div
            v-if="otpsend == true && otpsendwarning != ''"
            class="otpsendwarning"
          >
            {{ otpsendwarning }}
          </div>
        </div>
        <div v-if="mode == 'Resetting'">
          <div class="login-title">รีเซ็ตรหัสผ่านของคุณ</div>
          <a href="#" v-on:click="backtoLogin()"
            ><div class="register-text">กลับสู่การเข้าสู่ระบบ</div></a
          >
          <div class="login-input">
            <input
              type="password"
              v-model="resetpassword"
              placeholder="รหัสผ่านใหม่ของคุณ"
            />
          </div>
          <div class="login-input">
            <input
              type="password"
              v-model="resetpasswordconfirm"
              placeholder="ยืนยันรหัสผ่านใหม่ของคุณ"
            />
          </div>
          <div v-on:click="changeNewPassword()" class="loginbtt">ยืนยัน</div>
          <div v-if="otpsendwarning != ''" class="otpsendwarning">
            {{ otpsendwarning }}
          </div>
        </div>
      </div>
      <div v-else class="login-wrapper">
        <div style="width: 100%" class="login-title">ชื่อแชท</div>
        <div class="login-input">
          <input
            style="text-align: center"
            v-model="chatname"
            placeholder="ชื่อแชทของคุณ"
          />
        </div>
        <div v-on:click="closeModal()" class="loginbtt">ยืนยัน</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { backendURL } from "../config";

export default {
  components: {},
  data() {
    return {
      mode: "Login",
      username: "",
      password: "",
      confirmpassword: "",
      errormsg: "",
      successmg: "",
      loginClass: "",
      chatname: "",
      processStyle: "display:block",
      loadingStyle: "display:none",
      userID: "",
      socialemail: "",
      optemail: "",
      otpsend: false,
      otpsendwarning: "",
      optcode1: "",
      optcode2: "",
      optcode3: "",
      optcode4: "",
      optcode5: "",
      optcode6: "",
      OTPtries: 3,
      resetpassword: "",
      resetpasswordconfirm: "",
    };
  },
  computed: mapGetters(["userInfo"]),
  mounted() {},
  methods: {
    ...mapActions(["setUserLogin"]),
    reset() {
      this.mode = "Login";
      this.username = "";
      this.password = "";
      this.confirmpassword = "";
      this.errormsg = "";
      this.loginClass = "";
    },
    forgetPassword() {
      this.mode = "Forgot";
      this.otpsend = false;
      this.otpsendwarning = "";
      this.OTPtries = 3;
      this.optemail = "";
      this.resetpassword = "";
      this.resetpasswordconfirm = "";
      this.optcode1 = "";
      this.optcode2 = "";
      this.optcode3 = "";
      this.optcode4 = "";
      this.optcode5 = "";
      this.optcode6 = "";
    },
    showRegister() {
      this.mode = "Register";
    },
    showLogin() {
      this.mode = "Login";
    },
    backtoLogin() {
      this.mode = "Login";
    },
    goBack() {
      if (this.$route.query.shop) {
        this.$router.replace({
          name: "Shop",
          params: { id: this.$route.query.shop },
        });
      } else {
        this.$router.replace({ name: "Market" });
      }
    },
    socialSignIn(network) {
      var self = this;
      this.hello(network)
        .login({ scope: "email" })
        .then(() => {
          //const authRes = this.hello(network).getAuthResponse();
          /*
          performs operations using the token from authRes
        */
          // eslint-disable-next-line
          //console.log(authRes)
          this.hello(network)
            .api("me")
            .then(function (json) {
              const profile = json;
              // eslint-disable-next-line
              //console.log(profile)
              var email = profile.email;
              self.socialemail = email;
              var newuser = {};
              newuser.FCusID = "";
              newuser.FCusName = "";
              newuser.FCusNickName = "";
              newuser.RoleID = 2;
              newuser.Password = "G2b3:p@).CpZ@~4b";
              newuser.Email = email;
              newuser.Mobile = "";
              newuser.network = network;
              self.processStyle = "display:none";
              self.loadingStyle = "display:block";

              axios
                .post(`${backendURL}/sociallogin`, newuser)
                .then((response) => {
                  // eslint-disable-next-line
                  // console.log(response);
                  //self.processStyle = 'display:block';
                  //self.loadingStyle = 'display:none';
                  var userInfo = { id: response.data.FCusID };
                  self.userID = response.data.FCusID;
                  self.setUserLogin(userInfo);
                  self.getUserChatName(response.data.FCusID);
                })
                .catch((error) => {
                  // eslint-disable-next-line
                  console.error(error);
                  if (error.response.status == 409) {
                    // self.autoSocialLogin();
                  }
                  self.processStyle = "display:block";
                  self.loadingStyle = "display:none";
                });
              /*
            performs operations using the user info from profile
          */
            });
        });
    },
    doRegisLogin() {
      if (this.mode == "Register") {
        this.registerCustomer();
      } else {
        this.signInCustomer();
      }
    },
    signInCustomer() {
      var self = this;
      var user = {};
      user.FCusID = this.username.trim();
      user.Password = this.password.trim();
      this.processStyle = "display:none";
      this.loadingStyle = "display:block";

      if (this.username.trim() != "" && this.password.trim() != "") {
        axios
          .post(`${backendURL}/login`, user)
          .then((response) => {
            // eslint-disable-next-line
            console.log(response);
            if (response.data == "Password does not match") {
              self.processStyle = "display:block";
              self.loadingStyle = "display:none";
              self.loginClass = "error-shake";
              self.errormsg = "อีเมลหรือหมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง";
              setTimeout(function () {
                self.loginClass = "";
              }, 2000);
            } else {
              var userInfo = { id: response.data.FCusID };
              self.userID = response.data.FCusID;
              self.setUserLogin(userInfo);
              self.getUserChatName(response.data.FCusID);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
            self.processStyle = "display:block";
            self.loadingStyle = "display:none";
            if (error.response.status == 409) {
              self.loginClass = "error-shake";
              self.errormsg =
                "อีเมลหรือหมายเลขโทรศัพท์ที่ลงทะเบียนแล้วโปรดใช้อีเมลหรือหมายเลขโทรศัพท์อื่น";
              setTimeout(function () {
                self.loginClass = "";
              }, 2000);
            }
          });
      } else {
        self.processStyle = "display:block";
        self.loadingStyle = "display:none";
        self.loginClass = "error-shake";
        self.errormsg = "อีเมลหรือหมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง";
        setTimeout(function () {
          self.loginClass = "";
        }, 2000);
      }
    },
    registerCustomer() {
      var self = this;
      var mobile = "";
      var email = "";
      if (this.username.trim() != "" && this.password.trim() != "") {
        var numbers = /^[0-9]+$/;
        if (this.username.match(numbers)) {
          if (this.username.length == 10) {
            mobile = this.username;
          } else {
            this.loginClass = "error-shake";
            this.errormsg = "โปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง";
            setTimeout(function () {
              self.loginClass = "";
            }, 2000);
          }
        } else {
          // eslint-disable-next-line
          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(String(this.username).toLowerCase())) {
            email = this.username;
          } else {
            this.loginClass = "error-shake";
            this.errormsg = "กรุณาใส่อีเมล์ที่ถูกต้อง";
            setTimeout(function () {
              self.loginClass = "";
            }, 2000);
            self.processStyle = "display:block";
            self.loadingStyle = "display:none";
          }
        }
        if (mobile != "" || email != "") {
          if (this.password === this.confirmpassword) {
            var newuser = {};
            newuser.FCusID = "";
            newuser.FCusName = "";
            newuser.FCusNickName = "";
            newuser.RoleID = 2;
            newuser.Password = this.password;
            newuser.Email = email;
            newuser.Mobile = mobile;
            this.processStyle = "display:none";
            this.loadingStyle = "display:block";

            axios
              .post(`${backendURL}/adduser`, newuser)
              .then((response) => {
                // eslint-disable-next-line
                // console.log(response);
                //self.processStyle = 'display:block';
                //self.loadingStyle = 'display:none';
                var userInfo = { id: response.data.FCusID };
                self.userID = response.data.FCusID;
                self.setUserLogin(userInfo);
                self.getUserChatName(response.data.FCusID);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
                if (error.response.status == 409) {
                  self.loginClass = "error-shake";
                  self.errormsg =
                    "อีเมลหรือหมายเลขโทรศัพท์ที่ลงทะเบียนแล้วโปรดใช้อีเมลหรือหมายเลขโทรศัพท์อื่น";
                  setTimeout(function () {
                    self.loginClass = "";
                  }, 2000);
                }
                self.processStyle = "display:block";
                self.loadingStyle = "display:none";
              });
          } else {
            this.loginClass = "error-shake";
            this.errormsg = "รหัสผ่านไม่ตรงกันโปรดตรวจสอบและลองอีกครั้ง";
            setTimeout(function () {
              self.loginClass = "";
            }, 2000);
          }
        }
      } else {
        this.loginClass = "error-shake";
        this.errormsg = "ผู้ใช้และรหัสผ่านต้องไม่เว้นว่าง";
        setTimeout(function () {
          self.loginClass = "";
        }, 2000);
      }
    },
    async getUserChatName(userid) {
      const response = await axios.get(`${backendURL}/getuser/` + userid);
      self.processStyle = "display:block";
      self.loadingStyle = "display:none";
      if (
        response.data[0].FCusNickName == "" ||
        response.data[0].FCusNickName == null
      ) {
        this.chatname = "";
      } else {
        this.chatname = response.data[0].FCusNickName;
      }
      var usersetting = { id: userid, color: "f3155b", name: this.chatname };
      localStorage.setItem("userSetting", JSON.stringify(usersetting));
    },
    async sendOTPcode() {
      var params = {
        email: this.optemail,
      };
      const response = await axios.post(`${backendURL}/sendforgotOTP`, params);
      if (response.data == "OTP has been sent") {
        this.otpsend = true;
        this.otpsendwarning = "";
      } else if (response.data == "Email not found") {
        this.otpsendwarning = "เราไม่พบอีเมลบัญชีหรือหมายเลขโทรศัพท์ของคุณ";
      } else {
        this.otpsendwarning =
          "ไม่สามารถส่งรหัส OTP ได้โปรดลองอีกครั้งในภายหลัง";
      }
    },
    async confirmOTPcode() {
      var params = {
        email: this.optemail,
        otp:
          this.optcode1 +
          this.optcode2 +
          this.optcode3 +
          this.optcode4 +
          this.optcode5 +
          this.optcode6,
      };
      const response = await axios.post(`${backendURL}/validateOTP`, params);
      if (response.data == "OTP Match") {
        this.otpsendwarning = "";
        this.mode = "Resetting";
      } else if (response.data == "OTP does not match") {
        this.OTPtries = this.OTPtries - 1;
        if (this.OTPtries > 0) {
          this.otpsendwarning =
            "รหัส OTP ของคุณไม่ถูกต้อง คุณสามารถลองได้อีก " +
            this.OTPtries +
            " ครั้ง";
        } else {
          location.reload();
        }
      } else {
        this.otpsendwarning =
          "ไม่สามารถส่งรหัส OTP ได้โปรดลองอีกครั้งในภายหลัง";
      }
    },
    setNextNumber(order) {
      if (order == 1) {
        this.$refs.otp2.focus();
      }
      if (order == 2) {
        this.$refs.otp3.focus();
      }
      if (order == 3) {
        this.$refs.otp4.focus();
      }
      if (order == 4) {
        this.$refs.otp5.focus();
      }
      if (order == 5) {
        this.$refs.otp6.focus();
      }
    },
    async changeNewPassword() {
      var params = {
        email: this.optemail,
        otp:
          this.optcode1 +
          this.optcode2 +
          this.optcode3 +
          this.optcode4 +
          this.optcode5 +
          this.optcode6,
        password: this.resetpasswordconfirm,
      };
      if (this.resetpassword == this.resetpasswordconfirm) {
        const response = await axios.post(
          `${backendURL}/resetPassword`,
          params
        );
        if (response.data == "Password Updated") {
          this.backtoLogin();
          this.successmg = "รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว";
        } else {
          this.otpsendwarning =
            "ไม่สามารถเปลี่ยนรหัสผ่าน ได้โปรดลองอีกครั้งในภายหลัง";
        }
      } else {
        this.otpsendwarning =
          "รหัสผ่านไม่ตรงกัน. โปรดตรวจสอบแล้วลองใหม่อีกครั้ง";
      }
    },
    closeModal() {
      if (this.chatname.trim() !== "") {
        var selectedcolor = ((Math.random() * 0xffffff) << 0).toString(16);
        var usersetting = {
          id: this.userID,
          color: selectedcolor,
          name: this.chatname,
        };
        localStorage.setItem("userSetting", JSON.stringify(usersetting));
        var user = {};
        user.FCusID = this.userID;
        user.FCusNickName = this.chatname;
        axios
          .post(`${backendURL}/updateuser`, user)
          .then({})
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        //this.$emit('close')
        this.goBack();
      } else {
        alert("ชื่อแชทต้องไม่เว้นว่าง");
      }
    },
  },
};
</script>

<style scoped>
.page-content {
}
.brand-logo {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  cursor: pointer;
}
.login-wrapper {
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  overflow: hidden;
  width: 31.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 80px;
  padding: 30px;
  font-family: "Kanit";
}
.login-title {
  display: inline-block;
  width: 48%;
  font-weight: 400;
  font-size: 20px;
  text-align: left;
  color: #333;
}
.register-text {
  display: inline-block;
  width: 50%;
  text-align: right;
  color: rgba(243, 21, 91, 1);
  margin-top: -15px;
}
.register-text:hover {
  text-decoration: underline;
}
.login-input {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.02);
  margin-top: 25px;
}
.login-input input {
  padding: 12px;
  border: 0px;
  width: 100%;
  font-family: "Kanit";
}
.loginbtt {
  width: 100%;
  text-align: center;
  background-color: rgba(243, 21, 91, 1);
  border: 1px solid rgba(243, 21, 91, 1);
  color: white;
  padding: 10px;
  margin-top: 40px;
  cursor: pointer;
  letter-spacing: 1px;
}
.loginbtt:hover {
  background-color: rgb(189, 13, 69);
}
.forgot-pass {
  margin-top: 25px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  color: #333;
}
.signin-block {
  width: 48%;
  background-color: #1877f2;
  color: #fff;
  padding: 8px 8px;
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
}
.signin-block img {
  width: 25px;
  height: 25px;
  float: left;
}
.signin-block:hover {
  background-color: rgb(99, 158, 254);
}
.line {
  display: block;
  margin: 25px 0px;
}
.line h2 {
  font-size: 15px;
  color: #ccc;
  text-align: center;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.line h2 span {
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}
.error-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.error-msg,
.success-msg {
  width: 100%;
  text-align: center;
  color: red;
  margin-top: 30px;
}
.success-msg {
  color: #57ac00;
}
.loading {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.forgot-pass {
  color: rgba(243, 21, 91, 1);
  cursor: pointer;
}
.forgot-pass:hover {
  color: rgba(243, 21, 91, 1);
  text-decoration: underline;
}
.forgot-msg {
  margin-top: 15px;
  color: #8c8c8c;
}
.forgot-msg a {
  color: rgba(243, 21, 91, 1);
}
.otpcode {
  width: 12%;
  display: inline-block;
}
.otpcode input {
  text-align: center;
}
.otpcode-sep {
  display: inline-block;
  margin-right: 1.8%;
  margin-left: 1.8%;
  position: relative;
  top: -20px;
}
.otpsendwarning {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  color: #ff0000;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@media only screen and (max-width: 1100px) {
  .auth-page .brand-logo .logo {
    display: block;
  }
  .auth-page .brand-logo {
    margin-top: 0px;
  }
}
@media only screen and (min-device-width: 501px) and (max-device-width: 900px) and (orientation: portrait) {
  .page-content {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .login-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 900px) and (orientation: landscape) {
  .page-content {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .login-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 500px) and (orientation: portrait) {
  .page-content {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .login-wrapper {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }
  .register-text {
    width: 49%;
  }
  .signin-block {
    width: 48.5%;
  }
}
</style>
