import axios from "axios";
import { backendURL } from "../../config";

const state = {
  backend: false,
  shops: [],
  cart: [],
  postPage: 0,
  searchPage: 0,
  menuClass: "menu-close",
  shopMenuClass: "hide-menu",
  shopSideBarClass: "hide-shopside-menu",
  postCategory: "0",
  searchKeyword: "",
  shopInfo: {},
  userInfo: {},
  unreadchats: 0,
  market: "all",
  mainmenus: [
    {
      id: 0,
      thtxt: "ทั้งหมด",
      engtxt: "All Categories",
      status: "",
    },
    {
      id: 1,
      thtxt: "แฟชั่นผู้หญิง",
      engtxt: "Women",
      status: "",
    },
    {
      id: 2,
      thtxt: "แฟชั่นผู้ชาย",
      engtxt: "Men",
      status: "",
    },
    {
      id: 3,
      thtxt: "เด็ก",
      engtxt: "Kids",
      status: "",
    },
    {
      id: 4,
      thtxt: "เครื่องประดับ",
      engtxt: "Accessories",
      status: "",
    },
    {
      id: 5,
      thtxt: "อุปกรณ์ อิเล็กทรอนิกส์",
      engtxt: "Electornics",
      status: "",
    },
    {
      id: 6,
      thtxt: "อุปกรณ์ ตกแต่งรถยนต์",
      engtxt: "Auto Accessories",
      status: "",
    },
    {
      id: 7,
      thtxt: "สุขภาพและความงาม",
      engtxt: "Health and Beauty",
      status: "",
    },
    {
      id: 8,
      thtxt: "อาหาร",
      engtxt: "Food",
      status: "",
    },
    {
      id: 9,
      thtxt: "ขนมหวาน",
      engtxt: "Dessert",
      status: "",
    },
    {
      id: 10,
      thtxt: "เครื่องดื่ม",
      engtxt: "Drinks",
      status: "",
    },
    {
      id: 11,
      thtxt: "สัตว์เลี้ยง",
      engtxt: "Pets",
      status: "",
    },
    {
      id: 12,
      thtxt: "อื่น ๆ",
      engtxt: "Others",
      status: "",
    },
  ],
  featured: [],
  /*featured: [
        {
            id:1,
            image: 'https://placeimg.com/200/200/any?1',
            title: 'Omelete with Fresh Cream',
            category: 'Breakfast'
        },
        {
            id:2,
            image: 'https://placeimg.com/200/200/any?2',
            title: 'French Toast with Butter',
            category: 'French Breakfast'
        },
        {
            id:3,
            image: 'https://placeimg.com/200/200/any?3',
            title: 'Hazelnut spread with Cookies',
            category: 'Dessert'
        },
        {
            id:4,
            image: 'https://placeimg.com/200/200/any?4',
            title: 'Omelete with Fresh Cream',
            category: 'Breakfast'
        },
        {
            id:5,
            image: 'https://placeimg.com/200/200/any?5',
            title: 'Buttermilk Pancake with Strawberry',
            category: 'Dessert'
        },
        {
            id:6,
            image: 'https://placeimg.com/200/200/any?6',
            title: 'Rice Porridge with Fresh Egg',
            category: 'Asian Breakfast'
        },
    ],*/
  posts: [],
  shopposts: [],
  shopcategories: [],
  mockposts: [
    {
      id: 1,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_103332683-558x372.jpg",
      title: "Omelete with Fresh Cream",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Breakfast",
      postdate: "03 May",
      likes: "2345",
    },
    {
      id: 2,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_106533694-248x372.jpg",
      title: "French Toast with Butter",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "French Breakfast",
      postdate: "01 May",
      likes: "1861",
    },
    {
      id: 3,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_111625455-558x372.jpg",
      title: "Hazelnut spread with Cookies",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "Dessert",
      postdate: "08 May",
      likes: "267",
    },
    {
      id: 4,
      image: "https://placeimg.com/200/200/any?4",
      title: "Omelete with Fresh Cream",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Breakfast",
      postdate: "04 May",
      likes: "2401",
    },
    {
      id: 5,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_120593843-558x372.jpg",
      title: "Buttermilk Pancake with Strawberry",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Dessert",
      postdate: "12 May",
      likes: "18",
    },
    {
      id: 6,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_121817902-558x372.jpg",
      title: "Rice Porridge with Fresh Egg",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "Asian Breakfast",
      postdate: "18 May",
      likes: "9897",
    },
    {
      id: 7,
      image: "https://placeimg.com/200/200/any?7",
      title: "Cream cheese Chicken",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Breakfast",
      postdate: "11 May",
      likes: "369",
    },
    {
      id: 8,
      image: "https://placeimg.com/200/200/any?8",
      title: "Basil chicken with Rice",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Lunch",
      postdate: "23 May",
      likes: "89",
    },
    {
      id: 9,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_124533179-248x372.jpg",
      title: "Hainesse Rich with Fried Chicken",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "Lunch",
      postdate: "26 May",
      likes: "187",
    },
    {
      id: 10,
      image: "https://placeimg.com/200/200/any?10",
      title: "Green Curry with Bread",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Breakfast",
      postdate: "19 May",
      likes: "1487",
    },
    {
      id: 11,
      image: "https://placeimg.com/200/200/any?11",
      title: "Earl Grey Tea with Cookies",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Tea & Snacks",
      postdate: "30 May",
      likes: "867",
    },
  ],
  postsmore: [
    {
      id: 12,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_103332683-558x372.jpg",
      title: "Omelete with Fresh Cream",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "accessories",
      postdate: "03 May",
      likes: "2345",
    },
    {
      id: 13,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_106533694-248x372.jpg",
      title: "French Toast with Butter",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "French Breakfast",
      postdate: "01 May",
      likes: "1861",
    },
    {
      id: 14,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/05/AdobeStock_111625455-558x372.jpg",
      title: "Hazelnut spread with Cookies",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "accessories",
      postdate: "08 May",
      likes: "267",
    },
    {
      id: 15,
      image: "https://placeimg.com/200/200/any?4",
      title: "Omelete with Fresh Cream",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Breakfast",
      postdate: "04 May",
      likes: "2401",
    },
    {
      id: 16,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_120593843-558x372.jpg",
      title: "Buttermilk Pancake with Strawberry",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "accessories",
      postdate: "12 May",
      likes: "18",
    },
    {
      id: 17,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_121817902-558x372.jpg",
      title: "Rice Porridge with Fresh Egg",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "Asian Breakfast",
      postdate: "18 May",
      likes: "9897",
    },
    {
      id: 18,
      image: "https://placeimg.com/200/200/any?7",
      title: "Cream cheese Chicken",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "accessories",
      postdate: "11 May",
      likes: "369",
    },
    {
      id: 19,
      image: "https://placeimg.com/200/200/any?8",
      title: "Basil chicken with Rice",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Lunch",
      postdate: "23 May",
      likes: "89",
    },
    {
      id: 20,
      image:
        "http://pluto.pinsupreme.com/wp-content/uploads/2014/04/AdobeStock_124533179-248x372.jpg",
      title: "Hainesse Rich with Fried Chicken",
      desc:
        "Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience....",
      category: "accessories",
      postdate: "26 May",
      likes: "187",
    },
    {
      id: 21,
      image: "https://placeimg.com/200/200/any?10",
      title: "Green Curry with Bread",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "accessories",
      postdate: "19 May",
      likes: "1487",
    },
    {
      id: 22,
      image: "https://placeimg.com/200/200/any?11",
      title: "Earl Grey Tea with Cookies",
      desc:
        "Star girl moved from back beauty project and the of tending the if form sub taken and came together there...",
      category: "Tea & Snacks",
      postdate: "30 May",
      likes: "867",
    },
  ],
};

const getters = {
  allShops: (state) => state.shops,
  allMenus: (state) => state.mainmenus,
  allFeatured: (state) => state.featured,
  allPosts: (state) => state.posts,
  allShopPosts: (state) => state.shopposts,
  allMorePosts: (state) => state.postsmore,
  postCategory: (state) => state.postCategory,
  allShopCategories: (state) => state.shopcategories,
  menuState: (state) => state.menuClass,
  shopMenuState: (state) => state.shopMenuClass,
  shopSideBarState: (state) => state.shopSideBarClass,
  shopInfo: (state) => state.shopInfo,
  userInfo: (state) => state.userInfo,
  backendState: (state) => state.backend,
  unreadChatCount: (state) => state.unreadchats,
  cart: (state) => state.cart,
  market: (state) => state.market,
};

const actions = {
  async fetchShops({ commit }) {
    const response = await axios.get(
      "https://jsonplaceholder.typicode.com/todos"
    );
    commit("setShops", response.data);
  },
  async getFeaturedStream({ commit }) {
    const response = await axios.get(`${backendURL}/getlivestream`);
    commit("setFeaturedStreaming", response.data);
  },
  async getAllPosts({ commit }) {
    const response = await axios.get(
      `${backendURL}/getshoppostopenp/` + state.postPage + '/' + state.market
    );
    // eslint-disable-next-line
    //console.log(response.data.results)
    let orilink = "";
    let previewlink = "";
    for (var i = 0; i < response.data.results.length; i++) {
      orilink = response.data.results[i].Banner.split("images/");
      previewlink = orilink[0] + "images/preview/" + orilink[1];
      response.data.results[i].Preview = previewlink;
      if(response.data.results[i].FCusID.startsWith("C")){
        response.data.results[i].location = "สายใต้เซ็นเตอร์";
      } else {
        response.data.results[i].location = "ตลาดพระอิน";
      }
    }
    commit("setPosts", response.data.results);
  },
  async setPostFilter({ commit }, category) {
    commit("setPostCategory", category);
    commit("setMarketNextPage", 0);
    var response = null;
    var mainmenu = state.mainmenus;
    for (var i = 0; i < mainmenu.length; i++) {
      if (mainmenu[i].id == category) {
        mainmenu[i].status = "active";
      } else {
        mainmenu[i].status = "";
      }
    }
    commit("setMainMenu", mainmenu);
    let orilink = "";
    let previewlink = "";
    if (category == 0) {
      response = await axios.get(`${backendURL}/getshoppostopenp/0`+"/"+state.market);
      for (i = 0; i < response.data.results.length; i++) {
        orilink = response.data.results[i].Banner.split("images/");
        previewlink = orilink[0] + "images/preview/" + orilink[1];
        response.data.results[i].Preview = previewlink;
        if(response.data.results[i].FCusID.startsWith("C")){
          response.data.results[i].location = "สายใต้เซ็นเตอร์";
        } else {
          response.data.results[i].location = "ตลาดพระอิน";
        }
      }
      commit("setPosts", response.data.results);
    } else {
      //response = await axios.get('https://api.livetycoon.com/getshoppostbycategory/'+category);
      //commit('setPosts', response.data);
      var params = {
        CategoryID: category,
        market:state.market,
        page: 0,
      };
      const response = await axios.post(
        `${backendURL}/getshoppostbycategoryp`,
        params
      );
      for (i = 0; i < response.data.results.length; i++) {
        orilink = response.data.results[i].Banner.split("images/");
        previewlink = orilink[0] + "images/preview/" + orilink[1];
        response.data.results[i].Preview = previewlink;
        if(response.data.results[i].FCusID.startsWith("C")){
          response.data.results[i].location = "สายใต้เซ็นเตอร์";
        } else {
          response.data.results[i].location = "ตลาดพระอิน";
        }
      }
      commit("setPosts", response.data.results);
    }
  },
  async getAllShopPosts({ commit }, shopid) {
    var params = {
      id: shopid,
      page: 0,
    };
    const response = await axios.post(
      `${backendURL}/getshoppostopenbyshopp/`,
      params
    );
    var responseArray = response.data.results;
    let orilink = "";
    let previewlink = "";
    for (var i = 0; i < responseArray.length; i++) {
      responseArray[i].isRotate = false;
      responseArray[i].chats = [];
      orilink = response.data.results[i].Banner.split("images/");
      previewlink = orilink[0] + "images/preview/" + orilink[1];
      responseArray[i].Preview = previewlink;
      responseArray[i].amount = 0;
    }
    commit("setShopPosts", responseArray);
  },
  async getAllShopCategory({ commit }, shopid) {
    const response = await axios.get(
      `${backendURL}/getshoppostcategorybyshopid/${shopid}`
    );
    var responseArray = response.data;
    for (var i = 0; i < responseArray.length; i++) {
      responseArray[i].status = "";
    }
    var allCat = { CategoryID: "0", status: "active" };
    responseArray.unshift(allCat);
    commit("setShopCategories", responseArray);
  },
  async getAllShopPostsByCategory({ commit }, params) {
    const response = await axios.post(
      `${backendURL}/getshoppostbycategoryandshopid/`,
      params
    );
    var responseArray = response.data;
    commit("setShopPosts", responseArray);
  },
  async getShopInfo({ commit }, shopid) {
    const response = await axios.get(`${backendURL}/getshop/${shopid}`);
    // eslint-disable-next-line
    // console.log(response.data);
    /* for (var result in response.data[0]) {
            if (result.Facebook) {
                if (result.Facebook.includes("facebook.com")) {
                    // eslint-disable-next-line
                    console.log(result.Facebook)
                } else {
                    result.Facebook = 'https://www.facebook.com/'+ result.Facebook
                    // eslint-disable-next-line
                    console.log(result.Facebook)
                }
            }
        } */
    commit("setShopInfo", response.data);
  },
  async searchPostAllShop({ commit }, search) {
    commit("setSearchKeyword", search);
    commit("setSearchNextPage", 0);
    var params = {
      text: search,
      page: 0,
    };
    const response = await axios.post(`${backendURL}/searchshoppostp/`, params);
    let orilink = "";
    let previewlink = "";
    for (var i = 0; i < response.data.results.length; i++) {
      orilink = response.data.results[i].Banner.split("images/");
      previewlink = orilink[0] + "images/preview/" + orilink[1];
      response.data.results[i].Preview = previewlink;
      if(response.data.results[i].FCusID.startsWith("C")){
        response.data.results[i].location = "สายใต้เซ็นเตอร์";
      } else {
        response.data.results[i].location = "ตลาดพระอิน";
      }
    }
    commit("setPosts", response.data.results);
  },
  async getMorePost({ commit }) {
    var i = 0;
    let orilink = "";
    let previewlink = "";
    if (state.postCategory == 0) {
      var page = parseInt(state.postPage + 1);
      const response = await axios.get(
        `${backendURL}/getshoppostopenp/${page}`+"/"+state.market
      );
      if (response.data.results.length > 0) {
        commit("setMarketNextPage", parseInt(state.postPage) + 1);
      }
      //alert(page)
      // eslint-disable-next-line
      //console.log(response.data.results)
      for (i = 0; i < response.data.results.length; i++) {
        orilink = response.data.results[i].Banner.split("images/");
        previewlink = orilink[0] + "images/preview/" + orilink[1];
        response.data.results[i].Preview = previewlink;
        if(response.data.results[i].FCusID.startsWith("C")){
          response.data.results[i].location = "สายใต้เซ็นเตอร์";
        } else {
          response.data.results[i].location = "ตลาดพระอิน";
        }
        commit("addMorePost", response.data.results[i]);
      }
    } else {
      var params = {
        CategoryID: state.postCategory,
        page: parseInt(state.postPage + 1),
        market: state.market
      };
      // alert(parseInt(state.postPage+1))
      const response = await axios.post(
        `${backendURL}/getshoppostbycategoryp`,
        params
      );
      // eslint-disable-next-line
      // console.log(response)
      if (response.data.results.length > 0) {
        commit("setMarketNextPage", parseInt(state.postPage) + 1);
      }
      for (i = 0; i < response.data.results.length; i++) {
        orilink = response.data.results[i].Banner.split("images/");
        previewlink = orilink[0] + "images/preview/" + orilink[1];
        response.data.results[i].Preview = previewlink;
        if(response.data.results[i].FCusID.startsWith("C")){
          response.data.results[i].location = "สายใต้เซ็นเตอร์";
        } else {
          response.data.results[i].location = "ตลาดพระอิน";
        }
        commit("addMorePost", response.data.results[i]);
      }
    }
    // eslint-disable-next-line
    //console.log(response.data.results)
  },
  async addMoreSearch({ commit }) {
    commit("setSearchNextPage", parseInt(state.postPage) + 1);
    var params = {
      text: state.searchKeyword,
      page: state.searchPage,
    };
    const response = await axios.post(`${backendURL}/searchshoppostp/`, params);
    for (var i = 0; i < response.data.results.length; i++) {
      commit("addMoreSearch", response.data.results[i]);
    }
  },
  /*infiniteHandler({commit}) {
        for (var i=0; i < state.postsmore.length; i++){
            if(state.postsmore[i].category == state.postCategory){
                commit('addMorePost', state.postsmore[i]);
            }
        }
    },*/
  updateAllPosts({ commit }, updatePosts) {
    // eslint-disable-next-line
    // console.log(allPosts)
    commit("setShopPosts", updatePosts);
  },
  setMenuClass({ commit }, setClass) {
    commit("setMenuClass", setClass);
  },
  // eslint-disable-next-line
  changeMarket({ commit }, market) {
    commit("setMarket", market);
  },
  // eslint-disable-next-line
  resetPage({ commit }, page) {
    commit("setMarketNextPage", page);
  },
  setShopMenuClass({ commit }, setClass) {
    commit("setShopMenuClass", setClass);
  },
  setSideBarMenuClass({ commit }, setClass) {
    commit("setSideBarMenuClass", setClass);
  },
  setBackend({ commit }, setStatus) {
    commit("setBackendStatus", setStatus);
  },
  setPostActiveCategory({ commit }, categoryIndex) {
    var categoryArray = state.shopcategories;
    for (var i = 0; i < categoryArray.length; i++) {
      categoryArray[i].status = "";
    }
    categoryArray[categoryIndex].status = "active";
    commit("setShopCategoryActive", categoryArray);
  },
  setUserLogin({ commit }, userInfo) {
    commit("setUserInfo", userInfo);
  },
  setUnreadChat({ commit }, unreadchats) {
    commit("setUnread", unreadchats);
  },
  addtoCart({ commit }, cart) {
    commit("addNewCart", cart);
  },
  updateQuantity({ commit }, cart) {
    commit("updateQuantityCart", cart);
  },
  removefromCart({ commit }, cart) {
    commit("removeItemCart", cart);
  },
  loadCart({ commit }, cart) {
    commit("loadtoCart", cart);
  },
  logOutUser({ commit }) {
    localStorage.removeItem("userSetting");
    commit("setUserInfo", "");
  },
};

const mutations = {
  setShops: (state, shop) => (state.shops = shop),
  setFeaturedStreaming: (state, featured) => (state.featured = featured),
  setPosts: (state, post) => (state.posts = post),
  setShopPosts: (state, post) => (state.shopposts = post),
  setMenuClass: (state, menuClass) => (state.menuClass = menuClass),
  setShopMenuClass: (state, menuClass) => (state.shopMenuClass = menuClass),
  setSideBarMenuClass: (state, menuClass) =>
    (state.shopSideBarClass = menuClass),
  setMarket: (state, market) => (state.market = market),
  setMarketNextPage: (state, postPage) => (state.postPage = postPage),
  setSearchNextPage: (state, searchPage) => (state.searchPage = searchPage),
  addMorePost: (state, posts) => state.posts.push(posts),
  addMoreSearch: (state, posts) => state.posts.push(posts),
  // setPostFilter: (state, category) => (state.posts = state.posts.filter(it => it.CategoryID == category)),
  setPostCategory: (state, category) => (state.postCategory = category),
  setMainMenu: (state, mainmenu) => (state.mainmenus = mainmenu),
  setShopCategoryActive: (state, categoryArray) =>
    (state.shopcategories = categoryArray),
  setShopCategories: (state, category) => (state.shopcategories = category),
  setSearchKeyword: (state, keyword) => (state.searchKeyword = keyword),
  setShopInfo: (state, info) => (state.shopInfo = info),
  setBackendStatus: (state, status) => (state.backend = status),
  setUserInfo: (state, userInfo) => (state.userInfo = userInfo),
  setUnread: (state, unreadchats) => (state.unreadchats = unreadchats),
  addNewCart (state, cart) { 
    let pos = state.cart.map(function(e) { return e.shopid; }).indexOf(cart.shopid);
    if(pos !== -1){
      let productpos = state.cart[pos].product.map(function(e) { return e.pid; }).indexOf(cart.pid);
      if(productpos !== -1){
        state.cart[pos].product[productpos].amount = state.cart[pos].product[productpos].amount + 1;
      } else {
        let item = {
          pid: cart.pid,
          amount: 1
        }
        state.cart[pos].product.push(item)
      }
    } else{
      let item = {
        shopid: cart.shopid,
        product: [
          {
            pid:cart.pid,
            amount: 1
          }
        ]
      }
      state.cart.push(item)
    }
    localStorage.setItem('cart', JSON.stringify(state.cart)); 
  },
  updateQuantityCart (state,cart){
    let pos = state.cart.map(function(e) { return e.shopid; }).indexOf(cart.shopid);
    if(pos !== -1){
      let productpos = state.cart[pos].product.map(function(e) { return e.pid; }).indexOf(cart.pid);
      if(productpos !== -1){
        state.cart[pos].product[productpos].amount = parseInt(cart.amount);
      } else {
        let item = {
          pid: cart.pid,
          amount: 1
        }
        state.cart[pos].product.push(item)
      }
    } else{
      let item = {
        shopid: cart.shopid,
        product: [
          {
            pid:cart.pid,
            amount: 1
          }
        ]
      }
      state.cart.push(item)
    }
    localStorage.setItem('cart', JSON.stringify(state.cart)); 
  },
  removeItemCart (state, cart) { 
    /*for (var i=0; i<state.cart.length; i++) {
      if (state.cart[i].pid == cart.pid) {
        state.cart.splice(i, 1);
      }
    }*/
    let pos = state.cart.map(function(e) { return e.shopid; }).indexOf(cart.shopid);
    let productpos = state.cart[pos].product.map(function(e) { return e.pid; }).indexOf(cart.pid);
    state.cart[pos].product.splice(productpos, 1);
    localStorage.setItem('cart', JSON.stringify(state.cart)); 
  },
  loadtoCart: (state, cart) => (state.cart = cart),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
