<template>
  <div style="height:100%" class="video-player">
	<video :src="videoURL+'#t=0.5'" id="videoPlayer" controlsList="nodownload" preload="metadata" oncontextmenu="return false;" style="width:100%;height:100%" controls class="view--video__video"></video>
  </div>
</template>

<script>
export default {
	name: "VideoPlayer",
	props: ['videoURL'],
    components:{
    },
    methods:{
    },
    created() {
      /*var xhr = new XMLHttpRequest()
      xhr.open('GET', this.videoURL)
      xhr.responseType = 'arraybuffer'
      
      xhr.onload = function () {
        var videoBlob = new Blob([xhr.response], { 'type': 'video/mp4;' })
        var videoPlayer = document.getElementById('videoPlayer')
        videoPlayer.src = URL.createObjectURL(videoBlob)+'#t=0.5'
      }

      xhr.send()*/
    },
    data() {
      return {
      }
    },
}
</script>

<style scoped>
</style>