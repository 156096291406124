<template>
  <div class="markets front-page">
    <Featured></Featured>
    <div class="market-post-filter">
      <!-- <div class="filter-group">
          <div class="filter-item filter-label">เรียงตาม:</div>
          <div class="filter-item filter-button">ชมมากที่สุด</div>
          <div class="filter-item filter-button">สถานะออนไลน์</div>
        </div>-->
      <div style="padding-left: 0px" class="filter-group">
        <div class="filter-item filter-label">ตลาด</div>
        <b-form-select @change="selectMarket()" v-model="market" class="category-select" name="province">
          <!--<option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
          <option value="กระบี่">กระบี่ </option>
          <option value="กาญจนบุรี">กาญจนบุรี </option>
          <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
          <option value="กำแพงเพชร">กำแพงเพชร </option>
          <option value="ขอนแก่น">ขอนแก่น</option>
          <option value="จันทบุรี">จันทบุรี</option>
          <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
          <option value="ชัยนาท">ชัยนาท </option>
          <option value="ชัยภูมิ">ชัยภูมิ </option>
          <option value="ชุมพร">ชุมพร </option>
          <option value="ชลบุรี">ชลบุรี </option>
          <option value="เชียงใหม่">เชียงใหม่ </option>
          <option value="เชียงราย">เชียงราย </option>
          <option value="ตรัง">ตรัง </option>
          <option value="ตราด">ตราด </option>
          <option value="ตาก">ตาก </option>
          <option value="นครนายก">นครนายก </option>
          <option value="นครปฐม">นครปฐม </option>
          <option value="นครพนม">นครพนม </option>
          <option value="นครราชสีมา">นครราชสีมา </option>
          <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
          <option value="นครสวรรค์">นครสวรรค์ </option>
          <option value="นราธิวาส">นราธิวาส </option>
          <option value="น่าน">น่าน </option>
          <option value="นนทบุรี">นนทบุรี </option>
          <option value="บึงกาฬ">บึงกาฬ</option>
          <option value="บุรีรัมย์">บุรีรัมย์</option>
          <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
          <option value="ปทุมธานี">ปทุมธานี </option>
          <option value="ปราจีนบุรี">ปราจีนบุรี </option>
          <option value="ปัตตานี">ปัตตานี </option>
          <option value="พะเยา">พะเยา </option>
          <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
          <option value="พังงา">พังงา </option>
          <option value="พิจิตร">พิจิตร </option>
          <option value="พิษณุโลก">พิษณุโลก </option>
          <option value="เพชรบุรี">เพชรบุรี </option>
          <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
          <option value="แพร่">แพร่ </option>
          <option value="พัทลุง">พัทลุง </option>
          <option value="ภูเก็ต">ภูเก็ต </option>
          <option value="มหาสารคาม">มหาสารคาม </option>
          <option value="มุกดาหาร">มุกดาหาร </option>
          <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
          <option value="ยโสธร">ยโสธร </option>
          <option value="ยะลา">ยะลา </option>
          <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
          <option value="ระนอง">ระนอง </option>
          <option value="ระยอง">ระยอง </option>
          <option value="ราชบุรี">ราชบุรี</option>
          <option value="ลพบุรี">ลพบุรี </option>
          <option value="ลำปาง">ลำปาง </option>
          <option value="ลำพูน">ลำพูน </option>
          <option value="เลย">เลย </option>
          <option value="ศรีสะเกษ">ศรีสะเกษ</option>
          <option value="สกลนคร">สกลนคร</option>
          <option value="สงขลา">สงขลา </option>
          <option value="สมุทรสาคร">สมุทรสาคร </option>
          <option value="สมุทรปราการ">สมุทรปราการ </option>
          <option value="สมุทรสงคราม">สมุทรสงคราม </option>
          <option value="สระแก้ว">สระแก้ว </option>
          <option value="สระบุรี">สระบุรี </option>
          <option value="สิงห์บุรี">สิงห์บุรี </option>
          <option value="สุโขทัย">สุโขทัย </option>
          <option value="สุพรรณบุรี">สุพรรณบุรี </option>
          <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
          <option value="สุรินทร์">สุรินทร์ </option>
          <option value="สตูล">สตูล </option>
          <option value="หนองคาย">หนองคาย </option>
          <option value="หนองบัวลำภู">หนองบัวลำภู </option>
          <option value="อำนาจเจริญ">อำนาจเจริญ </option>
          <option value="อุดรธานี">อุดรธานี </option>
          <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
          <option value="อุทัยธานี">อุทัยธานี </option>
          <option value="อุบลราชธานี">อุบลราชธานี</option>
          <option value="อ่างทอง">อ่างทอง </option>
          <option value="อื่นๆ">อื่นๆ</option>-->
          <option value="all">ทั้งหมด</option>
          <option value="saitai">สายใต้เซ็นเตอร์</option>
          <option value="prain">ตลาดพระอิน</option>
        </b-form-select>
      </div>
      <div style="padding-left: 0px" class="filter-group">
        <div class="filter-item filter-label">หมวดหมู่</div>
        <b-form-select
          @change="setPostFilter(selectedCategory)"
          class="category-select"
          v-model="selected"
          :options="options"
        ></b-form-select>
      </div>
    </div>
    <!-- <div v-for="shop in allShops" :key="shop.id" class="shop">
          {{shop.title}}
      </div> -->
    <masonry
      class="post-container"
      :cols="{ default: 4, 1600: 3, 1000: 4, 900: 3, 700: 2, 400: 2 }"
      :gutter="{ default: '25px', 1300: '10px', 1200: '10px', 700: '0px', 400: '5px' }"
    >
      <div v-for="post in allPosts" :key="post.id">
        <div class="post-wrapper">
          <div class="post-top-section">
            <router-link :to="{ name: 'Shop', params: { id: post.ShopID } }">
              <!-- <div
                class="shop-logo"
                v-bind:style="{ 'background-image': 'url(' + post.Logo + ')' }"
              ></div>
              <div v-if="post.Name" class="shop-name">{{ post.Name }}</div>
              <div v-else class="shop-name">-</div>
              <div class="post-date">
                <font-awesome-icon class="post-icon" :icon="['far', 'clock']" />
                <span>{{
                  post.updated_at | moment("subtract", "3 hours", "MMMM Do")
                }}</span>
              </div>
              <div class="clearfix"></div>-->
              <div
                v-if="post.Banner.split('/')[4] == 'images'"
                class="post-image"
              >
                <img v-bind:src="post.Preview" />
                <div class="post-view">
                  <font-awesome-icon class="view-icon" icon="eye" />
                </div>
              </div>
              <div
                v-if="post.Banner.split('/')[4] == 'videos'"
                class="post-video"
              >
                <!-- <video oncontextmenu="return false;" style="width:100%;" controls class="view--video__video">
                                <source :src="post.Banner" type="video/mp4">
                            </video> -->
                <VideoPlayer :videoURL="post.Banner"></VideoPlayer>
              </div>
              <div
                v-if="post.Banner.split('/')[4] == 'gifs'"
                class="post-video"
              >
                <video
                  oncontextmenu="return false;"
                  style="width: 100%"
                  loop
                  muted
                  autoplay
                  :src="post.Banner"
                  class="view--video__video"
                >
                  <source :src="post.Banner" type="video/mp4" />
                </video>
                <!-- <GIFPlayer :videoURL='post.Banner'></GIFPlayer>-->
              </div>
              </router-link>
              <!-- <div class="post-sku">{{ post.SKU }}</div> -->
                            <div
                v-on:click="showCategory(2)"
                v-if="post.CategoryID == 2"
                class="footer-category"
              >
                แฟชั่นผู้ชาย
              </div>
              <div
                v-on:click="showCategory(1)"
                v-if="post.CategoryID == 1"
                class="footer-category"
              >
                แฟชั่นผู้หญิง
              </div>
              <div
                v-on:click="showCategory(3)"
                v-if="post.CategoryID == 3"
                class="footer-category"
              >
                เด็ก
              </div>
              <div
                v-on:click="showCategory(4)"
                v-if="post.CategoryID == 4"
                class="footer-category"
              >
                เครื่องประดับ
              </div>
              <div
                v-on:click="showCategory(5)"
                v-if="post.CategoryID == 5"
                class="footer-category"
              >
                อิเล็กทรอนิกส์
              </div>
              <div
                v-on:click="showCategory(6)"
                v-if="post.CategoryID == 6"
                class="footer-category"
              >
                ประดับรถยนต์
              </div>
              <div
                v-on:click="showCategory(7)"
                v-if="post.CategoryID == 7"
                class="footer-category"
              >
                สุขภาพความงาม
              </div>
              <div
                v-on:click="showCategory(8)"
                v-if="post.CategoryID == 8"
                class="footer-category"
              >
                อาหาร
              </div>
              <div
                v-on:click="showCategory(9)"
                v-if="post.CategoryID == 9"
                class="footer-category"
              >
                ขนมหวาน
              </div>
              <div
                v-on:click="showCategory(10)"
                v-if="post.CategoryID == 10"
                class="footer-category"
              >
                เครื่องดื่ม
              </div>
              <div
                v-on:click="showCategory(11)"
                v-if="post.CategoryID == 11"
                class="footer-category"
              >
                สัตว์เลี้ยง
              </div>
              <div
                v-on:click="showCategory(12)"
                v-if="post.CategoryID == 12"
                class="footer-category"
              >
                อื่น ๆ
              </div>
              <!--<div
                 v-if="Number(post.PriceTill).toLocaleString() !== '0' && post.PriceFrom != post.PriceTill"
                 style="float: right"
                 class="footer-item footer-date"
               >
                 <svg
                   style="margin-right: 10px; margin-top: -3px"
                   xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="#ec7465"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="feather feather-shopping-bag"
                 >
                   <path
                     d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                   ></path>
                   <line x1="3" y1="6" x2="21" y2="6"></line>
                   <path d="M16 10a4 4 0 0 1-8 0"></path>
                 </svg>
                 <span
                   >{{ Number(post.PriceFrom).toLocaleString() }} -
                   {{ Number(post.PriceTill).toLocaleString() }} บาท</span
                 >
               </div>
               <div
                 v-else
                 style="float: right"
                 class="footer-item footer-date"
               >
                <div v-if="Number(post.PriceFrom) != 0">
                 <svg
                   style="margin-right: 10px; margin-top: -3px"
                   xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="#ec7465"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="feather feather-shopping-bag"
                 >
                   <path
                     d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                   ></path>
                   <line x1="3" y1="6" x2="21" y2="6"></line>
                   <path d="M16 10a4 4 0 0 1-8 0"></path>
                 </svg>
                 <span
                   >{{ Number(post.PriceFrom).toLocaleString() }} บาท</span
                 >
                </div>
               </div>-->
                <div
                 style="float: right"
                 class="footer-item footer-date footer-price"
                >
                  <div v-if="Number(post.PriceTill) != 0">
                    <svg
                      style="margin-right: 10px; margin-top: -3px"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ec7465"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path
                        d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                      ></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span
                      >{{ Number(post.PriceTill).toLocaleString() }} บาท</span
                    >
                  </div>
                </div>
              <div class="clearfix"></div>
              <div v-if="post.Title != ''" class="post-title">{{ post.Title }}</div>
              <div v-if="post.Description != ''" class="post-desc short">{{ post.Description }}</div>
              <div
                 class="footer-item footer-date footer-price-mobile"
                >
                  <div v-if="Number(post.PriceTill) != 0">
                    <svg
                      style="margin-right: 10px; margin-top: -3px"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ec7465"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path
                        d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                      ></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span
                      >{{ Number(post.PriceTill).toLocaleString() }} บาท</span
                    >
                  </div>
                </div>
              <div class="clearfix"></div>
          </div>
          <!-- <div v-on:click="genPreview(post.Preview)" style="cursor:pointer;background-color:yellow;width:100%;text-align:center;padding:10px;" class="fixbtt">Fix Image</div>-->
          <div class="post-bottom-section">
            <!-- <router-link :to="{ name: 'Shop', params: { id: post.ShopID } }">
              <div class="read-more">ชมร้านค้า</div>
            </router-link>-->
            <div class="post-footer">
              <div class="post-shop-location">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                {{post.location}}
              </div>
              <!--<div
                v-if="Number(post.PriceTill).toLocaleString() !== '0'"
                style="float: right; margin-top: 1px"
                class="footer-item footer-date"
              >
                <svg
                  style="margin-right: 10px; margin-top: -3px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ec7465"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shopping-bag"
                >
                  <path
                    d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                  ></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <span
                  >{{ Number(post.PriceFrom).toLocaleString() }} -
                  {{ Number(post.PriceTill).toLocaleString() }} บาท</span
                >
              </div>
              <div
                v-else
                style="float: right; margin-top: 1px"
                class="footer-item footer-date"
              >
                <svg
                  style="margin-right: 10px; margin-top: -3px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ec7465"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shopping-bag"
                >
                  <path
                    d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                  ></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <span>{{ Number(post.PriceFrom).toLocaleString() }} บาท</span>
              </div>-->
              <router-link :to="{ name: 'Shop', params: { id: post.ShopID } }">
                <div class="view-wrapper">
                  <svg style="margin-right:5px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#428bca" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                  ดูร้าน
                </div>
              </router-link>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <infinite-loading
        :style="loaderStyle"
        ref="infiniteloader"
        @infinite="getMorePost"
      ></infinite-loading>
    </masonry>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Featured from "@/components/Featured";
import VideoPlayer from "@/components/VideoPlayer";
// import GIFPlayer from '@/components/GifPlayer'
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";
import { backendURL } from "../config";

export default {
  name: "Market",
  components: {
    Featured,
    InfiniteLoading,
    VideoPlayer,
    // GIFPlayer
  },
  methods: {
    showCategory: function (catid) {
      this.setPostFilter(catid);
      this.$router.push({ path: `/category/${catid}` });
    },
    async genPreview(imgurl) {
      var imgname = imgurl.split("/");
      var params = {
        imgurl: imgname[imgname.length - 1],
      };
      const response = await axios.post(`${backendURL}/genpreview/`, params);
      alert(response.data);
    },
    selectMarket(){
      //this.setPostFilter(0);
      this.changeMarket(this.market);
      this.resetPage(0);
      if(this.selected == 0) {
        this.getAllPosts();
      } else {
        this.setPostFilter(this.selected)
      }
    },
    ...mapActions([
      "fetchShops",
      "getAllPosts",
      "setShopMenuClass",
      "changeMarket",
      "resetPage",
      "getMorePost",
      "setPostFilter",
      "setMarketFilter",
    ]),
  },
  computed: {
    selected: {
      // getter
      get: function () {
        return this.postCategory;
      },
      // setter
      set: function (newValue) {
        this.selectedCategory = newValue;
      },
    },
    ...mapGetters([
      "allShops",
      "allPosts",
      "allMorePosts",
      "shopMenuState",
      "postCategory",
    ]),
  },
  created() {
    //this.fetchShops();
    this.getAllPosts();
    this.setShopMenuClass("hide-menu");
    //this.setPostFilter(0);
  },
  watch: {
    allPosts() {
      var remainder = this.allPosts.length % 30;
      if (remainder != 0) {
        this.$refs.infiniteloader.stateChanger.complete();
        this.loaderStyle = "display:none";
      } else {
        this.$refs.infiniteloader.stateChanger.loaded();
        this.loaderStyle = "display:block";
      }
    },
  },
  data() {
    return {
      selectedCategory: 0,
      loaderStyle: "",
      market:"all",
      options: [
        { value: "0", text: "ทั้งหมด (All Categories)" },
        { value: "2", text: "แฟชั่นผู้ชาย (Men)" },
        { value: "1", text: "แฟชั่นผู้หญิง (Women)" },
        { value: "3", text: "เด็ก (Kids)" },
        { value: "4", text: "เครื่องประดับ (Accessories)" },
        { value: "5", text: "อุปกรณ์ อิเล็กทรอนิกส์ (Electronics)" },
        { value: "6", text: "อุปกรณ์ ตกแต่งรถยนต์ (Auto Accessories)" },
        { value: "7", text: "สุขภาพและความงาม (Beauty & Health)" },
        { value: "8", text: "อาหาร (Food)" },
        { value: "9", text: "ขนมหวาน (Dessert)" },
        { value: "10", text: "เครื่องดื่ม (Beverage)" },
        { value: "11", text: "สัตว์เลี้ยง (Pets)" },
        { value: "12", text: "อื่น ๆ (Others)" },
      ],
      province: 'กรุงเทพมหานคร'
    };
  },
};
</script>

<style>
.front-page {
  /*padding-left: 20px;*/
}
.filter-item {
  display: inline-block;
  color: #111;
  margin-right: 10px;
}
.filter-button,
.category-select {
  /*border: 1px solid transparent;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  -moz-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  transition: all 0.3s ease;
  border-radius: 6px;
  padding: 4px 10px;
  color: black;
  */
  margin-right: 20px;
  display: inline-block;
  font-weight: 700;
  background-color: rgba(0,0,0,0.05) !important;
  box-shadow: none;
  border-radius: 4px;
  position: relative;
  min-width: 120px;
  font-weight: 500 !important;
  font-size: 15px !important;
  font-family: "Kanit";
}
.filter-button:hover {
  background-color: rgba(243, 21, 91, 1);
  color: #fff;
  cursor: pointer;
}
.market-post-filter {
  width: 100%;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  overflow: hidden;
  color: #111;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  border-top: 1px solid rgba(0,0,0,0.2);
  background-color: #fff;
  margin-top:10px;
}
.market-post-filter .filter-group {
  display: inline-block;
  padding: 15px 20px 15px 20px;
  vertical-align: middle;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 50px;
}
.market-post-filter .filter-group:first-child{
  margin-left: 3%;
}
.filter-label {
  font-size: 13px;
  font-weight: 500;
}
.post-container {
  width: 98%;
  margin-left: auto !important;
  margin-right: auto;
  padding-top: 20px;
  overflow: hidden;
}
.post-wrapper {
  /*border: 1px solid transparent;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  -moz-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  transition: all 0.3s ease;
  margin-bottom: 30px;
  border-radius: 12px;*/
  margin-bottom: 30px;
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: "Kanit";
}
.post-wrapper .post-image {
  /*margin-top: 15px;*/
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}
.post-wrapper .post-video {
  margin-bottom: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}
.post-wrapper .post-image img {
  width: 100%;
  border-radius: 5px;
  transition: all 0.6s ease;
}
.post-wrapper .post-image:hover img {
  transform: scale(1.1);
}
.post-wrapper:hover {
  /*transform: translateY(-7px);
  -webkit-box-shadow: 0px 15px 5px 0px rgba(17, 76, 124, 0.82);
  -moz-box-shadow: 0px 15px 5px 0px rgba(17, 76, 124, 0.82);
  box-shadow: 0px 15px 5px 0px rgba(17, 76, 124, 0.82);*/
  -webkit-box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  -o-transform: translateY(-8px);
  transform: translateY(-8px);
}
.shop-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  float: left;
  background-color: #c3c3c3;
}
.shop-name {
  float: left;
  margin-top: -4.5px;
  margin-left: 10px;
  color: #333;
  width: 70%;
}
.post-title {
  font-weight: 500;
  color: #2c2d2f;
  font-size: 20px;
  line-height: 32px;
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}
.post-desc {
  font-size: 14px;
  color: #5c5c5c;
  font-weight: 400;
  line-height: 1.428571429;
  white-space: pre-wrap;
}
.post-desc.short {
  overflow-wrap: break-word;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.post-view {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    transparent,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.7)
  );
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: all 0.3s ease;
}
.post-view svg {
  left: 44%;
  top: 45%;
  position: relative;
  width: 45px;
  height: 45px;
  font-size: 30px;
  color: white;
  transition: all 0.3s ease;
}
.post-wrapper .post-image:hover .post-view {
  opacity: 1;
}
.post-wrapper .post-image:hover svg {
  transform: translateY(-20px);
}
.read-more {
  margin-top: 15px;
  font-weight: 700;
  display: inline-block;
  color: white;
  background-color: #f3155b;
  padding: 10px;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  text-align: center;
}
.post-top-section{
  padding:15px;
  padding-bottom: 0px;
}
.post-bottom-section{
  background-color: #f1f6f8;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid #dfe4e7;
  margin-top: 10px;
}
.post-footer {
}
.footer-item {
  float: left;
}
.footer-category {
  /*display: block;
  padding: 2px 5px;
  background-color: #2863ec;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
  border: 1px solid #2863ec;
  border-radius: 4px;
  transition: all 0.2s ease;*/
  display: block;
  padding: 2px 5px;
  background-color: #d4edfc;
  font-size: 11px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-bottom: 10px;
  float:left;
}
.footer-category:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #5987f0;
}
.footer-date {
  margin-left: 0px;
  font-size: 13px;
  color: #333;
}
.post-icon {
  margin-right: 2px;
}
.heart-icon {
  margin-right: 2px;
  color: #ec7465;
}
.post-date {
  font-size: 14px;
  color: #c3c3c3;
  float: left;
  margin-left: 10px;
}
.chat-icon {
  position: relative;
  top: -4px;
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
}
.post-sku {
  font-size: 12px;
  color: #f3155b;
  margin-bottom: 5px;
}
.view-wrapper {
  padding: 12px 10px;
  padding-bottom: 13px;
  float: right;
  border-left: 1px solid #dfe4e7;
  background-color: #d2ecf6;
  border-radius: 0px 0px 6px 0px;
  color: #464646;
  font-size: 12px;
  cursor: pointer;
}
.post-shop-location {
  float: left;
  color: hsl(198, 27%, 32%);
  font-size: 12px;
  margin-top: 13px;
  margin-left: 15px;
}
.footer-price{
  display:block;
}
.footer-price-mobile{
  display:none;
}
.clearfix{
  clear:both;
}
@media only screen and (min-width: 351px) and (max-width: 1100px) {
  .post-container {
    width: 100%;
    margin-left: -5px !important;
    margin-top: 25px;
  }
  .market-post-filter {
    margin-top:15px;
  }
  .post-top-section{
    padding:10px;
    padding-bottom:0px
  }
  .front-page {
    padding-left: 0px;
    padding-right: 0px;
  }
  .post-wrapper{
    margin-bottom: 10px;
  }
  .view-wrapper{
    display: none;
  }
  .footer-category{
    margin-bottom:13px;
    margin-left: 0px !important;
  }
  .post-shop-location{
    margin-left: 10px;
    margin-top:10px;
    margin-bottom:10px;
  }
  .market-post-filter .filter-group:first-child{
    margin-left: 1%;
  }
  .market-post-filter .filter-group{
    margin-left: 25px;
    padding-bottom: 5px !important;
    width:100%;
    margin-left: 10px;
  }
  .market-post-filter{
    padding-bottom: 10px;
  }
  .filter-label {
    width:55px;
  }
  .market-post-filter .filter-group:first-child{
    margin-left: 10px;
  }
  .market-post-filter .category-select{
    width:80%;
  }
  .post-title{
    margin-bottom: 5px;
  }
  .footer-price{
    display:none;
  }
  .post-title{
    font-size: 18px;
  }
  .footer-price-mobile{
    display:block;
    margin-top:10px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 700px) {
  .post-container {
    margin-left: 1px !important;
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .market-post-filter .filter-group{
    width: 48.4%;
  }
  .market-post-filter .filter-group:last-child{
    padding-right: 0px;
  }
  .post-container{
    margin-right: 5px;
    margin-left: 5px !important;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  .market-post-filter .filter-group{
    width: 49%;
  }
  .market-post-filter .filter-group:last-child{
    padding-right: 0px;
  }
  .post-container{
    margin-right: 5px;
    margin-left: 5px !important;
  }
}
</style>