<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">Orders</div>
        <div class="page-subtitle">ออเดอร์ลูกค้า</div>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div class="order-dashboard">
        <div class="order-header-wrapper">
            <div class="order-header">
              <div class="header-products">สินค้าทั้งหมด</div>
              <div class="header-total">ยอดคำสั่งซื้อทั้งหมด</div>
              <div class="header-delivery">ที่อยู่การจัดส่ง</div>
              <div class="header-status">สถานะ</div>
            </div>
          </div>
          <div class="order-table" v-if="!noResult">
            <div v-for="orderitem in orderList" v-bind:key="orderitem.invono" class="cart-wrapper cart-row">
              <div class="cart-header">
                <div class="cart-header-left">
                  <a :href="'/shop/'+orderitem.shopid">
                    <div class="cart-icon shop">
                      <!--<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>-->
                      <div v-bind:style="{ backgroundImage: 'url(' + orderitem.shopLogo + ')' }" class="shop-logo"></div>
                    </div>
                    <span style="float:left;margin-top:9px;color:#333;">{{orderitem.shopName}}</span>
                  </a>
                </div>
                <div class="invoice-no">หมายเลขคำสั่งซื้อ <span style="color:#3893c1">{{orderitem.invono}}</span></div>
                <!--<div class="cart-header-right"><a @click="$router.go(-1)"><div class="backbtt">ย้อนกลับ</div></a></div>-->
                <div class="clearfix"></div>
              </div>
              <div class="cart-detail">
                <div class="cart-item-wrapper">
                  <div class="order-items">
                    <div v-for="item in orderitem.products" v-bind:key="item.pid" class="cart-item">
                      <div v-on:click="showShop(item.shopid)" class="item-image" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                      <div class="item-detail">
                        <div class="item-title">{{item.title}}</div>
                        <div class="item-desc">{{item.descripton}}</div>
                        <div class="item-category">{{item.category}}</div>
                        <div class="clearfix"></div>
                        <div class="item-location">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                          กรุงเทพฯ-สายใต้เซ็นเตอร์
                        </div>
                      </div>
                      <div class="item-amount">
                        x{{item.amount}}
                      </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                    <div class="item-total">
                      ฿{{orderitem.total}}
                    </div>
                    <div class="item-delivery">
                      {{orderitem.delivery.name}}<br>
                      {{orderitem.delivery.address}}<br>
                      {{orderitem.delivery.phone}}
                    </div>
                    <div class="item-status">
                      <div v-if="orderitem.status == 'Pending'" class="pending"><img style="margin-right:5px;" src="../assets/payment.png" />รอการชำระเงิน</div>
                      <div style="margin-bottom:10px;" v-if="orderitem.status == 'Awaiting Approval'" class="awaiting"><img style="margin-right:5px;" src="../assets/approving.png" />รอการยืนยัน</div>
                      <a style="margin-top:20px;color:#3893c1;text-decoration:underline" :href="orderitem.slip" target="_blank" v-if="orderitem.status == 'Awaiting Approval'" class="awaiting">ดูสลิปการชำระเงิน</a>
                      <div  v-if="orderitem.status == 'Awaiting Approval'" class="approval-action">
                        <div v-on:click="approveOrder(orderitem.id)" class="approve">ยืนยัน</div>
                        <div v-on:click="askRejectReason(orderitem.id)" class="reject">ไม่อนุมัติ</div>
                      </div>
                      <div v-if="orderitem.status == 'Rejected'" class="rejected"><img style="margin-right:5px;" src="../assets/rejected.png" />คำสั่งซื้อถูกยกเลิก</div>
                      <div v-if="orderitem.status == 'Rejected'" class="rejected-reason">เหตุผล: {{orderitem.reason}}</div>
                      <div v-if="orderitem.status == 'Approved'" class="approved"><img style="margin-right:5px;" src="../assets/approved.png" />ชำระเงินสำเร็จ</div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="order-no-result">
              <img src="../assets/noorder.png" />
              <div class="no-result-txt">ยังไม่มีคำสั่งซื้อ</div>
            </div>
      </div>
    </div>
    <sweet-modal
      style="font-family:'Kanit'"
      title="ไม่อนุมัติ"
      :blocking="false"
      ref="rejectModal"
      modal-theme="light"
      overlay-theme="dark"
    >
      <div class="form-input">
        <div class="form-label">เหตุผล</div>
        <input v-model="rejectReason" class="form-control" />
      </div>
      <sweet-button
        v-on:click="rejectOrder()"
        slot="button"
        ><div class="add-btt">ตกลง</div></sweet-button
      >
    </sweet-modal>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
import { SweetModal } from "sweet-modal-vue";
import firebase from 'firebase';
import axios from "axios";
import { backendURL } from "../config";
export default {
  name: "VendorOrders",
  components: {
    VendorMenu,
    SweetModal
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    async getShopOrders(){
      var self = this;
      this.orderList = [];
      this.shopID = "356";
      firebase.firestore().collection("checkout").where('shopid', '==', this.shopID).orderBy('created', 'desc').get()
      .then((result) => {
          for (var i = 0; i< result.docs.length; i++){
            self.getShopInfo(result.docs[i].data(),result.docs[i].id);
          }
          if (result.docs.length < 1) {
            self.noResult = true;
          }
        })
        .catch(function (error) {
          //eslint-disable-next-line
          console.log('orders', error)
          self.noResult = true;
        });
    },
    async getShopInfo(order,id){
      const response = await axios.get(`${backendURL}/getshop/${order.shopid}`);
      //eslint-disable-next-line
      console.log('shop', response)
      order.shopName = response.data.Name;
      order.shopLogo = response.data.Logo;
      order.id = id;
      this.orderList.push(order)
    },
    askRejectReason(id) {
      this.rejectID = id;
      this.$refs.rejectModal.open();
    },
    rejectOrder(){
      var self = this;
      firebase.firestore().collection("checkout").doc(this.rejectID).update({reason: this.rejectReason, status: 'Rejected'})
      .then(() => {
          this.$refs.rejectModal.close();
          self.getShopOrders();
        })
        .catch(function (error) {
          alert('error')
          //eslint-disable-next-line
          console.log('payment', error)
        });
    },
    approveOrder(id){
      var self = this;
      firebase.firestore().collection("checkout").doc(id).update({status: 'Approved'})
      .then(() => {
          self.getShopOrders();
        })
        .catch(function (error) {
          alert('error')
          //eslint-disable-next-line
          console.log('payment', error)
        });
    }
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(async function(user) {
      self.FCusID = user.uid;
      await firebase.firestore().collection("users").doc(user.uid).get()
      .then((result) => {
        self.shopID = result.data().shopid;
        self.getShopOrders();
      })
      .catch(function () {
      });
    });
  },
  data() {
    return {
      noResult: false,
      orderList: [],
      shopID: "",
      rejectReason:"",
      rejectID: null,
    };
  },
};
</script>

<style scoped>
/deep/.sweet-modal .sweet-title > h2 {
  margin-top: 19px;
}
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.order-header-wrapper{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top:0px;
  }
  .order-header{
    width: 100%;
    background-color: #d2ecf6;
    color: #3893c1;
    padding:20px 15px;
    margin-top:15px;
    border-radius: 5px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
  }
  .header-products{
    width:45%;
    display: inline-block;
  }
  .header-total{
    width:10%;
    display: inline-block;
    text-align:right;
  }
  .item-total{
    width:10%;
    float:left;
    text-align: right;
    padding-top:10px;
  }
  .header-delivery{
    width:25%;
    display: inline-block;
    text-align: right;
  }
  .item-delivery{
    float:left;
    width: 25%;
    text-align: right;
    padding-top: 10px;
  }
  .header-status{
    width:20%;
    display: inline-block;
    text-align: right;
  }
  .item-amount{
    float: right;
  }
  .orders-page .order-items{
    float: left;
    width:45%;
    padding-right: 8%;
    border-bottom: 0px;
  }
  .orders-page .cart-item{
    width:100%;
  }
  .item-status{
    float:right;
    padding-top: 10px;
    width:20%;
    text-align: right;
  }
  .item-status .pending{
    color:#f29339;
  }
  .item-status .awaiting{
    color:#00b0ff;
  }
  .cart-icon.shop{
    padding-top: 0px;
    margin-top: 2px;
    float: left;
  }
  .cart-row{
    border-top:1px solid #3893c1;
  }
  .order-no-result{
    text-align: center;
    margin-top: 40px;
  }
  .no-result-txt{
    margin-top: 15px;
    font-size: 32px;
  }
  .order-dashboard{
    width: 98.8%;
    color: #333;
    margin-bottom: 1.5%;
    overflow: hidden;
    height: auto;
  }
  .invoice-no{
    float:right;
    margin-top: 9px;
  }
  .order-items {
    float: left;
    width: 45%;
    padding-right: 8%;
    border-bottom: 0px;
}
.reject{
  border: 1px solid #f3155b;
  color: #f3155b;
  float: right;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
  margin-right: 10px;
}
.approve{
  border: 1px solid #196a9f;
  background-color: #196a9f;
  color:white;
  float: right;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
}
.approval-action{
  margin-top:15px;
}
.rejected{
  color:#f81b1b;
}
.rejected-reason{
  color:#f81b1b;
  text-decoration: underline;
}
.approved{
  color:#45c9a5;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>