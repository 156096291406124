import Vue from "vue";
import Router from "vue-router";
import Market from "@/components/Market";
import Cart from "@/components/Cart";
import Payment from "@/components/Payment";
//import myAccount from "@/components/Account";
import Orders from "@/components/Orders";
import Policy from "@/components/Policy";
import Cookie from "@/components/Cookie";
import Contact from "@/components/Contact";
import ShopPost from "@/components/ShopPost";
import FeaturedLive from "@/components/FeaturedLive";
import AuthCallBack from "@/components/AuthCallBack";
import ShopCategory from "@/components/ShopCategory";
import Search from "@/components/Search";
import AdminPost from "@/components/AdminPost";
import AdminSort from "@/components/AdminSort";
import AdminLIVE from "@/components/AdminLive";
import AdminNews from "@/components/AdminNews";
import AdminLogin from "@/components/AdminLogin";
import Login from "@/components/Login";
import Dashboard from "@/components/Dashboard";
import VendorDashboard from "@/components/VendorDashboard";
import VendorShop from "@/components/VendorShop";
import VendorOrders from "@/components/VendorOrders";
import VendorPost from "@/components/VendorPost";
import VendorPostNew from "@/components/VendorPostNew";
import VendorPostEdit from "@/components/VendorPostEdit";
import VendorPricing from "@/components/VendorPricing";
import Users from "@/components/Users";
import AdminStaffs from "@/components/AdminStaffs";
import AdminUploads from "@/components/AdminUploads";
import AdminApp from "@/components/AdminApp";
import axios from "axios";
import { backendURL } from "../config";
import firebase from 'firebase';

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Market",
      component: Market,
      meta: { isBackend: false },
    },
    {
      path: "/cart/:shop",
      name: "Cart",
      component: Cart,
      meta: { isBackend: false },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/payment/:invoice",
      name: "Payment",
      component: Payment,
      meta: { isBackend: false },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    /*{
      path: "/myAccount",
      name: "myAccount",
      component: myAccount,
      meta: { isBackend: false },
    },*/
    {
      path: "/orders",
      name: "Orders",
      component: Orders,
      meta: { isBackend: false },
    },
    {
      path: "/policy",
      name: "Policy",
      component: Policy,
      meta: { isBackend: false },
    },
    {
      path: "/cookie",
      name: "Cookie",
      component: Cookie,
      meta: { isBackend: false },
    },
    {
      path: "/shop/:id",
      name: "Shop",
      component: ShopPost,
      meta: { isBackend: false },
    },
    {
      path: "/live/:id",
      name: "Live",
      component: FeaturedLive,
      meta: { isBackend: false },
    },
    {
      path: "/category/:id",
      name: "Category",
      component: ShopCategory,
      meta: { isBackend: false },
    },
    {
      path: "/search/:keyword",
      name: "Search",
      component: Search,
      meta: { isBackend: false },
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
      meta: { isBackend: false },
    },
    {
      path: "/login/",
      name: "Login",
      component: Login,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            // eslint-disable-next-line
            if (from.name == 'Shop'){
              next({ path: "/cart/"+from.params.id });
            } else {
              next({ path: "/vendor/dashboard" });
            }
          } else {
            next();
          }
        });
      },
    },
    {
      path: "/vendor/dashboard/",
      name: "VendorDashboard",
      component: VendorDashboard,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/shop/",
      name: "VendorShop",
      component: VendorShop,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/orders/",
      name: "VendorOrders",
      component: VendorOrders,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/posts/",
      name: "VendorPost",
      component: VendorPost,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/posts/new",
      name: "VendorPostNew",
      component: VendorPostNew,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/posts/edit",
      name: "VendorPostEdit",
      component: VendorPostEdit,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            next();
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/vendor/pricing/",
      name: "VendorPricing",
      component: VendorPricing,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            vendorguard(to, from, next, user);
          } else {
            next({ path: "/login" });
          }
        });
      },
    },
    {
      path: "/admin/login/",
      name: "AdminLogin",
      component: AdminLogin,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          return next();
        }
      },
    },
    {
      path: "/admin/dashboard/",
      name: "Dashboard",
      component: Dashboard,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/users/",
      name: "Users",
      component: Users,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/staffs/",
      name: "Staffs",
      component: AdminStaffs,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/uploads/",
      name: "Uploads",
      component: AdminUploads,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/posts/",
      name: "Posts",
      component: AdminPost,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/sort/",
      name: "Sort",
      component: AdminSort,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/live/",
      name: "LIVE",
      component: AdminLIVE,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/news/",
      name: "News",
      component: AdminNews,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/login/" });
        }
      },
    },
    {
      path: "/admin/app/",
      name: "AppVersion",
      component: AdminApp,
      meta: { isBackend: true },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          guard(to, from, next);
        } else {
          next({ path: "/admin/app" });
        }
      },
    },
    {
      path: "/authcallback",
      name: "AuthBack",
      component: AuthCallBack,
      meta: { isBackend: true },
    },
  ],
  scrollBehavior() {
    window.scrollTo(0,0);
  },
});
const guard = function (to, from, next) {
  // check for valid auth token
  axios
    .post(`${backendURL}/validatetoken/`, {
      token: localStorage.getItem("token"),
    })
    .then((response) => {
      if (response.data == "Token validated") {
        if (to.name == "Login") {
          return next({ path: "/admin/dashboard/" });
        } else {
          return next();
        }
      } else {
        localStorage.removeItem("token");
        return next({ path: "/admin/login/" });
      }
    })
    .catch(function (error) {
      // eslint-disable-next-line
      console.log(error);
      localStorage.removeItem("token");
      return next({ path: "/admin/login/" });
    });
};
// eslint-disable-next-line
const vendorguard = function (to, from, next, user) {
  firebase.firestore().collection("users").doc(user.uid).get()
  .then((querySnapshot) => {
    // eslint-disable-next-line
    console.log(querySnapshot.exists)
      if (querySnapshot.exists == false){
        return next({ path: "/vendor/shop/" });
      } else {
        return next();
      }
  })
  .catch(function (error) {
    // eslint-disable-next-line
    console.log(error)
    return next({ path: "/login/" });
  });
};
