<template>
  <div class="dashboard-page">
    <VendorMenu class="admin-menu"></VendorMenu>
    <div class="mobile-screen mobile-main-menu">
      <font-awesome-icon
        v-on:click="toggleMobileMenu()"
        class="bars-icon"
        icon="bars"
      />
      <div class="mobile-logo">
        <router-link to="/"><img src="../assets/tylogo.png" /></router-link>
      </div>
      <font-awesome-icon
        v-on:click="triggerShopSidebar()"
        v-bind:class="shopMenuState"
        style="position: absolute; right: 20px"
        class="shop-icon"
        icon="th-large"
      />
    </div>
    <div v-bind:class="menuState" class="reveal-menu">
      <VendorMenu class="mobile-menu"></VendorMenu>
    </div>
    <div class="dashboard-body">
      <div class="vendor-page-title">
        <div class="page-title">POSTS</div>
        <div class="page-subtitle">Shop Posts</div>
        <router-link to="/vendor/posts/new">
          <div class="add-new-post">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            เพิ่มโพสต์ใหม่
          </div>
        </router-link>
        <div style="height: 25px" class="clearfix"></div>
      </div>
      <div class="post-container">
        <masonry
          v-bind:class="{ locked: shopSideBarState == 'show-shopside-menu' }"
          class="shop-post-container"
          :cols="{ default: 4, 1600: 3, 1200: 3, 800: 2, 600: 1 }"
          :gutter="{ default: '25px', 700: '0px' }"
        >
          <div v-for="post in VendorShopPosts" :key="post.id">
            <div
              :id="post.notiID"
              v-bind:class="{ isrotate: post.isRotate }"
              class="post-wrapper card"
            >
              <div class="front">
                <div class="post-top-section">
                  <div class="clearfix"></div>
                  <div
                    v-if="post.Banner.split('/')[4] == 'images'"
                    class="post-image"
                  >
                    <img v-bind:src="post.Banner" />
                    <div
                      v-on:click="showSingle(post.Banner, post.Description)"
                      class="post-view"
                    >
                      <font-awesome-icon class="view-icon" icon="eye" />
                    </div>
                  </div>
                  <div v-else class="post-video">
                    <VideoPlayer :videoURL="post.Banner"></VideoPlayer>
                  </div>
                                    <div
                    v-on:click="setShopCategorybyID(0)"
                    v-if="post.CategoryID == 0"
                    class="footer-item footer-category"
                  >
                    ทั้งหมด
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(2)"
                    v-if="post.CategoryID == 2"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้ชาย
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(1)"
                    v-if="post.CategoryID == 1"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้หญิง
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(3)"
                    v-if="post.CategoryID == 3"
                    class="footer-item footer-category"
                  >
                    เด็ก
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(4)"
                    v-if="post.CategoryID == 4"
                    class="footer-item footer-category"
                  >
                    เครื่องประดับ
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(5)"
                    v-if="post.CategoryID == 5"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ อิเล็กทรอนิกส์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(6)"
                    v-if="post.CategoryID == 6"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ ประดับรถยนต์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(7)"
                    v-if="post.CategoryID == 7"
                    class="footer-item footer-category"
                  >
                    สุขภาพและความงาม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(8)"
                    v-if="post.CategoryID == 8"
                    class="footer-item footer-category"
                  >
                    อาหาร
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(9)"
                    v-if="post.CategoryID == 9"
                    class="footer-item footer-category"
                  >
                    ขนมหวาน
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(10)"
                    v-if="post.CategoryID == 10"
                    class="footer-item footer-category"
                  >
                    เครื่องดื่ม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(11)"
                    v-if="post.CategoryID == 11"
                    class="footer-item footer-category"
                  >
                    สัตว์เลี้ยง
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(11)"
                    v-if="post.CategoryID == 12"
                    class="footer-item footer-category"
                  >
                    อื่น ๆ
                  </div>
                  <div class="clearfix"></div>
                  <div class="post-title">{{ post.Title }}</div>
                  <div class="post-desc">{{ post.Description }}</div>
                  <div class="crud-actions">
                    <div class="crud-option edit" v-on:click="editPost(post.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                      <span>แก้ไข</span>
                    </div>
                    <div v-on:click="deletePost(post.id)" style="margin-left:2%;" class="crud-option delete">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                      <span>ลบ</span>
                    </div>
                  </div>
                </div>
                <div class="post-bottom-section">
                  <div class="post-footer">
                    <div class="post-shop-location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    กรุงเทพฯ-สายใต้เซ็นเตอร์
                  </div>
                    <div class="view-wrapper">
                      <div
                        v-if="Number(post.PriceTill).toLocaleString() !== '0'"
                        style="float: right"
                        class="footer-item footer-date"
                      >
                        <svg
                          style="margin-right: 10px; margin-top: -3px"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#ec7465"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-bag"
                        >
                          <path
                            d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                          ></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span
                          >{{ Number(post.PriceFrom).toLocaleString() }} -
                          {{ Number(post.PriceTill).toLocaleString() }} บาท</span
                        >
                      </div>
                      <div
                        v-else
                        style="float: right"
                        class="footer-item footer-date"
                      >
                        <svg
                          style="margin-right: 10px; margin-top: -3px"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#ec7465"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-bag"
                        >
                          <path
                            d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                          ></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span
                          >{{ Number(post.PriceFrom).toLocaleString() }} บาท</span
                        >
                      </div>
                    </div>
                  <div class="clearfix"></div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import VendorMenu from "@/components/VendorMenu";
import { mapGetters, mapActions } from "vuex";
import firebase from 'firebase';
import axios from "axios";
//import { backendURL } from "../config";
export default {
  name: "VendorDashboard",
  components: {
    VendorMenu,
  },
  methods: {
    ...mapActions(["setMenuClass", "setSideBarMenuClass"]),
    getPosts(){
      var self = this;
      axios
        .get('https://api.livetycoon.com/getshoppostbyshop/' + self.shopID)
        .then(response => {
          // eslint-disable-next-line
          console.log('posts',response)
          self.VendorShopPosts = response.data;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error)
        })
    },
    editPost(id){
      this.$router.push({ name: "VendorPostEdit" , params: {id:id}});
    },
    deletePost(id){
      var result = confirm("คุณแน่ใจหรือว่าต้องการลบ");
      if (result) {
        // alert(id)
        var self = this;
        axios
          .get('https://api.livetycoon.com/deleteshoppost/' + id)
          .then(response => {
            // eslint-disable-next-line
            console.log('posts',response)
            var newshoppost = [];
            self.VendorShopPosts.forEach(item=>{
              if(item.id !== id)
                newshoppost.push(item);
            });
            self.VendorShopPosts = newshoppost;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          })
      }
    }
  },
  computed: mapGetters([
    "menuState",
    "shopMenuState",
    "shopSideBarState",
    "backendState",
  ]),
  mounted() {
    var self = this;
    firebase.auth().onAuthStateChanged(async function(user) {
      self.FCusID = user.uid;
      await firebase.firestore().collection("users").doc(user.uid).get()
      .then((result) => {
        self.shopID = result.data().shopid;
        self.getPosts();
      })
      .catch(function () {
      });
    });
  },
  data() {
    return {
      FCusID:'',
      shopID:'',
      shopInfo: '',
      shopLocation: '',
      membership: '',
      VendorShopPosts: null,
      annoucements:[
        {id:0,title: 'ลิมูซีนซังเต ดอกเตอร์ซูฮกวินรีดไถล้มเหลว สปาย ช็อปแฟล็ตเอ็นเตอร์เทนก๊วนจูเนียร์', post_date: '21/06/2021'},
        {id:1,title: ' แผดเผา เห็นด้วยแกรนด์แฟรนไชส์ชาร์จตัวตน ซื่อบื้อศิลปวัฒนธรรม เป่ายิงฉุบ', post_date: '21/06/2021'},
      ]
    };
  },
};
</script>

<style scoped>
.dashboard-body {
  margin-left: 280px;
  font-family: 'Kanit';
}
.vendor-page-title {
  color: #333;
  margin-right: 20px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee7ef;
}
.vendor-page-title .page-title {
  font-weight: bold;
  float: left;
}
.vendor-page-title .page-subtitle {
  float: right;
}
.vendor-page-title .add-new-post{
  float:right;
  padding:10px;
  background-color:#f3155b;
  margin-right: 20px;
  border-radius: 5px;
  color: white;
  margin-top: -10px;
}
.add-new-post:hover{
  background-color:#c4003e;
}
.dashboard-item {
  border: 1px solid #d1d7dc;
  -webkit-box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 12px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  float: left;
  margin-left: 0.7%;
  margin-right: 0.7%;
  height: 100px;
}
.dashboard-item.variable {
  height: auto;
}
.account-dashboard {
  width: 23.5%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
}
.full-dashboard{
  width: 98.2%;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5%;
  overflow: hidden;
  height:auto;
}
.account-dashboard.large {
  width: 73.3%;
  height: auto;
}
.account-dashboard.full {
  width: 98.1%;
  height: auto;
}
.upper-text {
  float: left;
}
.upper-image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;
}
.upper-image img {
  width: 100%;
}
.upper-icon {
  float: right;
  background: #f1f6f8;
  border-radius: 50%;
  padding: 12px;
  width:60px;
  height: 60px;
  color: #196a9f;
}
.upper-icon img{
  width:100%;
}
.item-container.report {
  padding: 20px 25px;
  padding-bottom: 0px;
}
.item-container.table-list {
  padding: 20px 25px;
}
.upper-text-subtitle {
  font-size: 25px;
  font-weight: bold;
}
.dashboard-title {
  font-weight: bold;
}
.category-view-icon {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.category-view-icon svg {
  color: #33be8f;
}
.most-view-category {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.most-view-category-subtitle {
  margin-top: 5px;
  font-family: "Kanit";
  text-align: center;
  margin-bottom: 25px;
}
.category-view-row {
  font-family: "Kanit";
  border-top: 2px solid #196A9F;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-name {
  float: left;
}
.category-view-result {
  float: right;
}
.annoucements{
  font-weight: normal;
  margin-top: 10px;
}
.annoucements ul{
  padding-left: 20px;
}
.annoucements li{
  padding-top:15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.annoucements .post-date{
  float:right;
  color: #196a9f;
}
.report a{
  text-decoration: none;
}
.dashboardbtt{
  padding: 5px 5px;
  background-color: #d4edfc;
  font-size: 14px;
  line-height: 14px;
  color: #3893c1;
  border: 1px solid #75b9dd;
  border-radius: 4px;
  margin-top:5px;
  text-align: center;
  cursor: pointer;
}
.dashboardbtt:hover{
  background-color:white;
}
.crud-option{
  border:1px solid #333;
  border-radius: 10px;
  display: inline-block;
  width:48%;
  text-align: center;
  padding-top:3px;
  padding-bottom: 5px;
}
.crud-option svg{
  margin-right: 10px;
}
.crud-actions{
  margin-top:15px;
}
.crud-option.edit{
  color: #196a9f;
  border-color: #196a9f;
  cursor: pointer;
  margin-bottom: 15px;
}
.crud-option.edit:hover{
  color: white;
  background-color: #196a9f;
}
.crud-option.delete{
  color: #f3155b;
  border-color: #f3155b;
  cursor: pointer;
  margin-bottom: 15px;
}
.crud-option.delete:hover{
  background-color: #f3155b;
  color:white;
}
/deep/.user-status {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/deep/.closed {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}
/deep/.open {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
/deep/.apexcharts-tooltip {
  color: #000000;
}
/deep/.apexcharts-toolbar {
  display: none;
}
/deep/.vgt-table {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Kanit";
}
/deep/table.vgt-table {
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-color: #196A9F;
}
/deep/.vgt-table thead th {
  background: #0c3c66;
  color: white;
  border-bottom: 1px solid #196A9F;
}
/deep/table.vgt-table td {
  color: white;
  border-color: #196A9F;
}
/deep/.vgt-wrap__footer {
  background: #0c3c66 !important;
  color: white;
  border: 0px;
  border-top: 1px solid #196A9F;
}
/deep/.vgt-wrap__footer .footer__row-count__label {
  color: white;
}
/deep/.vgt-wrap__footer .footer__row-count__select {
  color: #a6b0cf;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  color: white !important;
}
/deep/.vgt-wrap__footer .footer__navigation__page-btn {
  color: white;
}
/deep/.footer__navigation__info {
  color: white;
}
@media screen and (max-width: 1100px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 48.5%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 98.5%;
  }
  .admin-menu {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-body {
    margin-left: 10px !important;
    margin-right: 10px;
  }
  .dashboard-item {
    width: 100%;
    margin-top: 15px;
  }
  .account-dashboard.large {
    width: 100%;
  }
}
</style>