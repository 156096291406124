<template>
  <div class="login-body">
    <img class="wave" src="../assets/waveblue.png" />
    <div class="container">
      <div class="img">
        <img src="../assets/bg.svg" />
      </div>
      <div class="login-content">
        <div class="login-form">
          <img src="../assets/avatar.svg" />
          <h2 style="color: color: #196a9f;" class="title">Welcome</h2>
          <div :class="uinputClass" class="input-div one">
            <div class="i">
              <img style="color: #196a9f;" src="../assets/user.svg" />
            </div>
            <div class="div">
              <h5>Username</h5>
              <input style="color:#333"
                v-model="username"
                @blur="remcl('username')"
                @focus="addcl('username')"
                type="text"
                class="input"
              />
            </div>
          </div>
          <div :class="pinputClass" class="input-div pass">
            <div class="i">
              <img src="../assets/lock.svg" />
            </div>
            <div class="div">
              <h5>Password</h5>
              <input style="color:#333"
                v-model="userpass"
                @blur="remcl('password')"
                @focus="addcl('password')"
                type="password"
                class="input"
              />
            </div>
          </div>
          <!--<a style="font-weight:bold;margin-top:15px;margin-bottom:15px;" href="#">Forgot Password?</a>-->
          <input
            style="margin-top: 30px"
            v-on:click="login()"
            type="submit"
            class="btn"
            value="Login"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { backendURL } from "../config";

export default {
  name: "Login",
  components: {},
  methods: {
    addcl: function (type) {
      if (type === "username") {
        this.uinputClass = "focus";
      }
      if (type === "password") {
        this.pinputClass = "focus";
      }
    },
    remcl: function (type) {
      if (type === "username") {
        if (this.username == "") {
          this.uinputClass = "";
        }
      }
      if (type === "password") {
        if (this.userpass == "") {
          this.pinputClass = "";
        }
      }
    },
    async login() {
      var params = {
        FCusID: this.username,
        Password: this.userpass,
      };
      const response = await axios.post(`${backendURL}/adminlogin`, params);
      // eslint-disable-next-line
      console.log(response.data);
      if (response.data.accessToken) {
        localStorage.setItem("token", response.data.accessToken);
        var user = {};
        user.FCusID = response.data.user.FCusID;
        user.nickname = response.data.user.FCusNickName;
        user.role = response.data.user.RoleID;
        localStorage.setItem("user", JSON.stringify(user));
        this.$router.push({ path: "/admin/dashboard" });
      } else {
        alert("Wrong username or password");
      }
    },
    ...mapActions([]),
  },
  computed: mapGetters([]),
  created() {},
  data() {
    return {
      uinputClass: "",
      pinputClass: "",
      username: "",
      userpass: "",
    };
  },
};
</script>

<style scoped>
.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
}

.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.img img {
  width: 500px;
}

.login-form {
  width: 360px;
}

.login-content img {
  height: 100px;
}

.login-content .input-div img {
  height: 25px;
}

.login-content h2 {
  margin: 15px 0px 20px 0px;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 18px;
  transition: 0.3s;
}

.input-div:before,
.input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #38d39f;
  transition: 0.4s;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50%;
}

.input-div.focus > div > h5 {
  top: -5px;
  font-size: 15px;
}

.input-div.focus > .i > i {
  color: #38d39f;
}

.input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #fff;
  font-family: "poppins", sans-serif;
}

.input-div.pass {
  margin-bottom: 4px;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: #38d39f;
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
}
.btn:hover {
  background-position: right;
}

@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  form {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
  }
}
</style>